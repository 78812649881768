import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import { makeAuthenticatedApiCall } from '../../apiHelper';
import theme from '../../theme';
import { formatSimpleDateToDDMMYYYYWithTime } from '../../utils';
import FirebaseUploadComponent from '../CreateCotation/FirebaseUploadComponent';

interface FREItem {
  id: number;
  calculator_id: number;
  value: string;
  quote_urls?: string;
  invoice_urls?: string;
  comment: string;
}

interface CalculatorFormValues {
  purchasePrice: string; // Si vous attendez une chaîne ici
  mandate: string; // Même si c'est un nombre, vous pouvez le traiter comme une chaîne pour les inputs de formulaire
  guarantee: string;
  salePrice: string;
  fre: Array<{
    id: string; // uuidv4() retourne une chaîne
    value: string; // Si vous traitez la valeur comme une chaîne dans le formulaire
    quoteUrls: string[];
    invoiceUrls: string[];
    comment: string;
  }>;
  freSup: Array<{
    id: string;
    value: string;
    quoteUrls: string[];
    invoiceUrls: string[];
    comment: string;
  }>;
  vehicleCost: string;
  invoicedPrice: string;
  netMargin: string;
  isWarrantyExtended: boolean;
  warrantyExtensionFileUrls: string[];
}

// Define the expected structure of the response data
interface DepositSaleCalculatorResponse {
  id: number;
  pipedrive_deal_id?: string; // Use optional chaining if this property might not always be present
  // Add other properties as needed
}

export const DepositSaleCalculator: React.FC<{
  dealId: number;
  orgId: number;
  extUrl: string;
  regPlate: string;
  model: string;
  marketPriceDb: number;
  purchasePriceDb: number;
}> = ({ dealId, orgId, extUrl, regPlate, model, marketPriceDb, purchasePriceDb }) => {
  const [isWarrantyExtended, setIsWarrantyExtended] = useState(false);
  const [guaranteeButtonDisabled, setGuaranteeButtonDisabled] = useState(false);

  const [lastUpdate, setLastUpdate] = useState<string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [lastEmailSent, setLastEmailSent] = useState<string>('');
  const [canSendMandateEmail, setCanSendMandateEmail] = useState(false);
  const [depositSaleCalculatorId, setDepositSaleCalculatorId] = useState<number | null>(null);

  const { register, control, handleSubmit, watch, setValue, reset, trigger } = useForm<CalculatorFormValues>({
    defaultValues: {
      purchasePrice: purchasePriceDb ? purchasePriceDb.toString() : '',
      mandate: '825',
      guarantee: '409',
      salePrice: marketPriceDb ? marketPriceDb.toString() : '',
      fre: [{ id: uuidv4(), value: '', quoteUrls: [], invoiceUrls: [], comment: '' }],
      freSup: [{ id: uuidv4(), value: '', quoteUrls: [], invoiceUrls: [], comment: '' }],
      vehicleCost: '',
      invoicedPrice: '',
      netMargin: '',
      isWarrantyExtended: false,
      warrantyExtensionFileUrls: [],
    },
  });

  // Surveillez les champs nécessaires directement par leurs noms
  const salePrice = watch('salePrice');
  const purchasePrice = watch('purchasePrice');
  const mandate = watch('mandate');
  const guarantee = watch('guarantee');
  const fre = watch('fre');
  const freSup = watch('freSup');
  const warrantyExtensionFileUrls = watch('warrantyExtensionFileUrls');

  const [warrantyExtensionUrls, setWarrantyExtensionUrls] = useState<string[]>([]);
  const [mandateUrls, setMandateUrls] = useState<string[]>([]);

  const [freQuoteUrls, setFreQuoteUrls] = useState<{ [key: number]: string[] }>({});
  const [freInvoiceUrls, setFreInvoiceUrls] = useState<{ [key: number]: string[] }>({});
  const [freSupQuoteUrls, setFreSupQuoteUrls] = useState<{ [key: number]: string[] }>({});
  const [freSupInvoiceUrls, setFreSupInvoiceUrls] = useState<{ [key: number]: string[] }>({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await makeAuthenticatedApiCall<DepositSaleCalculatorResponse>(
          'get',
          `/api/arbitration/deposit-sale-calculator/${dealId}`,
        );
        if (response && response.data) {
          populateForm(response.data);
          if (response.data.pipedrive_deal_id) {
            setDepositSaleCalculatorId(response.data.id);
            setCanSendMandateEmail(true);
          } else {
            setCanSendMandateEmail(false);
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dealId, setValue]);

  const handleCommaToPoint = (event: { target: { value: any } }) => {
    const { value } = event.target;
    event.target.value = value.replace(/,/g, '.');
  };

  const populateForm = (data: any) => {
    const {
      purchase_price,
      mandate,
      guarantee,
      sale_price,
      vehicle_cost,
      invoiced_price,
      net_margin,
      is_warranty_extended,
      warranty_extension_file_url,
      mandate_urls,
      last_email_sent,
      fre,
      freSup,
    } = data;

    // Convertissez is_warranty_extended de nombre à booléen
    const isWarrantyExtendedBool = is_warranty_extended === 1;

    // Initialisez l'état des URLs d'extension de garantie avec les données existantes
    initializeWarrantyExtensionUrls(warranty_extension_file_url);

    initializeMandateUrls(mandate_urls);

    const defaultFreItem = { id: uuidv4(), value: '', quoteUrl: '', invoiceUrl: '' };
    const populatedFre = fre.length > 0 ? fre.map(mapFreItem) : [defaultFreItem];
    const populatedFreSup = freSup.length > 0 ? freSup.map(mapFreItem) : [defaultFreItem];

    reset({
      purchasePrice: purchase_price ? purchase_price.toString() : '',
      mandate: mandate ? mandate.toString() : '',
      guarantee: guarantee ? guarantee.toString() : '',
      salePrice: sale_price ? sale_price.toString() : '',
      vehicleCost: vehicle_cost ? vehicle_cost.toString() : '',
      invoicedPrice: invoiced_price ? invoiced_price.toString() : '',
      netMargin: net_margin ? net_margin.toString() : '',
      isWarrantyExtended: isWarrantyExtendedBool,
      fre: populatedFre,
      freSup: populatedFreSup,
    });

    setIsWarrantyExtended(isWarrantyExtendedBool);
    setGuaranteeButtonDisabled(isWarrantyExtendedBool);

    setLastEmailSent(last_email_sent);
  };

  function mapFreItem(item: FREItem) {
    return {
      id: item.id.toString(), // Assurez-vous que l'ID est une chaîne
      value: item.value ? item.value.toString() : '', // Fournit une chaîne vide comme valeur par défaut si `item.value` est falsy
      quoteUrls: item.quote_urls ? item.quote_urls : [], // Assurez-vous que c'est un tableau
      invoiceUrls: item.invoice_urls ? item.invoice_urls : [], // Assurez-vous que c'est un tableau
      comment: item.comment ? item.comment.toString() : '', // Fournit une chaîne vide comme valeur par défaut si `item.value` est falsy
    };
  }

  const freTotal = watch('fre').reduce((acc, current) => acc + Number(current.value || 0), 0);
  const freMinusTwentyPercentTotal = watch('fre').reduce((acc, current) => acc + Number(current.value || 0) / 1.2, 0);

  const freSupTotal = watch('freSup').reduce((acc, current) => acc + Number(current.value || 0), 0);
  const freSupMinusTwentyPercentTotal = watch('freSup').reduce(
    (acc, current) => acc + Number(current.value || 0) / 1.2,
    0,
  );

  useEffect(() => {
    // Convertir toutes les valeurs en float pour assurer des calculs corrects
    const totalFreTTC = fre.reduce((acc, current) => acc + parseFloat(current.value || '0'), 0);
    const totalFreSupTTC = freSup.reduce((acc, current) => acc + parseFloat(current.value || '0'), 0);

    const freHT = freTotal / 1.2; // Convertir FRE TTC en HT
    const freSupHT = freSupTotal / 1.2; // Convertir FRE Sup TTC en HT
    const mandateHT = 825; // Valeur HT fixe
    const guaranteeHT = 409; // Valeur HT fixe

    const prixVenteMoinsPrixAchat = (Number(salePrice) - Number(purchasePrice)) / 1.2;

    console.log('Prix de vente:', Number(salePrice));
    console.log("Prix d'achat:", Number(purchasePrice));
    console.log("(Prix de vente - Prix d'achat) / 1.2:", prixVenteMoinsPrixAchat);
    console.log('Mandat HT:', mandateHT);
    console.log('Garantie HT:', guaranteeHT);
    console.log('FRE HT:', freHT);
    console.log('FRE SUP HT:', freSupHT);

    const netMarginCalculation = prixVenteMoinsPrixAchat - mandateHT - guaranteeHT - freHT - freSupHT;

    console.log('Marge nette finale:', netMarginCalculation);

    // Mise à jour des valeurs dans le formulaire avec les valeurs calculées
    setValue('netMargin', netMarginCalculation.toFixed(0).toString());
  }, [salePrice, mandate, guarantee, purchasePrice, freTotal, freSupTotal, setValue, watch, trigger]);

  // Pour l'extension de garantie
  const handleWarrantyUploadComplete = (url: string) => {
    setWarrantyExtensionUrls((updatedUrls) => [...updatedUrls, url]);
    // setValue('warrantyExtensionFileUrls', updatedUrls); // Met à jour le champ du formulaire
  };

  const handleWarrantyDeleteComplete = (url: string) => {
    const updatedUrls = warrantyExtensionUrls.filter((u) => u !== url);
    setWarrantyExtensionUrls(updatedUrls);
    // setValue('warrantyExtensionFileUrls', updatedUrls);
  };

  const handleMandateUploadComplete = (url: string) => {
    setMandateUrls((updatedUrls) => [...updatedUrls, url]);
  };

  const handleMandateDeleteComplete = (url: string) => {
    const updatedUrls = mandateUrls.filter((u) => u !== url);
    setMandateUrls(updatedUrls);
  };

  const initializeWarrantyExtensionUrls = (warrantyExtensionFileUrls: string) => {
    try {
      // Tentative de détection si la valeur est déjà un tableau (et non une chaîne JSON)
      if (Array.isArray(warrantyExtensionFileUrls)) {
        setWarrantyExtensionUrls(warrantyExtensionFileUrls);
      } else {
        // Si c'est une chaîne, essayez de la parser comme du JSON
        const urls = JSON.parse(warrantyExtensionFileUrls);
        if (Array.isArray(urls)) {
          setWarrantyExtensionUrls(urls);
        } else {
          console.error('Parsed warrantyExtensionFileUrls is not an array', urls);
          setWarrantyExtensionUrls([]);
        }
      }
    } catch (error) {
      console.error('Failed to parse warrantyExtensionFileUrls', error);
      setWarrantyExtensionUrls([]); // Réinitialiser avec un tableau vide en cas d'erreur
    }
  };

  const initializeMandateUrls = (mandateUrls: string) => {
    try {
      // Tentative de détection si la valeur est déjà un tableau (et non une chaîne JSON)
      if (Array.isArray(mandateUrls)) {
        setMandateUrls(mandateUrls);
      } else {
        // Si c'est une chaîne, essayez de la parser comme du JSON
        const urls = JSON.parse(mandateUrls);
        if (Array.isArray(urls)) {
          setMandateUrls(urls);
        } else {
          console.error('Parsed mandateUrls is not an array', urls);
          setMandateUrls([]);
        }
      }
    } catch (error) {
      console.error('Failed to parse mandateUrls', error);
      setMandateUrls([]); // Réinitialiser avec un tableau vide en cas d'erreur
    }
  };

  // Pour les devis et factures FRE
  const handleFreQuoteUploadComplete = (index: number, url: string) => {
    const updatedUrls = { ...freQuoteUrls, [index]: [...(watch(`fre.${index}.quoteUrls`) || []), url] };
    setFreQuoteUrls(updatedUrls);
    setValue(`fre.${index}.quoteUrls`, updatedUrls[index]);
  };

  const handleFreQuoteDeleteComplete = (index: number, url: string) => {
    const remainingUrls = {
      ...freQuoteUrls,
      [index]: (watch(`fre.${index}.quoteUrls`) || []).filter((u) => u !== url),
    };
    setFreQuoteUrls(remainingUrls);
    setValue(`fre.${index}.quoteUrls`, remainingUrls[index]);
  };

  const handleFreInvoiceUploadComplete = (index: number, url: string) => {
    const updatedUrls = { ...freInvoiceUrls, [index]: [...(watch(`fre.${index}.invoiceUrls`) || []), url] };
    setFreInvoiceUrls(updatedUrls);
    setValue(`fre.${index}.invoiceUrls`, updatedUrls[index]);
  };

  const handleFreInvoiceDeleteComplete = (index: number, url: string) => {
    const remainingUrls = {
      ...freInvoiceUrls,
      [index]: (watch(`fre.${index}.invoiceUrls`) || []).filter((u) => u !== url),
    };
    setFreInvoiceUrls(remainingUrls);
    setValue(`fre.${index}.invoiceUrls`, remainingUrls[index]);
  };

  // Pour les devis et factures FRE SUP
  const handleFreSupQuoteUploadComplete = (index: number, url: string) => {
    const updatedUrls = { ...freSupQuoteUrls, [index]: [...(watch(`freSup.${index}.quoteUrls`) || []), url] };
    setFreSupQuoteUrls(updatedUrls);
    setValue(`freSup.${index}.quoteUrls`, updatedUrls[index]);
  };

  const handleFreSupQuoteDeleteComplete = (index: number, url: string) => {
    const remainingUrls = {
      ...freSupQuoteUrls,
      [index]: (watch(`freSup.${index}.quoteUrls`) || []).filter((u) => u !== url),
    };
    setFreSupQuoteUrls(remainingUrls);
    setValue(`freSup.${index}.quoteUrls`, remainingUrls[index]);
  };

  const handleFreSupInvoiceUploadComplete = (index: number, url: string) => {
    const updatedUrls = { ...freSupInvoiceUrls, [index]: [...(watch(`freSup.${index}.invoiceUrls`) || []), url] };
    setFreSupInvoiceUrls(updatedUrls);
    setValue(`freSup.${index}.invoiceUrls`, updatedUrls[index]);
  };

  const handleFreSupInvoiceDeleteComplete = (index: number, url: string) => {
    const remainingUrls = {
      ...freSupInvoiceUrls,
      [index]: (watch(`freSup.${index}.invoiceUrls`) || []).filter((u) => u !== url),
    };
    setFreSupInvoiceUrls(remainingUrls);
    setValue(`freSup.${index}.invoiceUrls`, remainingUrls[index]);
  };

  const {
    fields: freFields,
    append: appendFre,
    remove: removeFre,
  } = useFieldArray({
    control,
    name: 'fre',
  });

  const {
    fields: freSupFields,
    append: appendFreSup,
    remove: removeFreSup,
  } = useFieldArray({
    control,
    name: 'freSup',
  });

  const sendMandateEmail = async () => {
    try {
      const response = await makeAuthenticatedApiCall('post', '/api/mails/deposit-sale-mandate', {
        orgId: orgId,
        salePrice: salePrice,
        model: model,
        mandatePrice: mandate,
        regPlate: regPlate,
      });
      if (response && response.data) {
        try {
          // Mettez à jour l'état avec la nouvelle date d'envoi
          const dateNow = moment().tz('Europe/Paris').format('YYYY-MM-DD HH:mm:ss');
          setLastEmailSent(dateNow);
          console.log('Email sent successfully');
          const responseDepositSaleSold = await makeAuthenticatedApiCall(
            'post',
            '/api/arbitration/deposit-sale-mandate',
            {
              id: depositSaleCalculatorId,
              dateNow: dateNow,
            },
          );
          console.log('responseDepositSaleSold', responseDepositSaleSold);
        } catch (error) {
          console.error('Error updating last_email_sent', error);
        }
      }
    } catch (error) {
      console.error('Failed to send email', error);
    }
  };

  const onSubmit = async (formData: any) => {
    let mailToSend = false;

    // Vérifier chaque FRE pour une PJ dans Devis ou Facture avec un commentaire vide
    formData.fre.forEach((fre: any) => {
      if ((fre.quoteUrls.length > 0 || fre.invoiceUrls.length > 0) && fre.comment.trim() === '') {
        mailToSend = true;
      }
    });

    // Faire la même vérification pour chaque FRE Sup
    formData.freSup.forEach((freSup: any) => {
      if ((freSup.quoteUrls.length > 0 || freSup.invoiceUrls.length > 0) && freSup.comment.trim() === '') {
        mailToSend = true;
      }
    });

    console.log('mailToSend', mailToSend);

    const payload = {
      ...formData,
      warrantyExtensionFileUrls: JSON.stringify(warrantyExtensionUrls),
      mandateUrls: JSON.stringify(mandateUrls),
    };

    try {
      await makeAuthenticatedApiCall('post', `/api/arbitration/deposit-sale-calculator`, {
        dealId,
        payload,
      });

      setButtonDisabled(true);

      const now = new Date();
      const isoDateString = now.toISOString();
      setLastUpdate(isoDateString);

      // Si mailToSend est vrai, on déclenche l'envoi d'un e-mail de notification
      if (mailToSend) {
        try {
          await makeAuthenticatedApiCall('post', '/api/mails/deposit-sale-calculator', {
            dealId: dealId,
            extUrl: extUrl,
            regPlate: regPlate,
          });
        } catch (error) {
          console.error('Error sending email', error);
        }
      }

      setCanSendMandateEmail(true); // Rendre le bouton "Envoyer le mail mandat" cliquable
    } catch (error) {
      console.error('Failed to save data', error);
    }
  };

  const addFreItem = () => {
    appendFre({ id: uuidv4(), value: '', quoteUrls: [], invoiceUrls: [], comment: '' });
    trigger(['fre']); // Ajoutez ceci pour déclencher la validation et le recalcul
  };

  const removeFreItem = (index: number) => {
    if (freFields.length > 1) {
      removeFre(index);
      trigger(['fre']); // Ajoutez ceci pour déclencher la validation et le recalcul
    }
  };

  const addFreSupItem = () => {
    appendFreSup({ id: uuidv4(), value: '', quoteUrls: [], invoiceUrls: [], comment: '' });
    trigger(['freSup']); // Ajoutez ceci pour déclencher la validation et le recalcul
  };

  const removeFreSupItem = (index: number) => {
    if (freSupFields.length > 1) {
      removeFreSup(index);
      trigger(['freSup']); // Ajoutez ceci pour déclencher la validation et le recalcul
    }
  };

  // Example function to calculate -20% of FRE value
  const calculateMinusTwentyPercent = (value: string) => (parseFloat(value) / 1.2).toFixed(2);

  const onWarrantyExtensionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsWarrantyExtended(event.target.checked);
    if (event.target.checked) {
      // Si l'extension de garantie est cochée, réinitialisez le champ "guarantee"
      setValue('guarantee', '');
      setGuaranteeButtonDisabled(true);
      setValue('isWarrantyExtended', true);
    } else {
      setValue('guarantee', '409');
      setGuaranteeButtonDisabled(false);
      setValue('isWarrantyExtended', false);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box padding={3}>
        <Grid container spacing={2}>
          <Grid
            container
            xs={12}
            item
            spacing={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'row'}
            sx={{ flexFlow: 'row wrap' }}
          >
            <Grid item xs={12} sm={3}>
              <Controller
                name="purchasePrice"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleCommaToPoint(e);
                      field.onChange(e);
                    }}
                    className="inputElement"
                    label="Prix d'achat"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                          €
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="mandate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleCommaToPoint(e);
                      field.onChange(e);
                    }}
                    className="inputElement"
                    label="Mandat (HT)"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                          €
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="guarantee"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleCommaToPoint(e);
                      field.onChange(e);
                    }}
                    className="inputElement"
                    label="Garantie (HT)"
                    variant="outlined"
                    margin="normal"
                    disabled={guaranteeButtonDisabled}
                    sx={{ backgroundColor: guaranteeButtonDisabled ? '#ccc' : 'white' }}
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                          €
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="salePrice"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      handleCommaToPoint(e);
                      field.onChange(e);
                    }}
                    className="inputElement"
                    label="Prix de vente"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                          €
                        </Typography>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} display={'flex'} flexDirection={'column'}>
            <Divider sx={{ marginBottom: 2 }} />
            {/* Zone 2 - FRE */}
            <Typography variant="body1" marginBottom={1}>
              FRE
            </Typography>
            <Box display="flex" flexDirection="column" alignItems={'flex-start'}>
              {freFields.map((field, index) => (
                <Box key={field.id} sx={{ display: 'flex', gap: 2 }}>
                  <Grid item xs={12} sm={6} display={'flex'} flexDirection={'column'}>
                    <Controller
                      name={`fre.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            handleCommaToPoint(e);
                            field.onChange(e);
                          }}
                          className="inputElement"
                          label="FRE"
                          variant="outlined"
                          sx={{ marginBottom: 1 }}
                        />
                      )}
                    />
                    <TextField
                      className="inputElement"
                      value={calculateMinusTwentyPercent(watch(`fre.${index}.value`) || '0')}
                      label="FRE -20%"
                    />
                  </Grid>
                  <Grid container item xs={12} sm={3} display={'flex'} flexDirection={'column'}>
                    <Typography variant="body2">Devis</Typography>
                    <FirebaseUploadComponent
                      uploadedUrls={field.quoteUrls || []}
                      onUploadComplete={(url) => handleFreQuoteUploadComplete(index, url)}
                      onDeleteComplete={(url) => handleFreQuoteDeleteComplete(index, url)}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={3} display={'flex'} flexDirection={'column'}>
                    <Typography variant="body2">Facture</Typography>
                    <FirebaseUploadComponent
                      uploadedUrls={field.invoiceUrls || []}
                      onUploadComplete={(url) => handleFreInvoiceUploadComplete(index, url)}
                      onDeleteComplete={(url) => handleFreInvoiceDeleteComplete(index, url)}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={3} display={'flex'} flexDirection={'column'}>
                    <TextField
                      className="inputElement"
                      {...register(`fre.${index}.comment`)}
                      label="Commentaire"
                      variant="outlined"
                      multiline
                      rows={4}
                      sx={{ marginBottom: 1 }}
                    />
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
                    <IconButton
                      disabled={index === 0}
                      onClick={() => removeFreItem(index)}
                      sx={{ height: '44px' }} // Ajustez le padding selon vos besoins
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                    {index === freFields.length - 1 && (
                      <IconButton
                        onClick={addFreItem}
                        sx={{ height: '44px' }} // Ajustez le padding selon vos besoins
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              ))}
              <Typography variant="body2" marginBottom={1}>
                Total FRE HT / TTC : {freMinusTwentyPercentTotal.toFixed(2)} / {freTotal.toFixed(2)}
              </Typography>
            </Box>
            <Divider sx={{ marginTop: 2 }} />
          </Grid>
          <Grid container item xs={12} sm={12} display={'flex'} flexDirection={'column'}>
            {/* Zone 2 - FRE SUP */}
            <Typography variant="body1" marginBottom={1}>
              FRE SUP
            </Typography>
            <Box display="flex" flexDirection="column">
              {freSupFields.map((field, index) => (
                <Box key={field.id} sx={{ display: 'flex', gap: 2 }}>
                  <Grid item xs={12} sm={6} display={'flex'} flexDirection={'column'}>
                    <Controller
                      name={`freSup.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            handleCommaToPoint(e);
                            field.onChange(e);
                          }}
                          className="inputElement"
                          label="FRE SUP"
                          variant="outlined"
                          sx={{ marginBottom: 1 }}
                        />
                      )}
                    />
                    <TextField
                      className="inputElement"
                      value={calculateMinusTwentyPercent(watch(`freSup.${index}.value`) || '0')}
                      label="FRE SUP -20%"
                    />
                  </Grid>
                  <Grid container item xs={12} sm={3} display={'flex'} flexDirection={'column'}>
                    <Typography variant="body2">Devis</Typography>
                    <FirebaseUploadComponent
                      uploadedUrls={field.quoteUrls || []}
                      onUploadComplete={(url) => handleFreSupQuoteUploadComplete(index, url)}
                      onDeleteComplete={(url) => handleFreSupQuoteDeleteComplete(index, url)}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={3} display={'flex'} flexDirection={'column'}>
                    <Typography variant="body2">Facture</Typography>
                    <FirebaseUploadComponent
                      uploadedUrls={field.invoiceUrls || []}
                      onUploadComplete={(url) => handleFreSupInvoiceUploadComplete(index, url)}
                      onDeleteComplete={(url) => handleFreSupInvoiceDeleteComplete(index, url)}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={3} display={'flex'} flexDirection={'column'}>
                    <TextField
                      className="inputElement"
                      {...register(`freSup.${index}.comment`)}
                      label="Commentaire"
                      variant="outlined"
                      multiline
                      rows={4}
                      sx={{ marginBottom: 1 }}
                    />
                  </Grid>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
                    <IconButton
                      disabled={index === 0}
                      onClick={() => removeFreSupItem(index)}
                      sx={{ height: '44px' }} // Ajustez le padding selon vos besoins
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                    {index === freSupFields.length - 1 && (
                      <IconButton
                        onClick={addFreSupItem}
                        sx={{ height: '44px' }} // Ajustez le padding selon vos besoins
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              ))}
              <Typography variant="body2">
                Total FRE SUP HT / TTC : {freSupMinusTwentyPercentTotal.toFixed(2)} / {freSupTotal.toFixed(2)}
              </Typography>
            </Box>
            <Divider sx={{ marginTop: 2 }} />
          </Grid>

          <Grid container xs={12} item spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={isWarrantyExtended} onChange={onWarrantyExtensionChange} />}
                  className="inputElement"
                  label="Extension de garantie"
                />
              </Grid>
              {/* Conditionnellement affichez la DropZone pour l'upload de fichier */}
              {isWarrantyExtended && (
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FirebaseUploadComponent
                      onUploadComplete={(url) => handleWarrantyUploadComplete(url)}
                      onDeleteComplete={(url) => handleWarrantyDeleteComplete(url)}
                      uploadedUrls={warrantyExtensionUrls || []}
                    />
                  </Grid>
                </Grid>
              )}
              <TextField
                {...register('vehicleCost')}
                label="Cout de revient du véhicule"
                variant="outlined"
                className="inputElement"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                      €
                    </Typography>
                  ),
                }}
              />
              <TextField
                {...register('invoicedPrice')}
                label="Prix à facturer"
                variant="outlined"
                margin="normal"
                className="inputElement"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                      €
                    </Typography>
                  ),
                }}
              />
              <TextField
                {...register('netMargin')}
                label="Marge nette"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                      €
                    </Typography>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  Mandats et Avenants
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FirebaseUploadComponent
                  onUploadComplete={(url) => handleMandateUploadComplete(url)}
                  onDeleteComplete={(url) => handleMandateDeleteComplete(url)}
                  uploadedUrls={mandateUrls || []}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                type="submit"
                sx={{
                  fontWeight: 900,
                  height: 55,
                  textAlign: 'center',
                  backgroundColor: theme.palette.saffron.main,
                  '&:hover': { backgroundColor: theme.palette.saffron.main },
                }}
                disabled={buttonDisabled}
                variant="contained"
                color="primary"
              >
                Sauvegarder
              </Button>
              {lastUpdate && (
                <Grid sx={{ paddingTop: '0 !important' }} item xs={12} md={12}>
                  <Typography variant="body2">
                    Dernière mise à jour : {formatSimpleDateToDDMMYYYYWithTime(lastUpdate)}
                    {/*' par '}
                        {arbitrationDealInfo?.data?.last_user*/}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <Button
                  onClick={sendMandateEmail}
                  disabled={!canSendMandateEmail}
                  sx={{
                    fontWeight: 900,
                    marginTop: theme.spacing(2),
                    height: 55,
                    textAlign: 'center',
                    backgroundColor: theme.palette.saffron.main,
                    '&:hover': { backgroundColor: theme.palette.saffron.main },
                    marginRight: theme.spacing(2),
                  }}
                  variant="contained"
                  color="primary"
                >
                  Envoyer le mail Mandat
                </Button>
                {/* Bouton Sauvegarder et d'autres contenus */}
              </Grid>
              {lastEmailSent && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Dernier mail Mandat envoyé : {formatSimpleDateToDDMMYYYYWithTime(lastEmailSent)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
