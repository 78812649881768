import React from 'react';

import { TableCell, TableRow, Typography } from '@mui/material';

import { MarketplaceDealsListProps } from '../../types/deal';
import { Role } from '../../types/user';
import { capitalizeAndFormat, formatDateToDDMMYYYY, formatKilometers } from '../../utils';
import MarketPlaceButton from '../DealsList/MarketPlaceButton';

const MarketplaceDeal: React.FC<MarketplaceDealsListProps> = ({ row, index }) => {
  const priceFormatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
  });

  const isDeleted = !!row.deleted_at;

  return (
    <TableRow className={index % 2 === 0 ? 'grayBackground' : ''}>
      <TableCell sx={{ fontWeight: 700 }}>{row.immat || 'N/A'}</TableCell>
      <TableCell>
        {row.brand ? (
          <>
            <Typography sx={{ fontSize: 15 }}>{capitalizeAndFormat(row.brand)}</Typography>
            {row.model} {row.version}
          </>
        ) : (
          'N/A'
        )}
      </TableCell>
      <TableCell>{row.kilometers ? formatKilometers(row.kilometers) : 'N/A'}</TableCell>
      <TableCell>{row.extra_data?.reg_date ? formatDateToDDMMYYYY(row.extra_data.reg_date) : 'N/A'}</TableCell>
      <TableCell>{row.price !== null ? priceFormatter.format(Number(row.price)) : 'N/A'}</TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        {row.pics?.length > 0 ? (
          <a href={row.pics[0]} target="_blank" rel="noopener noreferrer">
            <img
              src={row.pics[0]}
              alt={`${row.brand} ${row.model}`}
              style={{
                width: '60px',
                height: '45px',
                objectFit: 'cover',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            />
          </a>
        ) : (
          'N/A'
        )}
      </TableCell>
      <TableCell>{row.date_added ? formatDateToDDMMYYYY(row.date_added) : 'N/A'}</TableCell>
      <TableCell sx={{ maxWidth: 200 }}>
        <MarketPlaceButton dealId={row.pipedrive_deal_id} userRole={Role.God} dealData={row} />
      </TableCell>
    </TableRow>
  );
};

export default MarketplaceDeal;
