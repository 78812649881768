import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import SaveIcon from '@mui/icons-material/Save';
import {
  AlertColor,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import { makeAuthenticatedApiCall } from '../../../apiHelper';
import { useDealMisivE, useDealMisivF, useDepositSaleBuyer } from '../../../hooks/useApiQueries';
import theme from '../../../theme';
import { formatDateToDDMMYYYY, formatUTCDateTime, getFormatFromMimetype, removeSpaces } from '../../../utils';
import FirebaseUploadComponent from '../../CreateCotation/FirebaseUploadComponent';
import { ErrorMessageSnackbar } from '../../ErrorMessageSnackbar';
import ManualLPN from './ManualLPN';

interface SubmitCessionCertificate {
  deal: any;
  pipelineLetter: string;
  stageId?: number;
  userRole?: string;
  onRefresh?: () => void;
}

const SubmitCessionCertificate: React.FC<SubmitCessionCertificate> = ({
  deal,
  pipelineLetter,
  stageId,
  userRole,
  onRefresh,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [downloading, setDownloading] = useState(false);
  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);

  const [isLogbookSending, setIsLogbookSending] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('');
  const [buttonStep, setButtonStep] = useState<string>('initial');
  const [responseNomFic, setResponseNomFic] = useState<string>('');
  const [responseId, setResponseId] = useState<number>();
  const [errorText, setErrorText] = useState<string>('');
  const [tooltipError, setTooltipError] = useState<string>('');
  const [isTimeoutError, setIsTimeoutError] = useState(false);

  const [showLogbookButton, setShowLogbookButton] = useState(false);
  const [logbookConfirmation, setLogbookConfirmation] = useState<string>('');

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [responseErrorCode, setResponseErrorCode] = useState(null);

  const isNumFormuleExists = !(deal?.num_formule ?? deal?.NumFormule); // NumFormule dans pipeline F

  const queryClient = useQueryClient();

  const { data: misivDealData } = pipelineLetter === 'f' ? useDealMisivF(deal.PipedriveDealID) : useDealMisivE(deal.id);
  const { data: depositSaleBuyerData, isLoading: isDepositSaleBuyerDataLoading } =
    pipelineLetter === 'e' ? useDepositSaleBuyer(deal.id) : { data: null, isLoading: false };

  const [submitCessionCertificateUploadedUrls, setSubmitCessionCertificateUploadedUrls] = useState<string[]>(
    pipelineLetter === 'e' ? depositSaleBuyerData?.files : [],
  );

  const [dealDataToSend, setDealDataToSend] = useState<any>(null);

  const formMethods = useForm({
    mode: 'all', // Validation à chaque changement de champ
  });
  const { handleSubmit, control, formState, trigger, watch, setValue } = formMethods;

  const dealWithPurchaseDateTime = { ...deal, purchaseDateTime: watch('purchaseDateTime') };

  const handleDownloadPhotos = async () => {
    setDownloading(true);
    const zip = new JSZip();
    const pipedriveDealId = pipelineLetter === 'f' ? deal.PipedriveDealID : deal.id;
    const folder = zip.folder(`Skipcar-DC-${pipedriveDealId}`);

    // Parse the deal.AllFiles string into an array of URLs
    const urls =
      pipelineLetter === 'f' ? deal.AllFiles.split(',').map((url: string) => url.trim()) : depositSaleBuyerData?.files;
    setDownloadUrls(urls);

    const promises = urls.map(async (url: string, index: number) => {
      try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const fileName = `Skipcar-DC-${pipedriveDealId}-${index}.${getFormatFromMimetype(response.headers['content-type'])}`;
        folder?.file(fileName, blob);
      } catch (error) {
        console.error('Error handling photo:', error);
      }
    });

    await Promise.all(promises);

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `Skipcar-DC-${pipedriveDealId}.zip`);
    } catch (error) {
      console.error('Error generating ZIP:', error);
    } finally {
      setDownloading(false);
    }
  };

  const { errors, isValid } = formState;
  const SellerType = watch('SellerType');
  const BuyerType = pipelineLetter === 'f' ? watch('BuyerType') : watch('clientType');

  const validationRules = {
    firstName: { required: 'Ce champ est requis' },
    lastName: { required: 'Ce champ est requis' },
    addressNumber: {
      validate: (value: string) => {
        const addressType = formMethods.watch('addressType');
        if (addressType !== 'ldit' && !value) {
          return 'Ce champ est requis';
        }
        return true;
      },
    },
    addressType: { required: 'Ce champ est requis' },
    addressName: { required: 'Ce champ est requis' },
    city: { required: 'Ce champ est requis' },
    postalCode: { required: 'Ce champ est requis' },
    country: { required: 'Ce champ est requis' },
  };

  useEffect(() => {
    const determineButtonState = () => {
      if (stageId === 46) {
        // F1
        return 'waiting-payment';
      }

      if (!misivDealData?.data || !misivDealData.data.getDealMisivFromDB.length) {
        if (userRole === 'agency' && stageId === 48) {
          // F3
          return 'hide';
        } else if (stageId === 47 && userRole === 'agency') {
          // F2
          return 'waiting-procedures';
        } else {
          return 'initial';
        }
      }

      const filteredData =
        pipelineLetter === 'f'
          ? misivDealData?.data.getDealMisivFromDB
              .filter((data: any) => data.receipt_file_name?.startsWith('DC-'))
              .sort((a: any, b: any) => b.id - a.id || b.response_id - a.response_id)[0]
          : misivDealData?.data.getDealMisivFromDB[0];

      if (!filteredData) {
        if (userRole === 'agency' && stageId === 48) {
          // F3
          return 'hide';
        } else if (stageId === 47 && userRole === 'agency') {
          // F2
          return 'waiting-procedures';
        } else {
          return 'initial';
        }
      }

      const {
        receipt_file_firebase_url,
        receipt_file_name,
        response_id,
        has_timeout,
        purchase_date_time,
        lpn_id,
        step,
        last_update,
      } = filteredData;

      if (receipt_file_name && response_id && has_timeout === 0) {
        setResponseNomFic(receipt_file_name);
        if (userRole === 'agency') {
          setButtonText(`✅ Télécharger récépissé DC `);
        } else {
          setButtonText(`✅ DC n°${response_id} ${userRole === 'god' && receipt_file_firebase_url ? '' : 'KO'}`);
        }
        setIsTimeoutError(false);

        if (!lpn_id && pipelineLetter !== 'f') {
          setShowLogbookButton(true);
        } else if (lpn_id && step === 2 && pipelineLetter !== 'f') {
          setLogbookConfirmation(`✅ Inscrit au LPN le : ${formatDateToDDMMYYYY(last_update)}`);
          setShowLogbookButton(false);
        }
        return 'receipt';
      } else if (!receipt_file_name && has_timeout === 1) {
        setResponseId(response_id);
        setIsTimeoutError(true);
        setButtonText(`Renvoyer au ministère n°${response_id}`);
        return 'timeout-error';
      } else if (!receipt_file_name && has_timeout === 0) {
        setValue('purchaseDateTime', formatUTCDateTime(purchase_date_time));
        setIsTimeoutError(false);
        setButtonText(`⚠️ DC n°${response_id}`);
        return 'save';
      }

      return 'initial';
    };

    const newState = determineButtonState();
    setButtonStep(newState);
  }, [misivDealData, stageId]);

  useEffect(() => {
    if (pipelineLetter === 'f' && deal?.AllFiles) {
      const files = [...(pipelineLetter === 'f' && deal?.AllFiles ? deal?.AllFiles.split(',') : [])];

      setSubmitCessionCertificateUploadedUrls(files);
    } else if (depositSaleBuyerData && depositSaleBuyerData.files) {
      setSubmitCessionCertificateUploadedUrls(depositSaleBuyerData.files);
    }
  }, [deal, depositSaleBuyerData, pipelineLetter]);

  useEffect(() => {
    if (deal?.PurchaseDateTime) {
      const formattedDate = formatUTCDateTime(deal.PurchaseDateTime);
      setValue('purchaseDateTime', formattedDate);
    }
  }, [deal]);

  const handleConfirmClick = () => {
    setIsConfirmModalOpen(true); // Ouvrir la modale de confirmation au lieu de soumettre directement
  };

  const handleFinalConfirm = async () => {
    const formData = formMethods.getValues(); // Récupérer les données du formulaire actuel
    const updatedDealWithFormDatas = { ...deal, ...formData };
    const _dealDataToSend = pipelineLetter === 'f' ? updatedDealWithFormDatas : dealWithPurchaseDateTime;

    setDealDataToSend(_dealDataToSend);

    setIsConfirmModalOpen(false); // Fermer la modale de confirmation
    const handleSaveResponse = await handleSave(formData);
    if (handleSaveResponse) {
      await handleSaveCessionCertificate(_dealDataToSend); // Appeler la fonction pour soumettre
      handleDialogClose(); // Fermer la modale principale
    }
  };

  const handleSave = async (dealDataToSend: any) => {
    if (pipelineLetter === 'e') {
      try {
        await makeAuthenticatedApiCall('post', `/api/misiv/deposit-sale-buyer-update/${deal.id}`, dealDataToSend);
        openSnackbar('Les modifications ont bien été prises en compte', 'success');
        return true;
      } catch (error: any) {
        console.error(error);
        openSnackbar(error.message, 'error');
        return false;
      } finally {
        setResponseErrorCode(null);
      }
    } else if (pipelineLetter === 'f') {
      try {
        const purchaseDateTime = formMethods.getValues('purchaseDateTime');
        const dealWithPurchaseDateTime = { ...dealDataToSend, purchaseDateTime, type: 'DC' };

        const responseUpdate = await makeAuthenticatedApiCall('post', `/api/cg/update`, {
          dealDataToSend: dealWithPurchaseDateTime,
          PipedriveDealID: deal.PipedriveDealID,
        });
        console.log('responseUpdate', responseUpdate);
        return true;
        // openSnackbar('Les modifications ont bien été prises en compte', 'success');
      } catch (error: any) {
        console.error(error);
        openSnackbar(error.message, 'error');
        return false;
      } finally {
        setResponseErrorCode(null);
      }
    }
  };

  const handleSaveCessionCertificate = async (dealDataToSend: any) => {
    setIsLoading(true);
    setDisabledButton(true);

    try {
      const responseSave = await makeAuthenticatedApiCall('post', `/api/misiv/save/cession`, dealDataToSend);
      const responseSubmitId = responseSave?.data.responseId;
      setButtonStep('save');
      setButtonText(`⚠️ DC n°${responseSubmitId}`);
      handleSubmitCession(dealDataToSend);
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de la demande:', error);
      openSnackbar('Erreur lors de la sauvegarde de la demande. Veuillez ré-essayer.', 'error');
      setIsLoading(false);
      setDisabledButton(false);
    } finally {
      setDisabledButton(false);
      handleDialogClose();
      // setIsLoading(false);
    }
  };

  const handleSubmitCession = async (dealDataToSend: any) => {
    setIsLoading(true);
    setDisabledButton(true);

    try {
      const responseSubmit = await makeAuthenticatedApiCall('post', `/api/misiv/submit/cession`, dealDataToSend);

      const responseId = responseSubmit.data.responseId;
      const responseNomFic = responseSubmit.data.responseNomFic;
      setResponseNomFic(responseNomFic);

      // Gérer le cas où il n'y a pas d'erreur
      openSnackbar(`Votre demande a bien été envoyée : ${responseId}`, 'success');
      setButtonStep('receipt');
      if (userRole === 'agency') {
        setButtonText(`✅ Télécharger récépissé DC `);
      } else {
        setButtonText(`✅ DC n°${responseId}`);
      }

      /*
      const base64String = responseSubmit.data.askCessionReceipt;
      const blob = base64ToBlob(base64String, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      */
      const firebaseUrl = responseSubmit.data.firebaseReceipt.firebaseReceiptUrl;
      if (firebaseUrl) {
        // Si nous recevons une URL Firebase, ouvrez-la directement
        window.open(firebaseUrl);
        if (onRefresh) {
          onRefresh();
        }
      }

      if (pipelineLetter !== 'f') {
        handleSendToLogbook();
      }
    } catch (error: any) {
      if (error.response && error.response.status === 500) {
        // Cas où le serveur a renvoyé une erreur 500 avec des données JSON
        const responseId = error.response.data?.responseId;
        const responseErrorCode = error.response.data?.responseErrorCode;
        const responseErrorMessage = error.response.data?.responseErrorMessage;

        if (responseErrorCode === 3 || responseErrorCode === 100) {
          // Ici on doit pouvoir re-cliquer et re-tenter l'envoi sans passer par la modale avec le form
          setResponseId(responseId);
          setIsTimeoutError(true);
          setButtonText(`Renvoyer au ministère n°${responseId}`);
          openSnackbar(`Timeout Ministère : Ré-essayer dans quelques instants`, 'error');
          return;
        } else {
          // Enregistrez le code d'erreur pour une utilisation ultérieure
          setResponseErrorCode(responseErrorCode);
        }

        if (responseErrorCode) {
          openSnackbar(`Erreur ${responseErrorCode} : ${responseErrorMessage}`, 'error');
          setErrorText(responseErrorMessage);
        } else {
          console.log(error);
          openSnackbar(error.response.data.error, 'error');
          setErrorText(error.response.data.error);
        }

        return;
      } else if (axios.isAxiosError(error)) {
        console.error('Erreur réseau ou serveur :', error.message);
        openSnackbar('Erreur réseau ou serveur : ' + error.message, 'error');
      } else {
        console.error("Erreur inattendue lors de l'envoi du certificat à TMS :", error);
        openSnackbar(`Erreur lors de l'envoi du certificat à TMS`, 'error');
      }
      setDisabledButton(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendToMinistry = async (responseSubmitId: number) => {
    setIsLoading(true);

    try {
      const responseOpen = await makeAuthenticatedApiCall('post', `/api/misiv/open/cession`, {
        responseSubmitId,
      });
      handleGetReceipt(responseOpen.data.jsonObj);
      setButtonStep('receipt');
      setButtonText(`✅ DC n°${responseSubmitId}`);
      setTooltipError('');
      setResponseNomFic(responseOpen.data.jsonObj);
    } catch (error) {
      console.error("Erreur lors de l'ouverture du certificat à TMS :", error);
      openSnackbar("Erreur lors de l'ouverture du certificat à TMS", 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetReceipt = async (responseNomFic: string) => {
    setIsLoading(true);

    const dealId = pipelineLetter === 'f' ? deal.PipedriveDealID : deal.id;

    try {
      const responseReceipt = await makeAuthenticatedApiCall('post', `/api/misiv/receipt/cession`, {
        responseNomFic,
        pipelineLetter,
        dealId,
        typeDemarche: 'DC',
      });
      /*
      const base64String = responseReceipt.data.askCessionReceipt;

      // Convertir le base64 en Blob
      const blob = base64ToBlob(base64String, 'application/pdf');

      // Créer un URL pour le Blob
      const blobUrl = URL.createObjectURL(blob);

      // Ouvrir le PDF dans une nouvelle fenêtre
      window.open(blobUrl);
      */
      const firebaseUrl = responseReceipt.data.firebaseUrl;
      if (firebaseUrl) {
        // Si nous recevons une URL Firebase, ouvrez-la directement
        window.open(firebaseUrl);
      }
      setButtonStep('receipt');
      setIsTimeoutError(false);
    } catch (error: any) {
      console.error('handleGetReceipt - Erreur lors de la génération du reçu :', error);

      const errorMessage = error.response?.data?.message || 'Erreur inconnue';
      openSnackbar(errorMessage, 'error'); // Conversion de l'erreur en une chaîne de caractères pour la notification
      setErrorText(errorMessage); // Mise à jour de l'état de l'erreur avec une chaîne de caractères
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = async () => {
    if (buttonText.startsWith('⚠️ DC n°') && responseErrorCode === 3 && responseErrorCode === 100) {
      // Comportement par défaut
      handleSubmitCession(dealDataToSend);
    } else {
      setIsDialogOpen(true);
    }
  };

  useEffect(() => {
    if (isDialogOpen) {
      trigger(); // Déclencher la validation de tous les champs
    }
  }, [isDialogOpen, trigger]);

  const handleSendToLogbook = async () => {
    setIsLogbookSending(true);
    setShowLogbookButton(true);
    try {
      const response = await makeAuthenticatedApiCall('post', `/api/misiv/send-to-logbook`, {
        dealWithPurchaseDateTime,
        pipelineLetter,
      });
      console.log('response logbook', response);
      setLogbookConfirmation('✅ Inscrit au LPN');
      setShowLogbookButton(false);
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.response.data, 'error');
      setErrorText(error.response.data);
    } finally {
      setIsLogbookSending(false);
    }
  };

  const handleUploadComplete = (url: string) => {
    setSubmitCessionCertificateUploadedUrls((prevUrls) => {
      if (!Array.isArray(prevUrls)) {
        prevUrls = [];
      }
      return [...prevUrls, url];
    });
  };

  const handleDeleteComplete = (url: string) => {
    setSubmitCessionCertificateUploadedUrls((prevUrls) => {
      if (!Array.isArray(prevUrls)) {
        prevUrls = [];
      }
      return prevUrls.filter((existingUrl) => existingUrl !== url);
    });
  };

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {buttonStep === 'waiting-payment' && !isTimeoutError && (
        <Button
          disabled
          sx={{
            fontSize: 14,
            background: '#FF6060',
            opacity: 0.7,
            color: 'white !important',
            fontWeight: 600,
            width: 234,
          }}
        >
          DC : En attente de paiement
        </Button>
      )}
      {buttonStep === 'waiting-procedures' && !isTimeoutError && (
        <Button
          disabled
          sx={{
            fontSize: 14,
            background: '#FF6060',
            opacity: 0.7,
            color: 'white !important',
            fontWeight: 600,
            width: 260,
          }}
        >
          DC : En attente des démarches
        </Button>
      )}
      {buttonStep === 'initial' && !isTimeoutError && (
        <div style={{ position: 'relative' }}>
          <Tooltip
            title={tooltipError ? tooltipError : isNumFormuleExists ? 'Numéro de formule absent' : ''}
            placement="top"
          >
            <div>
              <Button
                sx={{
                  fontSize: 14,
                  margin: '8px auto 0',
                  maxWidth: 160,
                  color: 'white',
                  backgroundColor: tooltipError ? 'red' : '', // Changer la couleur du bouton en rouge en cas d'erreur
                }}
                variant="contained"
                onClick={() => handleButtonClick()}
                disabled={disabledButton || !!tooltipError || isNumFormuleExists}
                fullWidth
              >
                {isLoading && <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} />}
                {disabledButton ? buttonText : '📩 Soumettre la DC'}
              </Button>
            </div>
          </Tooltip>
          {tooltipError && (
            <Typography
              color="error"
              sx={{
                fontSize: 12,
                marginTop: 1,
                textAlign: 'center',
              }}
            >
              {tooltipError}
            </Typography>
          )}
        </div>
      )}

      {buttonStep === 'save' && !isTimeoutError && (
        <Tooltip
          title={
            isLoading
              ? "Demande sauvegardée, maintenant en cours d'envoi"
              : "La demande n'a pas abouti. Cliquez pour réessayer."
          }
          placement="top"
        >
          <div>
            <Button
              sx={{
                fontSize: 14,
                margin: '8px auto 0',
                width: 180,
                backgroundColor: 'orange !important',
              }}
              variant="contained"
              onClick={responseErrorCode !== 3 && responseErrorCode !== 100 ? handleButtonClick : handleSubmitCession}
              fullWidth
            >
              {isLoading && <CircularProgress size={12} style={{ color: 'white' }} />}
              {buttonText}
            </Button>
            {errorText && (
              <Typography
                variant="body2"
                sx={{ fontSize: '10px', lineHeight: '1.25', marginTop: 1, fontWeight: 'bold', color: 'red !important' }}
              >
                {errorText}
              </Typography>
            )}
          </div>
        </Tooltip>
      )}

      {buttonStep === 'receipt' && !isTimeoutError && (
        <Button
          sx={{
            fontSize: 14,
            margin: '8px auto 0',
            maxWidth: 160,
            backgroundColor: 'green',
            color: 'white',
          }}
          variant="contained"
          onClick={() => handleGetReceipt(responseNomFic)}
          fullWidth
        >
          {isLoading && <CircularProgress size={12} style={{ color: 'white' }} />}
          {buttonText}
        </Button>
      )}
      {isTimeoutError && responseId !== undefined && (
        <Tooltip title="Timeout Ministère : Ré-essayez dans quelques instants" placement="top">
          <div>
            <Button
              sx={{
                fontSize: 14,
                margin: '8px auto 0',
                width: 180,
                backgroundColor: 'red !important',
              }}
              variant="contained"
              onClick={() => handleResendToMinistry(responseId)}
              fullWidth
            >
              {isLoading && <CircularProgress size={12} style={{ color: 'white' }} />}
              {buttonText}
            </Button>
            {errorText && (
              <Typography
                variant="body2"
                sx={{ fontSize: '10px', lineHeight: '1.25', marginTop: 0, fontWeight: 'bold', color: 'red !important' }}
              >
                {errorText}
              </Typography>
            )}
          </div>
        </Tooltip>
      )}
      {showLogbookButton && (
        <>
          <Button
            onClick={handleSendToLogbook}
            sx={{
              margin: '8px auto 0',
              width: 180,
              backgroundColor: 'orange !important',
            }}
            variant="contained"
          >
            {isLogbookSending ? (
              <>
                <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} /> Envoi au LPN en cours
              </>
            ) : (
              '⚠️ Renvoyer au LPN'
            )}
          </Button>
        </>
      )}
      {!logbookConfirmation?.startsWith('✅ Inscrit au LPN le : ') && pipelineLetter !== 'f' && (
        <ManualLPN
          id={misivDealData?.data?.getDealMisivFromDB[0]?.id}
          pipelineLetter={pipelineLetter}
          onSuccess={() => {
            setLogbookConfirmation(`✅ Inscrit au LPN manuellement`);
            setShowLogbookButton(false);
            queryClient.invalidateQueries({ queryKey: [`useDealMisiv${pipelineLetter.toUpperCase()}`] });
          }}
        />
      )}
      {logbookConfirmation && (
        <Typography variant="body2" align="center" sx={{ marginTop: 1, fontSize: 16 }}>
          {logbookConfirmation}
        </Typography>
      )}
      {errorText && (
        <Typography
          variant="body2"
          sx={{ fontSize: '10px', lineHeight: '1.25', marginTop: 1, fontWeight: 'bold', color: 'red' }}
        >
          {errorText}
        </Typography>
      )}

      <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="lg">
        {isDepositSaleBuyerDataLoading ? (
          <CircularProgress />
        ) : (
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleSave)}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                    <Controller
                      name="purchaseDateTime"
                      control={control}
                      defaultValue={depositSaleBuyerData?.purchase_date_time ?? deal?.PurchaseDateTime ?? ''}
                      render={({ field }) => (
                        <TextField
                          {...field} // Bind react-hook-form's field to the TextField
                          autoFocus
                          margin="dense"
                          id="purchaseDateTime"
                          label="Date et heure de la vente"
                          type="datetime-local"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    <Typography variant="body2" textAlign="center">
                      Veuillez vérifier les informations suivantes :
                    </Typography>
                  </Grid>
                  {pipelineLetter === 'f' && (
                    <>
                      <Grid item xs={12} md={4}>
                        <Controller
                          name={pipelineLetter === 'f' ? 'RegPlate' : 'regPlate'}
                          control={control}
                          defaultValue={deal?.RegPlate}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="inputElement"
                              fullWidth
                              margin="dense"
                              id={pipelineLetter === 'f' ? 'RegPlate' : 'regPlate'}
                              label="Plaque d'immatriculation"
                              type="text"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Controller
                          name={pipelineLetter === 'f' ? 'VIN' : 'vin'}
                          control={control}
                          defaultValue={deal?.VIN}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="inputElement"
                              fullWidth
                              margin="dense"
                              id={pipelineLetter === 'f' ? 'VIN' : 'vin'}
                              label="VIN"
                              type="text"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Controller
                          name={pipelineLetter === 'f' ? 'NumFormule' : 'numFormule'}
                          control={control}
                          defaultValue={deal?.NumFormule}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="inputElement"
                              fullWidth
                              margin="dense"
                              id={pipelineLetter === 'f' ? 'NumFormule' : 'numFormule'}
                              label="Numéro de Formule"
                              type="text"
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {deal?.SellerType && (
                    <>
                      <Grid container sx={{ marginTop: 2 }} alignItems="center" item xs={12} md={12}>
                        <Grid item>
                          <Typography variant="body1" component="div" sx={{ marginRight: 2 }}>
                            Vendeur
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Controller
                            name="SellerType"
                            control={control}
                            defaultValue={deal?.SellerType}
                            render={({ field }) => (
                              <RadioGroup {...field} row>
                                <FormControlLabel
                                  className="inputElement"
                                  value="person"
                                  control={<Radio />}
                                  label="Particulier"
                                />
                                <FormControlLabel
                                  className="inputElement"
                                  value="pro"
                                  control={<Radio />}
                                  label="Professionnel"
                                />
                              </RadioGroup>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {SellerType === 'person' && (
                        <>
                          <Grid item xs={12} md={2}>
                            <Controller
                              name="SellerGender"
                              control={control}
                              defaultValue={deal?.SellerGender === 'mr' ? 'male' : 'female'}
                              rules={{}}
                              render={({ field }) => (
                                <Select
                                  error={!!errors.gender}
                                  sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                                  {...field}
                                  fullWidth
                                  className="inputElement"
                                  label="Sexe"
                                >
                                  <MenuItem value="male">Masculin</MenuItem>
                                  <MenuItem value="female">Féminin</MenuItem>
                                </Select>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Controller
                              name="SellerFirstName"
                              rules={validationRules.firstName}
                              control={control}
                              defaultValue={deal?.SellerFirstName}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="inputElement"
                                  margin="dense"
                                  id="SellerFirstName"
                                  error={!!errors.firstName}
                                  label="Prénom"
                                  type="text"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Controller
                              name="SellerLastName"
                              rules={validationRules.firstName}
                              control={control}
                              defaultValue={deal?.SellerLastName}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="inputElement"
                                  margin="dense"
                                  id="SellerLastName"
                                  error={!!errors.firstName}
                                  label="Nom"
                                  type="text"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Controller
                              name="SellerUsualName"
                              rules={{}}
                              control={control}
                              defaultValue={deal?.SellerUsualName}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="inputElement"
                                  margin="dense"
                                  id="SellerUsualName"
                                  error={!!errors.firstName}
                                  label="Nom d'usage"
                                  type="text"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                      {SellerType === 'pro' && (
                        <>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name="SellerSIRET"
                              control={control}
                              rules={
                                deal?.SellerType === 'pro'
                                  ? {
                                      required: 'Ce champ est requis',
                                      minLength: {
                                        value: 14,
                                        message: 'Le SIRET doit contenir exactement 14 caractères',
                                      },
                                      maxLength: {
                                        value: 14,
                                        message: 'Le SIRET doit contenir exactement 14 caractères',
                                      },
                                    }
                                  : {
                                      minLength: {
                                        value: 14,
                                        message: 'Le SIRET doit contenir exactement 14 caractères',
                                      },
                                      maxLength: {
                                        value: 14,
                                        message: 'Le SIRET doit contenir exactement 14 caractères',
                                      },
                                    }
                              }
                              defaultValue={deal?.SellerSIRET}
                              render={({ field }) => (
                                <TextField
                                  className="inputElement"
                                  error={!!errors.SellerSIRET} // Mise à jour pour utiliser 'SellerSIRET'
                                  {...field}
                                  margin="dense"
                                  id="SellerSIRET"
                                  label="SIRET"
                                  type="text"
                                  fullWidth
                                  inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                                  onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                                  helperText={errors.SellerSIRET ? (errors.SellerSIRET.message as string) : ''} // Mise à jour pour utiliser 'SellerSIRET'
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name="SellerCompanyName"
                              rules={deal?.SellerType === 'pro' ? { required: 'Ce champ est requis' } : {}}
                              control={control}
                              defaultValue={deal?.SellerCompanyName}
                              render={({ field }) => (
                                <TextField
                                  className="inputElement"
                                  {...field}
                                  margin="dense"
                                  error={!!errors.companyName}
                                  id="SellerCompanyName"
                                  label="Nom de l'entreprise"
                                  type="text"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  <Grid container sx={{ marginTop: 2 }} alignItems="center" item xs={12} md={12}>
                    <Grid item>
                      <Typography variant="body1" component="div" sx={{ marginRight: 2 }}>
                        Acheteur
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Controller
                        name={pipelineLetter === 'f' ? 'BuyerType' : 'clientType'}
                        control={control}
                        defaultValue={depositSaleBuyerData?.client_type ?? deal?.BuyerType}
                        render={({ field }) => (
                          <RadioGroup {...field} row id={pipelineLetter === 'f' ? 'BuyerType' : 'clientType'}>
                            <FormControlLabel
                              className="inputElement"
                              value="person"
                              control={<Radio />}
                              label="Particulier"
                            />
                            <FormControlLabel
                              className="inputElement"
                              value="pro"
                              control={<Radio />}
                              label="Professionnel"
                            />
                          </RadioGroup>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {BuyerType === 'pro' && (
                    <>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name={pipelineLetter === 'f' ? 'BuyerSIRET' : 'siret'}
                          control={control}
                          rules={
                            depositSaleBuyerData?.client_type === 'pro' || deal?.BuyerType === 'pro'
                              ? {
                                  required: 'Ce champ est requis',
                                  minLength: {
                                    value: 14,
                                    message: 'Le SIRET doit contenir exactement 14 caractères',
                                  },
                                  maxLength: {
                                    value: 14,
                                    message: 'Le SIRET doit contenir exactement 14 caractères',
                                  },
                                }
                              : {
                                  minLength: {
                                    value: 14,
                                    message: 'Le SIRET doit contenir exactement 14 caractères',
                                  },
                                  maxLength: {
                                    value: 14,
                                    message: 'Le SIRET doit contenir exactement 14 caractères',
                                  },
                                }
                          }
                          defaultValue={depositSaleBuyerData?.siret ?? deal?.BuyerSIRET}
                          render={({ field }) => (
                            <TextField
                              className="inputElement"
                              error={!!errors.siret}
                              {...field}
                              margin="dense"
                              id={pipelineLetter === 'f' ? 'BuyerSIRET' : 'siret'}
                              label="SIRET"
                              type="text"
                              fullWidth
                              inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                              onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Controller
                          name={pipelineLetter === 'f' ? 'BuyerCompanyName' : 'companyName'}
                          rules={
                            depositSaleBuyerData?.client_type === 'pro' || deal?.BuyerType === 'pro'
                              ? { required: 'Ce champ est requis' }
                              : {}
                          }
                          control={control}
                          defaultValue={depositSaleBuyerData?.company_name ?? deal?.BuyerCompanyName}
                          render={({ field }) => (
                            <TextField
                              className="inputElement"
                              {...field}
                              margin="dense"
                              error={!!errors.companyName}
                              id={pipelineLetter === 'f' ? 'BuyerCompanyName' : 'companyName'}
                              label="Nom de l'entreprise"
                              type="text"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {BuyerType === 'person' && (
                    <>
                      <Grid item xs={12} md={3}>
                        <Controller
                          name={pipelineLetter === 'f' ? 'BuyerGender' : 'gender'}
                          control={control}
                          defaultValue={
                            depositSaleBuyerData?.gender ?? (deal?.BuyerGender === 'mr' ? 'male' : 'female')
                          }
                          rules={
                            depositSaleBuyerData?.client_type === 'person' || deal?.BuyerType === 'person'
                              ? { required: 'Ce champ est requis' }
                              : {}
                          }
                          render={({ field }) => (
                            <Select
                              error={!!errors.gender}
                              {...field}
                              sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                              margin="dense"
                              fullWidth
                              className="inputElement"
                              label="Sexe"
                              id={pipelineLetter === 'f' ? 'BuyerGender' : 'gender'}
                            >
                              <MenuItem value="male">Masculin</MenuItem>
                              <MenuItem value="female">Féminin</MenuItem>
                            </Select>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Controller
                          name={pipelineLetter === 'f' ? 'BuyerFirstName' : 'firstName'}
                          rules={validationRules.firstName}
                          control={control}
                          defaultValue={depositSaleBuyerData?.first_name ?? deal?.BuyerFirstName}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              className="inputElement"
                              margin="dense"
                              id={pipelineLetter === 'f' ? 'BuyerFirstName' : 'firstName'}
                              error={!!errors.firstName}
                              label="Prénom"
                              type="text"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={pipelineLetter === 'f' ? 4 : 3}>
                        <Controller
                          name={pipelineLetter === 'f' ? 'BuyerLastName' : 'lastName'}
                          rules={validationRules.lastName}
                          control={control}
                          defaultValue={depositSaleBuyerData?.last_name ?? deal?.BuyerLastName}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id={pipelineLetter === 'f' ? 'BuyerLastName' : 'lastName'}
                              error={!!errors.lastName}
                              className="inputElement"
                              margin="dense"
                              label="Nom"
                              type="text"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      {pipelineLetter === 'e' && (
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="email"
                            control={control}
                            rules={{ required: 'Ce champ est requis' }}
                            defaultValue={depositSaleBuyerData.email ?? ''}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                                {...field}
                                error={!!error}
                                helperText={error ? error.message : ''}
                                className="inputElement"
                                margin="dense"
                                id="email"
                                label="Email"
                                type="email"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {pipelineLetter === 'f' && (
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="BuyerUsualName"
                            rules={{}}
                            control={control}
                            defaultValue={deal?.BuyerUsualName}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="BuyerUsualName"
                                error={!!errors.lastName}
                                className="inputElement"
                                margin="dense"
                                label="Nom d'usage"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={12} md={2}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerAddressNumber' : 'addressNumber'}
                      control={control}
                      rules={validationRules.addressNumber}
                      defaultValue={depositSaleBuyerData?.address_number ?? deal?.BuyerAddressNumber}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!errors.addressNumber}
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerAddressNumber' : 'addressNumber'}
                          label="Numéro d'adresse"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerAddressExtension' : 'addressExtension'}
                      control={control}
                      defaultValue={depositSaleBuyerData?.address_extension ?? deal?.BuyerAddressExtension ?? ''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerAddressExtension' : 'addressExtension'}
                          label="Extension d'adresse"
                          type="text"
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                          placeholder="Ex: BIS"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerAddressType' : 'addressType'}
                      control={control}
                      defaultValue={depositSaleBuyerData?.address_type ?? deal?.BuyerAddressType ?? ''}
                      rules={validationRules.addressType}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <InputLabel className="inputElement" id={`${field.name}-label`}>
                            Type d'adresse
                          </InputLabel>
                          <Select
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                            className="inputElement"
                            {...field}
                            labelId={`${field.name}-label`}
                            id={field.name}
                            label="Type d'adresse"
                            value={field.value ? field.value.toLowerCase() : ''}
                          >
                            <MenuItem value="rue">Rue</MenuItem>
                            <MenuItem value="all">Allée</MenuItem>
                            <MenuItem value="avn">Avenue</MenuItem>
                            <MenuItem value="blvd">Boulevard</MenuItem>
                            <MenuItem value="chau">Chaussée</MenuItem>
                            <MenuItem value="chm">Chemin</MenuItem>
                            <MenuItem value="cour">Cour</MenuItem>
                            <MenuItem value="fbg">Faubourg</MenuItem>
                            <MenuItem value="fort">Fort</MenuItem>
                            <MenuItem value="imp">Impasse</MenuItem>
                            <MenuItem value="ldit">Lieu-Dit</MenuItem>
                            <MenuItem value="parc">Parc</MenuItem>
                            <MenuItem value="pass">Passage</MenuItem>
                            <MenuItem value="plc">Place</MenuItem>
                            <MenuItem value="quai">Quai</MenuItem>
                            <MenuItem value="rte">Route</MenuItem>
                            <MenuItem value="sent">Sentier</MenuItem>
                            <MenuItem value="squa">Square</MenuItem>
                            <MenuItem value="sans">Inconnu</MenuItem>
                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerAddressName' : 'addressName'}
                      control={control}
                      rules={validationRules.addressName}
                      defaultValue={depositSaleBuyerData?.address_name ?? deal?.BuyerAddressName}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.addressName}
                          className="inputElement"
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerAddressName' : 'addressName'}
                          label="Nom de la voie"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerAddressPlace' : 'addressPlace'}
                      control={control}
                      rules={{}}
                      defaultValue={depositSaleBuyerData?.address_place ?? deal?.BuyerAddressPlace ?? ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerAddressPlace' : 'addressPlace'}
                          label="Lieu-dit"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerAddressStairs' : 'addressStairs'}
                      control={control}
                      rules={{}}
                      defaultValue={depositSaleBuyerData?.address_stairs ?? deal?.BuyerAddressStairs ?? ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerAddressStairs' : 'addressStairs'}
                          label="Étage/Escalier/Appartement"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerAddressComplement' : 'addressComp'}
                      control={control}
                      rules={{}}
                      defaultValue={depositSaleBuyerData?.address_comp ?? deal?.BuyerAddressComplement ?? ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerAddressComplement' : 'addressComp'}
                          label="Complément d'adresse"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerAddressPostalBox' : 'addressPostalBox'}
                      control={control}
                      rules={{}}
                      defaultValue={depositSaleBuyerData?.address_postalbox ?? deal?.BuyerAddressPostalBox ?? ''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerAddressPostalBox' : 'addressPostalBox'}
                          label="Boîte postale"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerCity' : 'city'}
                      control={control}
                      rules={validationRules.city}
                      defaultValue={depositSaleBuyerData?.city ?? deal?.BuyerCity}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.city}
                          className="inputElement"
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerCity' : 'city'}
                          label="Ville"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerPostalCode' : 'postalCode'}
                      control={control}
                      rules={validationRules.postalCode}
                      defaultValue={depositSaleBuyerData?.postal_code ?? deal?.BuyerPostalCode}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.postalCode}
                          className="inputElement"
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerPostalCode' : 'postalCode'}
                          label="Code postal"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name={pipelineLetter === 'f' ? 'BuyerCountry' : 'country'}
                      defaultValue={depositSaleBuyerData?.country ?? deal?.BuyerCountry}
                      control={control}
                      rules={validationRules.country}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          margin="dense"
                          id={pipelineLetter === 'f' ? 'BuyerCountry' : 'country'}
                          error={!!errors.country}
                          label="Pays"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  {BuyerType === 'person' && (
                    <>
                      {(depositSaleBuyerData?.client_type === 'person' || deal?.BuyerType === 'person') && (
                        <>
                          <Grid item xs={12} md={3}>
                            <Controller
                              name={pipelineLetter === 'f' ? 'BuyerDateOfBirth' : 'dateOfBirth'}
                              control={control}
                              rules={
                                depositSaleBuyerData?.client_type === 'person' || deal?.BuyerType === 'person'
                                  ? { required: 'Ce champ est requis' }
                                  : {}
                              }
                              defaultValue={
                                pipelineLetter === 'f'
                                  ? deal?.BuyerDateOfBirth
                                    ? deal?.BuyerDateOfBirth.substring(0, 10)
                                    : ''
                                  : depositSaleBuyerData?.date_of_birth
                              }
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  id={pipelineLetter === 'f' ? 'BuyerDateOfBirth' : 'dateOfBirth'}
                                  className="inputElement"
                                  error={!!errors.dateOfBirth}
                                  label="Date de naissance"
                                  type="date"
                                  fullWidth
                                  margin="dense"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Controller
                              name={pipelineLetter === 'f' ? 'BuyerPlaceOfBirth' : 'placeOfBirth'}
                              rules={
                                depositSaleBuyerData?.client_type === 'person' || deal?.BuyerType === 'person'
                                  ? { required: 'Ce champ est requis' }
                                  : {}
                              }
                              control={control}
                              defaultValue={depositSaleBuyerData?.place_of_birth ?? deal?.BuyerPlaceOfBirth}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="inputElement"
                                  error={!!errors.placeOfBirth}
                                  margin="dense"
                                  id={pipelineLetter === 'f' ? 'BuyerPlaceOfBirth' : 'placeOfBirth'}
                                  label="Lieu de naissance"
                                  type="text"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                      {pipelineLetter !== 'f' && (
                        <>
                          <Grid item xs={12} md={3}>
                            <Controller
                              name="documentType"
                              control={control}
                              defaultValue={depositSaleBuyerData?.document_type}
                              rules={
                                depositSaleBuyerData?.client_type === 'person'
                                  ? { required: 'Ce champ est requis' }
                                  : {}
                              }
                              render={({ field }) => (
                                <Select
                                  sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                                  error={!!errors.documentType}
                                  {...field}
                                  fullWidth
                                  margin="dense"
                                  className="inputElement"
                                  id="documentType"
                                >
                                  <MenuItem value="id_card">Carte d'identité</MenuItem>
                                  <MenuItem value="passport">Passeport</MenuItem>
                                  <MenuItem value="driving_licence">Permis de conduire</MenuItem>
                                  <MenuItem value="residence_permit">Titre de séjour</MenuItem>
                                </Select>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Controller
                              name="documentId"
                              rules={
                                depositSaleBuyerData?.client_type === 'person'
                                  ? { required: 'Ce champ est requis' }
                                  : {}
                              }
                              control={control}
                              defaultValue={depositSaleBuyerData?.document_id ?? ''}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  margin="dense"
                                  error={!!errors.documentId}
                                  className="inputElement"
                                  id="documentId"
                                  label="ID du document"
                                  type="text"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}

                      {!deal && BuyerType !== 'person' && (
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="documentId"
                            control={control}
                            rules={
                              depositSaleBuyerData?.client_type === 'person' ? { required: 'Ce champ est requis' } : {}
                            }
                            defaultValue={depositSaleBuyerData?.document_id}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={!!errors.documentId}
                                margin="dense"
                                className="inputElement"
                                id="documentId"
                                label="ID du document"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {pipelineLetter === 'e' && (
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="documentIssueDate"
                            rules={
                              depositSaleBuyerData?.client_type === 'person' ? { required: 'Ce champ est requis' } : {}
                            }
                            control={control}
                            defaultValue={depositSaleBuyerData?.document_issue_date}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                margin="dense"
                                className="inputElement"
                                error={!!errors.documentId}
                                id="documentIssueDate"
                                label="Date de délivrance du document"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={12} md={12}>
                    <Typography variant="body2">
                      {pipelineLetter === 'f'
                        ? 'Ensemble des pièces justificatives envoyés dans le formulaire CG'
                        : depositSaleBuyerData?.client_type === 'pro' || deal?.BuyerType === 'pro'
                          ? 'KBIS'
                          : "Justificatif de domicile, Passeport ou Carte d'identité (Recto-Verso)"}
                    </Typography>
                    <Button
                      onClick={handleDownloadPhotos}
                      style={{
                        position: 'relative',
                        float: 'right',
                        right: '0px',
                        top: '0px',
                        backgroundColor: theme.palette.saffron.main,
                        zIndex: 1000,
                        cursor: 'pointer',
                      }}
                    >
                      {downloading ? (
                        <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} />
                      ) : (
                        <SaveIcon color="action" />
                      )}
                    </Button>
                    <FirebaseUploadComponent
                      onDeleteComplete={handleDeleteComplete}
                      onUploadComplete={handleUploadComplete}
                      uploadedUrls={submitCessionCertificateUploadedUrls}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Annuler</Button>
                <Button
                  sx={{
                    fontWeight: 600,
                    backgroundColor: theme.palette.saffron.main,
                    '&:hover': { backgroundColor: theme.palette.saffron.main },
                  }}
                  variant="contained"
                  onClick={handleConfirmClick}
                  disabled={!isValid || !watch('purchaseDateTime') || isLoading}
                >
                  {isLoading && <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} />}Soumettre
                </Button>
              </DialogActions>
            </form>
          </FormProvider>
        )}
      </Dialog>
      {/* Modale de confirmation */}
      <Dialog open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
        <DialogContent>
          <Typography>Êtes-vous sûr que toutes les informations sont correctes ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmModalOpen(false)}>Annuler</Button>
          <Button
            sx={{
              fontWeight: 600,
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            variant="contained"
            onClick={handleFinalConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
    </>
  );
};
export default SubmitCessionCertificate;
