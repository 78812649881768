import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import { makeAuthenticatedApiCall } from '../../../apiHelper';
import { useDepositSaleBuyer } from '../../../hooks/useApiQueries';
import theme from '../../../theme';
import { FormDataPerson, FormDataPro } from '../../../types/buyerData';
import { DBDeal } from '../../../types/deal';
import { formatDateToDDMMYYYYWithTime, priceFormatter, removeSpaces } from '../../../utils';
import FirebaseUploadComponent from '../../CreateCotation/FirebaseUploadComponent';
import { ErrorMessageSnackbar } from '../../ErrorMessageSnackbar';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GenerateInvoice: React.FC<{ role?: string; deal: DBDeal; pipeline: string; vat: boolean }> = ({
  role,
  deal,
  pipeline,
  vat,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [lastUpdate, setLastUpdate] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [isQontoLoading, setIsQontoLoading] = useState(true);

  const [openEvoPriceDialog, setOpenEvoPriceDialog] = useState(false);
  const [evoSellingPrice, setEvoSellingPrice] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');

  const [openPipelineEModal, setOpenPipelineEModal] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);

  const formMethods = useForm<FormDataPerson | FormDataPro>({ mode: 'onChange' });
  const { control, handleSubmit, formState, trigger, setValue, setError, clearErrors } = formMethods;
  const { errors, isValid } = formState;

  const { data: depositSaleBuyerData, isLoading: isBuyerDataLoading } =
    pipeline === 'e' ? useDepositSaleBuyer(deal?.id as number) : { data: {}, isLoading: false };

  const validationRules = {
    firstName: { required: 'Ce champ est requis' },
    lastName: { required: 'Ce champ est requis' },
    addressNumber: {
      validate: (value: string) => {
        const addressType = formMethods.watch('addressType');
        if (addressType !== 'ldit' && !value) {
          return 'Ce champ est requis';
        }
        return true;
      },
    },
    addressType: { required: 'Ce champ est requis' },
    addressName: { required: 'Ce champ est requis' },
    city: { required: 'Ce champ est requis' },
    postalCode: { required: 'Ce champ est requis' },
    country: { required: 'Ce champ est requis' },
    siret: { required: 'Ce champ est requis' },
    companyName: { required: 'Ce champ est requis' },
  };

  useEffect(() => {
    if (pipeline === 'e') {
      if (depositSaleBuyerData) {
        setValue('firstName', depositSaleBuyerData.first_name);
        setValue('lastName', depositSaleBuyerData.last_name);
        setValue('email', depositSaleBuyerData.email);
        setValue('dateOfBirth', depositSaleBuyerData.date_of_birth);
        setValue('placeOfBirth', depositSaleBuyerData.place_of_birth);
        setValue('addressNumber', depositSaleBuyerData.address_number);
        setValue('addressExtension', depositSaleBuyerData.address_extension);
        setValue('addressType', depositSaleBuyerData.address_type);
        setValue('addressName', depositSaleBuyerData.address_name);
        setValue('addressStairs', depositSaleBuyerData.address_stairs);
        setValue('addressComp', depositSaleBuyerData.address_comp);
        setValue('addressPostalbox', depositSaleBuyerData.address_postalbox);
        setValue('city', depositSaleBuyerData.city);
        setValue('postalCode', depositSaleBuyerData.postal_code); // Peupler le champ
        setValue('country', depositSaleBuyerData.country);
        setValue('gender', depositSaleBuyerData.gender);
        setValue('documentType', depositSaleBuyerData.document_type);
        setValue('documentId', depositSaleBuyerData.document_id);
        setValue('documentIssueDate', depositSaleBuyerData.document_issue_date);
        setValue('siret', depositSaleBuyerData.siret);
        setValue('companyName', depositSaleBuyerData.company_name);
        setUploadedUrls(depositSaleBuyerData.files);
        if (depositSaleBuyerData.client_type === 'pro') {
          setTabValue(1); // Définir l'onglet "Professionnel" par défaut
        }
      }
    }
  }, [depositSaleBuyerData, setValue]);

  const handleOpenEvoPriceDialog = () => setOpenEvoPriceDialog(true);
  const handleCloseEvoPriceDialog = () => setOpenEvoPriceDialog(false);

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  // Define a function to handle the closing of the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleUploadComplete = (url: string) => {
    setUploadedUrls((prevUrls) => [...prevUrls, url]);
  };

  const handleDeleteComplete = (url: string) => {
    setUploadedUrls((prevUrls) => prevUrls.filter((existingUrl) => existingUrl !== url));
  };

  const handleClickSubmit = async (formData: FormDataPerson | FormDataPro) => {
    if (isLoading) return;

    const updatedFormData = { ...formData, clientType: tabValue === 1 ? 'pro' : 'person' };

    if (pipeline === 'e') {
      try {
        await makeAuthenticatedApiCall('post', `/api/misiv/deposit-sale-buyer-update/${deal.id}`, updatedFormData);
        openSnackbar('Les modifications ont bien été prises en compte', 'success');
      } catch (error: any) {
        console.error(error);
        openSnackbar(error.message, 'error');
      } finally {
        // setResponseErrorCode(null);
      }
    }

    setIsLoading(true);
    const formValid = await trigger();

    try {
      const clientType = tabValue === 1 ? 'pro' : 'person';
      const uploadedUrlsJson = JSON.stringify(uploadedUrls);
      const invoiceData = { ...formData, dealId: deal.id, clientType, uploadedUrls: uploadedUrlsJson };

      const response = await makeAuthenticatedApiCall('post', `/api/qonto/generate`, {
        deal,
        pipeline,
        vat,
        sellingPrice,
        invoiceData,
      });

      if (response?.data?.invoiceFirebaseUrl) {
        window.open(response.data.invoiceFirebaseUrl, '_blank');
        setInvoiceUrl(response.data.invoiceFirebaseUrl);
      }
    } catch (error: any) {
      console.error('Error handling the request:', error);
      openSnackbar(error.response.data.message, 'error');
    } finally {
      setIsLoading(false);
      setOpenPipelineEModal(false);
    }
  };

  useEffect(() => {
    const fetchDealQontoData = async () => {
      setIsQontoLoading(true);
      try {
        if (pipeline === 'b') {
          const response = await makeAuthenticatedApiCall('get', `/api/arbitration/mandate-calculator/${deal.id}`);
          const sellingPrice = response?.data?.selling_final_price;
          setSellingPrice(sellingPrice);
        } else if (pipeline === 'e') {
          const response = await makeAuthenticatedApiCall('get', `/api/arbitration/deposit-sale-calculator/${deal.id}`);
          const sellingPrice = response?.data?.sale_price;
          setSellingPrice(sellingPrice);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to fetch existing mandate data', error);
        setIsLoading(false);
      }

      try {
        const response = await makeAuthenticatedApiCall('get', `/api/qonto/deals/${pipeline}/${deal.id}`);
        if (response?.data) {
          setInvoiceUrl(response.data.invoice_url_firebase || '');
          setLastUpdate(response.data.last_update || '');
        }
      } catch (error: any) {
        console.error(error);
        openSnackbar(error.response.data, 'error');
        return true; // Arrêter l'exécution ici
      } finally {
        setIsQontoLoading(false);
      }
    };

    fetchDealQontoData();
  }, [deal.id]);

  const generateInvoice = async (sellingPrice?: string) => {
    setIsLoading(true);

    try {
      const response = await makeAuthenticatedApiCall('post', `/api/qonto/generate`, {
        deal,
        pipeline, // Si pipeline C on va sauvegarder le prix de vente EVO pour ensuite l'envoyer au LPN
        vat,
        sellingPrice,
      });

      if (response?.data?.invoiceFirebaseUrl) {
        window.open(response.data.invoiceFirebaseUrl, '_blank');
        setInvoiceUrl(response.data.invoiceFirebaseUrl);
      }
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.response.data.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    if (invoiceUrl) {
      window.open(invoiceUrl, '_blank');
      return;
    }

    setIsLoading(true);

    if ((!sellingPrice || sellingPrice === '0') && pipeline !== 'c') {
      handleOpenDialog();
      setIsLoading(false);
      return;
    }

    if (pipeline === 'e' && sellingPrice) {
      setOpenPipelineEModal(true);
      setIsLoading(false);
      return;
    }

    try {
      const response = await makeAuthenticatedApiCall('post', `/api/qonto/generate`, {
        deal,
        pipeline,
        vat,
        sellingPrice,
      });

      if (response?.data?.invoiceFirebaseUrl) {
        window.open(response.data.invoiceFirebaseUrl, '_blank');
        setInvoiceUrl(response.data.invoiceFirebaseUrl);
      }
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.response.data.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        sx={{
          fontSize: '14px',
          fontWeight: 600,
          margin: '2 auto 0',
          maxWidth: '150px',
          textTransform: 'none',
        }}
        onClick={
          role === 'god' || role === 'arbitrator'
            ? pipeline === 'c' && !invoiceUrl
              ? handleOpenEvoPriceDialog
              : handleClick
            : invoiceUrl
              ? () => window.open(invoiceUrl, '_blank')
              : undefined
        }
        variant="contained"
        disabled={isLoading || isQontoLoading || (role !== 'god' && role !== 'arbitrator' && !invoiceUrl)}
      >
        {isLoading || isQontoLoading ? (
          <CircularProgress size={24} />
        ) : invoiceUrl ? (
          '🧾 Voir la facture'
        ) : (
          '🧾 Générer la facture'
        )}
      </Button>
      {lastUpdate && (
        <Typography
          variant="body2"
          sx={{
            fontSize: '13px',
            maxWidth: '200px',
            lineHeight: '1.25',
            marginTop: 1,
            fontWeight: 'medium',
          }}
        >
          Facture générée le {formatDateToDDMMYYYYWithTime(lastUpdate.replace('T', ' ').slice(0, -5))}
        </Typography>
      )}
      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{'Prix de vente manquant'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez d'abord définir le prix de vente dans l'univers {pipeline === 'b' ? 'Mandat' : 'Dépot-Vente'}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.open(`${deal.ext_url}&arbitrator`, '_blank');
              handleCloseDialog();
            }}
            color="primary"
          >
            Aller à {pipeline === 'b' ? 'Mandat' : 'Dépot-Vente'}
          </Button>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEvoPriceDialog} onClose={handleCloseEvoPriceDialog}>
        <DialogTitle>{'Définir le Prix de vente EVO'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Veuillez entrer le prix de vente EVO pour la génération de la facture.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="evoSellingPrice"
            label="Prix de vente EVO"
            type="number"
            fullWidth
            value={evoSellingPrice}
            onChange={(e) => setEvoSellingPrice(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEvoPriceDialog}>Annuler</Button>
          <Button
            onClick={async () => {
              setSellingPrice(evoSellingPrice);
              handleCloseEvoPriceDialog();
              await generateInvoice(evoSellingPrice);
            }}
            color="primary"
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openPipelineEModal} onClose={() => setOpenPipelineEModal(false)} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ textAlign: 'center', fontWeight: '600', color: 'black' }}>
          {'Confirmation du prix de vente'}
        </DialogTitle>
        <Tabs
          value={tabValue}
          sx={{ justifyItems: 'center', margin: 'auto' }}
          onChange={handleTabChange}
          aria-label="Type de client"
        >
          <Tab label="Particulier" {...a11yProps(0)} />
          <Tab label="Professionnel" {...a11yProps(1)} />
        </Tabs>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center', fontWeight: '500', color: 'black' }}>
            Le prix de vente enregistré est de :{' '}
            <span style={{ color: 'black', fontWeight: '500' }}>{priceFormatter.format(sellingPrice)}</span>
          </DialogContentText>
          <TabPanel value={tabValue} index={0}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit((data: FormDataPerson | FormDataPro) => handleClickSubmit(data))}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={validationRules.firstName}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="firstName"
                          label="Prénom"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={validationRules.lastName}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="lastName"
                          label="Nom"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: 'Ce champ est requis' }}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="email"
                          label="Email"
                          type="email"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="dateOfBirth"
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          id="dateOfBirth"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          sx={{ marginTop: '8px' }}
                          className="inputElement"
                          label="Date de naissance"
                          type="date"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="placeOfBirth"
                      control={control}
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="placeOfBirth"
                          label="Lieu de naissance"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  {/* Champ pour le numéro de l'adresse */}
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressNumber"
                      control={control}
                      rules={validationRules.addressNumber}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressNumber"
                          label="Numéro d'adresse"
                          type="number"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  {/* Champ pour l'extension de l'adresse (Bis, Ter, ...) */}
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressExtension"
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressExtension"
                          label="Extension d'adresse"
                          type="text"
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressType"
                      control={control}
                      defaultValue={''}
                      rules={validationRules.addressType}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth margin="dense" error={!!error}>
                          <InputLabel className="inputElement" id={`${field.name}-label`}>
                            Type d'adresse
                          </InputLabel>
                          <Select
                            className="inputElement"
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '0' }}
                            {...field}
                            labelId={`${field.name}-label`}
                            id={field.name}
                            label="Type d'adresse"
                            value={field.value ? field.value.toLowerCase() : ''}
                          >
                            <MenuItem value="rue">Rue</MenuItem>
                            <MenuItem value="all">Allée</MenuItem>
                            <MenuItem value="avn">Avenue</MenuItem>
                            <MenuItem value="blvd">Boulevard</MenuItem>
                            <MenuItem value="chau">Chaussée</MenuItem>
                            <MenuItem value="chm">Chemin</MenuItem>
                            <MenuItem value="cour">Cour</MenuItem>
                            <MenuItem value="fbg">Faubourg</MenuItem>
                            <MenuItem value="fort">Fort</MenuItem>
                            <MenuItem value="imp">Impasse</MenuItem>
                            <MenuItem value="ldit">Lieu-Dit</MenuItem>
                            <MenuItem value="parc">Parc</MenuItem>
                            <MenuItem value="pass">Passage</MenuItem>
                            <MenuItem value="plc">Place</MenuItem>
                            <MenuItem value="quai">Quai</MenuItem>
                            <MenuItem value="rte">Route</MenuItem>
                            <MenuItem value="sent">Sentier</MenuItem>
                            <MenuItem value="squa">Square</MenuItem>
                            <MenuItem value="sans">Inconnu</MenuItem>
                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="addressName"
                      control={control}
                      rules={validationRules.addressName}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="addressName"
                          label="Nom de la voie (Rue, Allée, ou Avenue, ne doivent pas apparaitre ici)"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="addressPostalbox"
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressPostalbox"
                          label="Boîte postale"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      name="postalCode"
                      control={control}
                      rules={validationRules.postalCode}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="postalCode"
                          label="Code postal"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="addressStairs"
                      control={control}
                      rules={{}}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressStairs"
                          label="Étage/Escalier/Appartement"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="addressComp"
                      control={control}
                      rules={{}}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressComp"
                          label="Complément d'adresse"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      name="city"
                      control={control}
                      rules={validationRules.city}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="city"
                          label="Ville"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="country"
                      control={control}
                      rules={validationRules.country}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="country"
                          label="Pays"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="gender"
                      control={control}
                      defaultValue="male"
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Monsieur"
                            className="inputElement"
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Madame"
                            className="inputElement"
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="documentType"
                      control={control}
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth error={!!error}>
                          <InputLabel className="inputElement">Type de document</InputLabel>
                          <Select
                            {...field}
                            value={field.value || ''} // Ensure it is always controlled
                            displayEmpty // This can help with displaying a placeholder or default value
                            className="inputElement"
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '0' }}
                            fullWidth
                            margin="dense"
                            id="documentType"
                          >
                            <MenuItem value="id_card">Carte d'identité</MenuItem>
                            <MenuItem value="passport">Passeport</MenuItem>
                            <MenuItem value="driving_licence">Permis de conduire</MenuItem>
                            <MenuItem value="residence_permit">Titre de séjour</MenuItem>
                          </Select>
                          {error && (
                            <Typography className="inputElement" fontSize={12} color="error">
                              {error.message}
                            </Typography>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="documentId"
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          margin="dense"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          id="documentId"
                          label="ID du document"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="documentIssueDate"
                      rules={tabValue === 0 ? { required: 'Ce champ est requis' } : {}}
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                          margin="dense"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          id="documentIssueDate"
                          label="Date de délivrance du document"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body2">
                      Justificatif de domicile, Passeport ou Carte d'identité (Recto-Verso)
                    </Typography>
                    <FirebaseUploadComponent
                      onDeleteComplete={handleDeleteComplete}
                      onUploadComplete={handleUploadComplete}
                      uploadedUrls={uploadedUrls}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} justifyItems={'flex-end'}>
                    <DialogActions>
                      <Button onClick={() => setOpenPipelineEModal(false)} color="primary">
                        Annuler
                      </Button>
                      <Button
                        sx={{
                          fontWeight: 600,
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || isLoading}
                      >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Valider'}
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit((data: FormDataPerson | FormDataPro) => handleClickSubmit(data))}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="siret"
                      control={control}
                      rules={{
                        required: 'Ce champ est requis',
                        minLength: {
                          value: 14,
                          message: 'Le SIRET doit contenir exactement 14 caractères',
                        },
                        maxLength: {
                          value: 14,
                          message: 'Le SIRET doit contenir exactement 14 caractères',
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          error={!!error}
                          helperText={error ? error.message : ''}
                          {...field}
                          label="SIRET"
                          fullWidth
                          margin="dense"
                          className="inputElement"
                          inputProps={{ maxLength: 14 }}
                          onChange={(e) => field.onChange(removeSpaces(e.target.value))}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="companyName"
                      control={control}
                      rules={{ required: 'Ce champ est requis' }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          label="Raison sociale"
                          fullWidth
                          margin="dense"
                          className="inputElement"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: 'Ce champ est requis' }}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="email"
                          label="Email"
                          type="email"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressNumber"
                      control={control}
                      rules={validationRules.addressNumber}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressNumber"
                          label="Numéro d'adresse"
                          type="number"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressExtension"
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="addressExtension"
                          label="Extension d'adresse"
                          type="text"
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="addressType"
                      control={control}
                      defaultValue={''}
                      rules={validationRules.addressType}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl fullWidth margin="dense" error={!!error}>
                          <InputLabel className="inputElement" id={`${field.name}-label`}>
                            Type d'adresse
                          </InputLabel>
                          <Select
                            className="inputElement"
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '0' }}
                            {...field}
                            labelId={`${field.name}-label`}
                            id={field.name}
                            label="Type d'adresse"
                            value={field.value ? field.value.toLowerCase() : ''}
                          >
                            <MenuItem value="rue">Rue</MenuItem>
                            <MenuItem value="all">Allée</MenuItem>
                            <MenuItem value="avn">Avenue</MenuItem>
                            <MenuItem value="blvd">Boulevard</MenuItem>
                            <MenuItem value="chau">Chaussée</MenuItem>
                            <MenuItem value="chm">Chemin</MenuItem>
                            <MenuItem value="cour">Cour</MenuItem>
                            <MenuItem value="fbg">Faubourg</MenuItem>
                            <MenuItem value="fort">Fort</MenuItem>
                            <MenuItem value="imp">Impasse</MenuItem>
                            <MenuItem value="ldit">Lieu-Dit</MenuItem>
                            <MenuItem value="parc">Parc</MenuItem>
                            <MenuItem value="pass">Passage</MenuItem>
                            <MenuItem value="plc">Place</MenuItem>
                            <MenuItem value="quai">Quai</MenuItem>
                            <MenuItem value="rte">Route</MenuItem>
                            <MenuItem value="sent">Sentier</MenuItem>
                            <MenuItem value="squa">Square</MenuItem>
                            <MenuItem value="sans">Inconnu</MenuItem>
                          </Select>
                          {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="addressName"
                      control={control}
                      rules={validationRules.addressName}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          error={!!error}
                          helperText={error ? error.message : ''}
                          {...field}
                          className="inputElement"
                          margin="dense"
                          id="addressName"
                          label="Nom de la voie (Rue, Allée, ou Avenue, ne doivent pas apparaitre ici)"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      name="postalCode"
                      control={control}
                      rules={validationRules.postalCode}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="postalCode"
                          label="Code postal"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      name="city"
                      control={control}
                      rules={validationRules.city}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ''}
                          className="inputElement"
                          margin="dense"
                          id="city"
                          label="Ville"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="country"
                      control={control}
                      rules={validationRules.country}
                      defaultValue={''}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className="inputElement"
                          error={!!error}
                          helperText={error ? error.message : ''}
                          margin="dense"
                          id="country"
                          label="Pays"
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body2">KBIS</Typography>
                    <FirebaseUploadComponent
                      onDeleteComplete={handleDeleteComplete}
                      onUploadComplete={handleUploadComplete}
                      uploadedUrls={uploadedUrls}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} justifyItems={'flex-end'}>
                    <DialogActions>
                      <Button onClick={() => setOpenPipelineEModal(false)} color="primary">
                        Annuler
                      </Button>
                      <Button
                        sx={{
                          fontWeight: 600,
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!isValid || isLoading}
                      >
                        {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Valider'}
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GenerateInvoice;
