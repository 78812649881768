import { Container } from '@mui/material';

import CreateCotation from '../components/CreateCotation';

const CreateCotationPage = () => {
  return (
    <Container maxWidth={false}>
      <CreateCotation />
    </Container>
  );
};

export default CreateCotationPage;
