import React from 'react';

import { Box, Button, Card, Grid, Tooltip as MuiTooltip, Typography } from '@mui/material';

import { useMonthlyStats } from '../../hooks/useApiQueries';
import theme from '../../theme';
import { priceFormatter } from '../../utils';

interface MonthlyStatsOverviewProps {
  franchise?: string;
  orgName?: string;
  role?: string;
}

const MonthlyStatsOverview: React.FC<MonthlyStatsOverviewProps> = ({ franchise, orgName, role }) => {
  const { data: stats, isLoading } = useMonthlyStats(franchise, orgName);

  if (isLoading || !stats) return null;

  if (stats.currentMonth.totalCotations === 0 && role === 'agency') {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '200px' }}>
        <Card
          elevation={5}
          sx={{
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(0),
            width: '100%',
            background: 'linear-gradient(135deg, #fff8e1 0%, #ffe57f 100%)', // Dégradé utilisant le jaune de la charte
          }}
        >
          <Typography
            variant="h6"
            align="center"
            sx={{
              marginBottom: theme.spacing(1),
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            📊 Pas encore de cotations
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{
              marginBottom: theme.spacing(2),
              color: 'black',
            }}
          >
            Faites une première demande de cotations pour voir vos statistiques.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            type="button"
            className="ActionButton"
            sx={{
              width: '350px',
              margin: '22px auto !important',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': {
                backgroundColor: theme.palette.saffron.dark,
              },
            }}
            href="/cotation"
          >
            🚗 Faire ma première demande de reprise
          </Button>
        </Card>
      </Grid>
    );
  }

  const calculatePercentageChange = (current: number, previous: number): string => {
    if (previous === 0) return '+∞';
    const change = ((current - previous) / previous) * 100;
    return `${change > 0 ? '+' : ''}${change.toFixed(1)}%`;
  };

  const getVariationColor = (current: number, previous: number): string => {
    if (previous === 0) return theme.palette.warning.main;
    return current >= previous ? theme.palette.warning.main : theme.palette.error.main;
  };

  const statsConfig = [
    {
      label: 'Cotations',
      count: {
        current: stats.currentMonth.totalCotations,
        previous: stats.previousMonth.totalCotations,
        label: 'Nombre',
      },
      value: {
        current:
          role === 'agency'
            ? stats.currentMonth.totalCotationsSkipcarPotentialValue
            : stats.currentMonth.totalCotationsPotentialValue,
        previous:
          role === 'agency'
            ? stats.previousMonth.totalCotationsSkipcarPotentialValue
            : stats.previousMonth.totalCotationsPotentialValue,
        label: 'Valeur potentielle',
      },
      tooltip: 'Nombre et valeur potentielle des cotations du mois',
    },
    {
      label: 'Achats',
      count: {
        current: stats.currentMonth.totalBuying,
        previous: stats.previousMonth.totalBuying,
        label: 'Nombre',
      },
      value: {
        current: role === 'agency' ? stats.currentMonth.totalBuyingSkipcarValue : stats.currentMonth.totalBuyingValue,
        previous:
          role === 'agency' ? stats.previousMonth.totalBuyingSkipcarValue : stats.previousMonth.totalBuyingValue,
        label: 'Valeur',
      },
      tooltip: 'Nombre et valeur des achats du mois',
    },
    ...(role !== 'agency'
      ? [
          {
            label: 'Ventes',
            count: {
              current: stats.currentMonth.totalSelling,
              previous: stats.previousMonth.totalSelling,
              label: 'Nombre',
            },
            value: {
              current: stats.currentMonth.totalSellingValue,
              previous: stats.previousMonth.totalSellingValue,
              label: 'Valeur',
            },
            netMargin: {
              current: stats.currentMonth.totalNetMarginValue,
              previous: stats.previousMonth.totalNetMarginValue,
              label: 'Marge nette',
            },
            tooltip: 'Nombre, valeur et marge nette des ventes du mois',
          },
        ]
      : []),
  ];

  const MetricComparison = ({
    current,
    previous,
    label,
    isPrice = false,
  }: {
    current: number;
    previous: number;
    label: string;
    isPrice?: boolean;
  }) => (
    <Box sx={{ marginBottom: 1 }}>
      <Typography variant="body1" sx={{ fontSize: '12px' }}>
        {label}
      </Typography>
      <Box>
        <Typography variant="h6" fontWeight="bold">
          {isPrice ? priceFormatter.format(current) : current}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: getVariationColor(current, previous),
            fontSize: '12px',
          }}
        >
          <b>
            {calculatePercentageChange(current, previous)} / {isPrice ? priceFormatter.format(previous) : previous} vs
            m-1
          </b>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={2} sx={{ marginBottom: theme.spacing(4) }}>
      {statsConfig.map((stat) => (
        <Grid
          item
          xs={12}
          sm={
            stat.label === 'Achats' && role === 'agency'
              ? 6
              : stat.label === 'Ventes' && role === 'agency'
                ? 6
                : stat.label === 'Ventes' && role !== 'agency'
                  ? 5
                  : stat.label === 'Cotations' && role === 'agency'
                    ? 6
                    : stat.label === 'Cotation' && role !== 'agency'
                      ? 4
                      : stat.label === 'Achats' && role !== 'agency'
                        ? 3
                        : 4
          }
          key={stat.label}
        >
          <MuiTooltip title={stat.tooltip} arrow placement="top">
            <Card
              elevation={5}
              sx={{
                padding: theme.spacing(2),
                ...(stat.label === 'Achats' &&
                  stats.currentMonth.totalBuying === 0 && {
                    background: 'linear-gradient(135deg, #fff8e1 0%, #ffe57f 100%)',
                    minHeight: '123px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }),
              }}
            >
              {stat.label === 'Achats' && stats.currentMonth.totalBuying === 0 ? (
                <>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{
                      marginBottom: theme.spacing(1),
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    🚙 Pas encore d'achats
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    {stat.label}
                  </Typography>

                  <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between">
                    <MetricComparison
                      current={stat.count.current}
                      previous={stat.count.previous}
                      label={stat.count.label}
                    />
                    <MetricComparison
                      current={stat.value.current}
                      previous={stat.value.previous}
                      label={stat.value.label}
                      isPrice={true}
                    />
                    {stat.netMargin && (
                      <MetricComparison
                        current={stat.netMargin.current}
                        previous={stat.netMargin.previous}
                        label={stat.netMargin.label}
                        isPrice={true}
                      />
                    )}
                  </Box>
                </>
              )}
            </Card>
          </MuiTooltip>
        </Grid>
      ))}
    </Grid>
  );
};

export default MonthlyStatsOverview;
