import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Tooltip as MuiTooltip,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { endOfMonth, format, startOfMonth, startOfYear, subDays } from 'date-fns';

import { makeAuthenticatedApiCall } from '../apiHelper';
import { AgencyPerformance } from '../components/Statistics/AgencyPerformance';
import AgencyStatsOverview from '../components/Statistics/AgencyStatsOverview';
import DailyBuyingStatsTable from '../components/Statistics/DailyBuyingStatsTable';
import DailySellingStatsTable from '../components/Statistics/DailySellingStatsTable';
import MonthlyStatsOverview from '../components/Statistics/MonthlyStatsOverview';
import StockOverview from '../components/Statistics/StockOverview';
import {
  useArbitrationPerformance,
  useFlopTransformations,
  useGetAgencies,
  useGetFranchise,
  useStatisticsCountByDate,
  useTopAgencies,
  useTopTransformations,
} from '../hooks/useApiQueries';
import { capitalizeFirstLetter, priceFormatter } from '../utils';

// Register ChartJS components
ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [selectedFranchise, setSelectedFranchise] = useState<string>('');
  const [selectedAgency, setSelectedAgency] = useState<string>('');
  const theme = useTheme();
  const { t } = useTranslation();

  const { data: franchiseData } = useGetFranchise();
  const { data: agenciesData } = useGetAgencies();

  // Initialiser startDate et endDate avec les valeurs par défaut
  const [startDate, setStartDate] = useState<Date>(startOfYear(new Date()));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>(null);

  // New state for daily additional statistics
  const [dailyAdditionalStats, setDailyAdditionalStats] = useState<any[]>([]);

  const { data: topArbitrations } = useArbitrationPerformance('top', startDate, endDate, selectedFranchise);
  const { data: flopArbitrations } = useArbitrationPerformance('flop', startDate, endDate, selectedFranchise);

  const { data: topAgencies } = useTopAgencies(startDate, endDate, selectedFranchise);

  const { data: flopTransformations, isLoading: flopTransformationsLoading } = useFlopTransformations(
    startDate,
    endDate,
    selectedFranchise,
  );

  const { data: topTransformations, isLoading: topTransformationsLoading } = useTopTransformations(
    startDate,
    endDate,
    selectedFranchise,
  );

  const { data: cotationsData } = useStatisticsCountByDate(startDate, endDate, selectedFranchise, selectedAgency);

  const handleFranchiseChange = (value: string) => {
    setSelectedFranchise(value);
    setSelectedAgency(''); // Reset agency selection when franchise changes
  };

  const handleDateRangeClick = (range: string) => {
    const today = new Date();
    switch (range) {
      case '30jours':
        setStartDate(subDays(today, 30));
        setEndDate(today);
        break;
      case 'mois':
        setStartDate(startOfMonth(today));
        setEndDate(endOfMonth(today));
        break;
      case 'annee':
        setStartDate(startOfYear(today));
        setEndDate(today);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchAdditionalStatistics = async () => {
      if (!startDate || !endDate) {
        console.error('Start date and end date must be defined');
        return;
      }

      try {
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');
        const franchise = selectedFranchise;
        const agency = selectedAgency;

        const [
          doubleCotationsResponse,
          processedArbitrationsResponse,
          acceptedArbitrationsResponse,
          awaitingArbitrationsResponse,
        ] = await Promise.all([
          makeAuthenticatedApiCall(
            'get',
            `/api/statistics/arbitrations/count?startDate=${formattedStartDate}&endDate=${formattedEndDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
          ),
          makeAuthenticatedApiCall(
            'get',
            `/api/statistics/arbitrations/processed?startDate=${formattedStartDate}&endDate=${formattedEndDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
          ),
          makeAuthenticatedApiCall(
            'get',
            `/api/statistics/arbitrations/accepted?startDate=${formattedStartDate}&endDate=${formattedEndDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
          ),
          makeAuthenticatedApiCall(
            'get',
            `/api/statistics/arbitrations/awaiting?startDate=${formattedStartDate}&endDate=${formattedEndDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
          ),
        ]);

        // Aggregate data by date
        const aggregatedData: Record<string, any> = {};

        const aggregateResponse = (response: any, key: string) => {
          response.data.forEach((item: any) => {
            const date = format(new Date(item.cotation_date || item.arbitration_date), 'dd/MM/yyyy');
            if (!aggregatedData[date]) {
              aggregatedData[date] = {
                date,
                doubleCotations: 0,
                processedArbitrations: 0,
                acceptedArbitrations: 0,
                awaitingArbitrations: 0,
              };
            }
            aggregatedData[date][key] += item.cotation_count || item.arbitration_count;
          });
        };

        aggregateResponse(doubleCotationsResponse, 'doubleCotations');
        aggregateResponse(processedArbitrationsResponse, 'processedArbitrations');
        aggregateResponse(acceptedArbitrationsResponse, 'acceptedArbitrations');
        aggregateResponse(awaitingArbitrationsResponse, 'awaitingArbitrations');

        setDailyAdditionalStats(Object.values(aggregatedData));
      } catch (error) {
        console.error('Error fetching additional statistics:', error);
      }
    };

    fetchAdditionalStatistics();
  }, [startDate, endDate, selectedFranchise, selectedAgency]);

  // Configuration des options du graphique
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label || '';
            return `${label}: ${context.raw}`;
          },
        },
      },
    },
  };

  const totalDoubleCotations = dailyAdditionalStats.reduce((sum, stat) => sum + stat.doubleCotations, 0);
  const totalProcessedArbitrations = dailyAdditionalStats.reduce((sum, stat) => sum + stat.processedArbitrations, 0);
  const totalAcceptedArbitrations = dailyAdditionalStats.reduce((sum, stat) => sum + stat.acceptedArbitrations, 0);
  const totalAwaitingArbitrations = dailyAdditionalStats.reduce((sum, stat) => sum + stat.awaitingArbitrations, 0);

  const totalCotations = useMemo(() => {
    if (!cotationsData) return 0;
    return cotationsData.reduce((sum, day) => sum + day.count, 0);
  }, [cotationsData]);

  const handleUpdateFranchises = async () => {
    try {
      const response = await axios.get('/api/deals/update-organizations-franchise');
      console.log('response', response);
    } catch (error) {
      console.error('Erreur lors de la mise à jour des franchises:', error);
    }
  };

  return (
    <Container maxWidth={false} sx={{ padding: theme.spacing(4), backgroundColor: theme.palette.background.default }}>
      <Typography variant="body1" fontSize="30px" gutterBottom textAlign="center" marginBottom={theme.spacing(4)}>
        Tableau de bord
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        type="button"
        className="ActionButton"
        sx={{ display: 'none', width: '250px', margin: '8px auto 0 !important' }}
        onClick={handleUpdateFranchises}
      >
        🔄 Mettre à jour les franchises
      </Button>

      <AgencyStatsOverview />

      {/* Top Agencies Section */}
      <Grid
        container
        spacing={4}
        sx={{
          marginTop: theme.spacing(4),
          width: '100%',
          maxWidth: 1200,
          mx: 'auto',
          marginBottom: theme.spacing(1),
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="body1" gutterBottom align="center" sx={{ marginBottom: theme.spacing(4) }}>
            Statistiques sur le mois en cours
          </Typography>
          <MonthlyStatsOverview franchise={selectedFranchise} orgName={selectedAgency} />
        </Box>
      </Grid>

      {/* Stock Overview Component */}
      <StockOverview franchise={selectedFranchise} orgName={selectedAgency} />

      <Divider
        sx={{ marginTop: theme.spacing(8), border: 0, height: '2px', backgroundColor: theme.palette.saffron.main }}
      />
      <Grid container sx={{ maxWidth: '1200px', mx: 'auto', marginTop: theme.spacing(8) }}>
        <Grid item xs={12} sm={3}>
          <FormControl variant="outlined">
            <InputLabel className="inputElement" id="franchise-select-label">
              Franchise
            </InputLabel>
            <Select
              labelId="franchise-select-label"
              id="franchise-select"
              className="inputElement"
              sx={{ width: 250 }}
              value={selectedFranchise}
              onChange={(e) => handleFranchiseChange(e.target.value)}
              label="Franchise"
            >
              <MenuItem value="">
                <em>Aucune sélection</em>
              </MenuItem>
              {Array.isArray(franchiseData) &&
                franchiseData.map((franchise: any) => (
                  <MenuItem key={franchise.franchise} value={franchise.franchise}>
                    {franchise.franchise === 'Skipcar' ? 'Ewigo' : franchise.franchise}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl variant="outlined">
            <InputLabel className="inputElement" id="agency-select-label">
              Agence
            </InputLabel>
            <Select
              labelId="agency-select-label"
              id="agency-select"
              sx={{ width: 250 }}
              className="inputElement"
              value={selectedAgency}
              onChange={(e) => setSelectedAgency(e.target.value)}
              label="Agence"
            >
              <MenuItem value="">
                <em>Aucune sélection</em>
              </MenuItem>
              {(agenciesData as any[])
                ?.filter((agency: any) => agency.franchise === selectedFranchise)
                .map((agency: any) => (
                  <MenuItem key={agency.org_name} value={agency.org_name}>
                    {agency.org_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" justifyContent="space-between">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} sm={3} flexDirection="column" display="flex">
              <DatePicker
                className="inputElement"
                sx={{ width: 250 }}
                label="Date de début"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue ?? new Date())}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="JJ/MM/AAAA"
                    inputProps={{
                      ...params.inputProps,
                      mask: '__/__/____',
                    }}
                  />
                )}
                format="dd/MM/yyyy"
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '16px', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleDateRangeClick('30jours')}
                >
                  30 jours glissants
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '16px', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleDateRangeClick('mois')}
                >
                  Mois en cours
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '16px', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleDateRangeClick('annee')}
                >
                  Année en cours
                </Typography>
              </Box>
            </Grid>

            <DatePicker
              label="Date de fin"
              className="inputElement"
              value={endDate}
              sx={{ width: 250 }}
              onChange={(newValue) => setEndDate(newValue ?? new Date())}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="JJ/MM/AAAA"
                  inputProps={{
                    ...params.inputProps,
                    mask: '__/__/____',
                  }}
                />
              )}
              format="dd/MM/yyyy"
            />
          </LocalizationProvider>
        </Grid>

        <Grid container spacing={4} sx={{ marginTop: theme.spacing(4) }}>
          <Grid item xs={12}>
            <Card
              elevation={5}
              sx={{
                padding: theme.spacing(2),
                maxWidth: '400px',
                margin: '0 auto',
                marginBottom: theme.spacing(4),
              }}
            >
              <Typography variant="h6" align="center" sx={{ marginBottom: 2 }}>
                Nombre de cotations sur la période
              </Typography>
              <Typography variant="h4" align="center" fontWeight="bold">
                {totalCotations}
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom align="center">
              Transformations
            </Typography>
          </Grid>

          {!topTransformationsLoading && topTransformations && (
            <Grid item xs={12} md={6}>
              <AgencyPerformance data={topTransformations} title="Top 5 Transformations" />
            </Grid>
          )}
          {!flopTransformationsLoading && flopTransformations && (
            <Grid item xs={12} md={6}>
              <AgencyPerformance data={flopTransformations} title="Flop 5 Transformations" />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={4} sx={{ marginTop: theme.spacing(6) }}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom align="center">
              Les Top
            </Typography>
          </Grid>
          {topAgencies &&
            Object.entries(topAgencies).map(([category, agency]: [string, (typeof topAgencies)[0]], index) => (
              <Grid item xs={12} sm={4} key={category} sx={index === 0 ? { paddingLeft: '0 !important' } : {}}>
                <Card elevation={5} sx={{ padding: theme.spacing(2) }}>
                  <Typography variant="body1" sx={{ textAlign: 'left', color: theme.palette.saffron.main }}>
                    {`${t(category.charAt(0).toUpperCase() + category.slice(1))}`}
                  </Typography>
                  <CardContent>
                    <Grid container spacing={2} direction="column">
                      {agency.map((agency: any) => (
                        <Grid container item xs={12} key={agency.org_name} justifyContent="space-between">
                          <Typography
                            variant="body2"
                            sx={{ fontSize: '14px', fontWeight: 600, color: theme.palette.black.main }}
                          >
                            {capitalizeFirstLetter(agency.org_name)}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: '14px', fontWeight: 600, color: theme.palette.black.light }}
                          >
                            {`${agency.count} ${agency.net_amount ? ` / ${priceFormatter.format(agency.net_amount)}` : ''} ${agency.net_margin ? ` / ${priceFormatter.format(agency.net_margin)}` : ''}`}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          <Grid item xs={12} alignSelf="center" justifyContent="center" display="flex">
            <Button
              href="/detailed-statistics"
              variant="contained"
              color="primary"
              sx={{
                fontWeight: 600,
                color: 'black !important',
                textTransform: 'none',
                fontSize: '18px',
                marginTop: theme.spacing(2),
                backgroundColor: theme.palette.saffron.main,
                '&:hover': {
                  backgroundColor: theme.palette.saffron.dark,
                },
              }}
            >
              Voir les statistiques détaillées
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          sx={{
            marginTop: theme.spacing(0),
            width: '100%',
            maxWidth: 1200,
            mx: 'auto',
            marginBottom: theme.spacing(2),
          }}
        >
          <Box sx={{ width: '100%', marginTop: theme.spacing(8) }}>
            <Typography variant="body1" gutterBottom align="center" sx={{ marginBottom: theme.spacing(1) }}>
              Statistiques au jour le jour
            </Typography>
          </Box>
          <Box sx={{ width: '100%', maxWidth: 1200, mx: 'auto' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card sx={{ marginTop: theme.spacing(5) }} elevation={5}>
                  <CardHeader title="Achats" sx={{ textAlign: 'center' }} />
                  <CardContent>
                    <DailyBuyingStatsTable franchise={selectedFranchise} orgName={selectedAgency} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ marginTop: theme.spacing(5) }} elevation={5}>
                  <CardHeader title="Ventes" align="center" />
                  <CardContent>
                    <DailySellingStatsTable franchise={selectedFranchise} orgName={selectedAgency} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} display="none">
                <Card sx={{ marginTop: theme.spacing(5) }} elevation={5}>
                  <CardHeader title="Statistiques des arbitrages" sx={{ textAlign: 'center' }} />
                  <CardContent>
                    <TableContainer component={Paper} sx={{ marginTop: 0 }}>
                      <Table>
                        <TableHead>
                          <TableRow sx={{ background: '#262626' }}>
                            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Date</TableCell>
                            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Double Cotations</TableCell>
                            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Arbitrages Traités</TableCell>
                            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Arbitrages Acceptés</TableCell>
                            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Arbitrages en Attente</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dailyAdditionalStats.map((stat) => (
                            <TableRow key={stat.date}>
                              <TableCell>{stat.date}</TableCell>
                              <TableCell>{stat.doubleCotations}</TableCell>
                              <TableCell>{stat.processedArbitrations}</TableCell>
                              <TableCell>{stat.acceptedArbitrations}</TableCell>
                              <TableCell>{stat.awaitingArbitrations}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          spacing={4}
          sx={{
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(0),
            width: '100%',
            maxWidth: 1200,
            mx: 'auto',
          }}
        >
          <Box sx={{ width: '100%', display: 'none' }}>
            <Typography variant="body1" gutterBottom>
              Statistiques des arbitrages sur la période
            </Typography>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} sm={6} md={3}>
                <MuiTooltip
                  title="Cette requête compte le nombre de fois où on a cliqué sur le bouton 'Double-cotations' dans la période sélectionnée."
                  arrow
                  placement="top"
                >
                  <Card variant="outlined" sx={{ padding: theme.spacing(2), textAlign: 'left' }}>
                    <Typography variant="body1" sx={{ fontSize: '14px' }}>
                      Doubles-cotations
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {totalDoubleCotations}
                    </Typography>
                    <Typography variant="body2" color="red" sx={{ fontSize: '14px', color: theme.palette.red.main }}>
                      <b>-2.3%</b> vs last period (fausse stat)
                    </Typography>
                  </Card>
                </MuiTooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MuiTooltip
                  title="Cette requête compte le nombre de cas d'arbitrage traités dans la période sélectionnée."
                  arrow
                  placement="top"
                >
                  <Card variant="outlined" sx={{ padding: theme.spacing(2), textAlign: 'left' }}>
                    <Typography variant="body1" sx={{ fontSize: '14px' }}>
                      Arbitrages traités
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {totalProcessedArbitrations}
                    </Typography>
                    <Typography variant="body2" color="red" sx={{ fontSize: '14px', color: theme.palette.red.main }}>
                      <b>-2.3%</b> vs last period (fausse stat)
                    </Typography>
                  </Card>
                </MuiTooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MuiTooltip
                  title="Cette requête compte le nombre de cas d'arbitrage acceptés par une agence dans la période sélectionnée."
                  arrow
                  placement="top"
                >
                  <Card variant="outlined" sx={{ padding: theme.spacing(2), textAlign: 'left' }}>
                    <Typography variant="body1" sx={{ fontSize: '14px' }}>
                      Arbitrages acceptés
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {totalAcceptedArbitrations}
                    </Typography>
                    <Typography variant="body2" color="red" sx={{ fontSize: '14px', color: theme.palette.red.main }}>
                      <b>-2.3%</b> vs last period (fausse stat)
                    </Typography>
                  </Card>
                </MuiTooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MuiTooltip
                  title="Cette requête compte le nombre de cas d'arbitrage en attente d'action dans la période sélectionnée."
                  arrow
                  placement="top"
                >
                  <Card variant="outlined" sx={{ padding: theme.spacing(2), textAlign: 'left' }}>
                    <Typography variant="body1" sx={{ fontSize: '14px' }}>
                      Arbitrages en attente
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {totalAwaitingArbitrations}
                    </Typography>
                    <Typography variant="body2" color="red" sx={{ fontSize: '14px', color: theme.palette.red.main }}>
                      <b>-2.3%</b> vs last period (fausse stat)
                    </Typography>
                  </Card>
                </MuiTooltip>
              </Grid>
            </Grid>
            <Grid container spacing={4} sx={{ marginTop: theme.spacing(1) }}>
              {topArbitrations && (
                <Grid item xs={12} sm={6}>
                  <Card elevation={5} sx={{ padding: theme.spacing(2) }}>
                    <Typography variant="body1" sx={{ textAlign: 'left', color: theme.palette.saffron.main }}>
                      Top 5 Arbitrages
                    </Typography>
                    <CardContent>
                      <Grid container spacing={2} direction="column">
                        {topArbitrations.map((arbitration: any) => (
                          <Grid container item xs={12} key={arbitration.org_name} justifyContent="space-between">
                            <Typography variant="body2" sx={{ color: theme.palette.black.main }}>
                              {capitalizeFirstLetter(arbitration.org_name)}
                            </Typography>
                            <Typography variant="body1" sx={{ color: theme.palette.black.light }}>
                              {arbitration.count}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {flopArbitrations && (
                <Grid item xs={12} sm={6}>
                  <Card elevation={5} sx={{ padding: theme.spacing(2) }}>
                    <Typography variant="body1" sx={{ textAlign: 'left', color: theme.palette.red.main }}>
                      Flop 5 Arbitrages
                    </Typography>
                    <CardContent>
                      <Grid container spacing={2} direction="column">
                        {flopArbitrations.map((arbitration: any) => (
                          <Grid container item xs={12} key={arbitration.org_name} justifyContent="space-between">
                            <Typography variant="body2" sx={{ color: theme.palette.black.main }}>
                              {capitalizeFirstLetter(arbitration.org_name)}
                            </Typography>
                            <Typography variant="body1" sx={{ color: theme.palette.black.light }}>
                              {arbitration.count}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
        <Box sx={{ width: '100%', maxWidth: 1000, mx: 'auto' }}>
          <Card elevation={10}>
            {loading ? (
              <CircularProgress sx={{ alignSelf: 'center' }} />
            ) : (
              chartData && <Line data={chartData} options={options} />
            )}
          </Card>
        </Box>
      </Grid>
    </Container>
  );
};

export default Dashboard;
