import React, { useEffect, useMemo, useState } from 'react';

import {
  AlertColor,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';

import { makeAuthenticatedApiCall } from '../../apiHelper';
import { DBDeal, MarketplaceDeal } from '../../types/deal';
import { Role } from '../../types/user';
import { priceFormatter } from '../../utils';
import { ErrorMessageSnackbar, openSnackbar } from '../ErrorMessageSnackbar';
import MarketPlaceStatus from './MarketPlaceStatus';
import { isInMarketPlace } from './isInMarketPlace';

interface MarketPlaceButtonProps {
  dealId: number | null;
  userRole: Role;
  dealData?: DBDeal | MarketplaceDeal;
  hideSendEmButton?: boolean;
}

const ALLOWED_ROLES = [Role.God, Role.Arbitrator];

const MarketPlaceButton: React.FC<MarketPlaceButtonProps> = ({ dealId, userRole, dealData, hideSendEmButton }) => {
  const [marketplaceRequested, setMarketplaceRequested] = useState(false);
  const [marketplaceDate, setMarketplaceDate] = useState<Date>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('info');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [minimumPrice, setMinimumPrice] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  if (dealId === null) {
    // pipedrive_deal_id en BDD
    return;
  }

  useEffect(() => {
    if (dealData && dealId >= 8929) {
      if (isInMarketPlace(dealData)) {
        setMarketplaceRequested(true);
        if (dealData.marketplace_sent_date) {
          setMarketplaceDate(new Date(dealData.marketplace_sent_date));
        }
        if (dealData.minimum_em_price) {
          setMinimumPrice(dealData.minimum_em_price);
        } else if (dealData.purchase_price) {
          setMinimumPrice(dealData.purchase_price);
        }
      } else {
        setMarketplaceRequested(false);
      }
    }
  }, [dealData, dealId]);

  const timeElapsedPercentage = useMemo(() => {
    if (!dealData?.marketplace_sent_date) return undefined;
    if (!dealData.marketplace_expiration_date) return undefined;

    const sentEmDate = Date.parse(dealData.marketplace_sent_date);
    const expirationDate = Date.parse(dealData.marketplace_expiration_date);
    const now = Date.now();

    const diff = now - sentEmDate;
    const maxDuration = expirationDate - sentEmDate;

    // S'assurer que le pourcentage ne dépasse pas 100%
    if (maxDuration === 0) return 100;
    return Math.min((diff / maxDuration) * 100, 100);
  }, [dealData?.marketplace_sent_date, dealData?.marketplace_expiration_date]);

  // Création de la fonction openSnackbar personnalisée
  const handleOpenSnackbar = openSnackbar(setSnackbarMessage, setSnackbarOpen, setSeverity);

  const handleDeleteFromMarketplace = async () => {
    setIsLoading(true); // Commence le chargement
    try {
      await makeAuthenticatedApiCall('DELETE', `/api/marketplace/deal/${dealId}`);
    } catch (error) {
      console.error("Erreur lors de l'envoi des données", error);
      handleOpenSnackbar('Erreur lors de la mise à jour du deal', 'error');
      handleApiError(error, 'handleDeleteFromMarketplace');
    } finally {
      setMarketplaceRequested(false);
      setMarketplaceDate(undefined);
      handleOpenSnackbar('Affaire retirée de la marketplace', 'success');
      setIsLoading(false); // Stoppe le chargement
    }
  };
  const handleApiError = (error: any, context: string) => {
    Sentry.withScope((scope) => {
      scope.setTag('where', context);
      scope.setExtra('deal', dealId);

      if (axios.isAxiosError(error)) {
        if (error.response) {
          scope.setExtra('response', {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers,
          });
          handleOpenSnackbar(`Erreur serveur: ${error.response.status}`, 'error');
        } else if (error.request) {
          scope.setExtra('request', error.request);
          handleOpenSnackbar('Erreur réseau ou serveur non disponible', 'error');
        } else {
          handleOpenSnackbar('Erreur inattendue lors de la communication avec le serveur', 'error');
        }
      } else {
        handleOpenSnackbar('Une erreur inattendue est survenue', 'error');
      }

      Sentry.captureException(error);
    });
  };

  const handleSendToMarketplaceClick = () => {
    setIsPopupOpen(true);
  };

  const handleSendToMarketplace = async () => {
    setIsLoading(true); // Commence le chargement
    try {
      await makeAuthenticatedApiCall('PUT', `/api/marketplace/deal/${dealId}`, { minimumPrice });
    } catch (error) {
      console.error("Erreur lors de l'envoi des données", error);
      handleOpenSnackbar("Erreur lors de l'envoi du deal", 'error');
      handleApiError(error, 'handleSendToMarketplace');
    } finally {
      handleOpenSnackbar('Deal envoyé avec succès', 'success');
      setMarketplaceRequested(true);
      setMarketplaceDate(new Date());
      setIsLoading(false); // Arrête le chargement
      setIsPopupOpen(false); // Ferme la modale
    }
  };

  if (!ALLOWED_ROLES.includes(userRole)) return null;
  return (
    <>
      {!marketplaceRequested ? (
        <Fade in={true}>
          <Button
            variant="contained"
            size="small"
            id="send_to_em"
            sx={{
              color: 'white',
              fontSize: 13,
              marginTop: 1,
              marginBottom: 1,
              display: hideSendEmButton ? 'none' : 'block',
            }}
            onClick={handleSendToMarketplaceClick}
          >
            🚀 Envoyer sur la marketplace
          </Button>
        </Fade>
      ) : (
        <MarketPlaceStatus
          marketplaceDate={marketplaceDate}
          minimumPrice={priceFormatter.format(parseInt(minimumPrice))}
          isLoading={isLoading}
          dealId={dealId}
          handleDeleteFromMarketplace={handleDeleteFromMarketplace}
          timeElapsedPercentage={timeElapsedPercentage}
        />
      )}
      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
      <Dialog open={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="minimumPrice"
            label="Prix Minimum (€)"
            type="number"
            variant="outlined"
            value={minimumPrice}
            onChange={(e) => setMinimumPrice(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ order: 2 }}
            variant="contained"
            onClick={handleSendToMarketplace}
            disabled={!minimumPrice || isLoading}
            startIcon={isLoading && <CircularProgress size={24} />}
          >
            Confirmer
          </Button>
          <Button sx={{ order: 1 }} onClick={() => setIsPopupOpen(false)}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MarketPlaceButton;
