import React, { useState } from 'react';

import {
  Grid,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import theme from '../../theme';

const OfferLegends = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openedPopoverId, setOpenedPopoverId] = useState<string | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const offerDetails = {
    '30-jours': {
      emoji: '⏰',
      title: 'Mandat 30 jours',
      description: `Offre Mandat 30 jours : Portage de stock. SKIPCAR porte le stock pendant 30 jours. Si le véhicule se vend, SKIPCAR revend le véhicule à l'agence sinon, SKIPCAR rapatrie le véhicule ou le met en dépôt-vente,
+ L'agence pilote la vente (fixe le prix de vente assurant ainsi le contrôle total de sa marge ; assure les frais de remise en état et la garantie)
+ Aucun frais Skipcar, si le véhicule n'est pas vendu durant les 30 jours.`,
    },
    'reprise-seche': {
      emoji: '⚡',
      title: 'Reprise sèche',
      description: `SKIPCAR achète le véhicule et assure le rapatriement à ses frais. Permet de proposer une reprise pour faire une vente sans s'occuper de la vente du véhicule repris (souvent utilisé pour hors charte ou moins attractif) Commission d'apporteur d'affaires possible à l'entrée.`,
    },
    'depot-vente': {
      emoji: '🏪',
      title: 'Dépôt-Vente',
      description: `Acheteur et vendeur, SKIPCAR offre un mandat d'intermédiation de 990€ TTC à l'agence pour commercialiser le véhicule sans en devenir titulaire. SKIPCAR assume la garantie légale de conformité, les frais de remise en état et positionne une garantie 12 mois (si aucune extension de garantie n'a été vendue).`,
    },
  };

  const commissionData = [
    { min: '1 €', max: '4 999 €', commission: '580 €' },
    { min: '5 000 €', max: '7 999 €', commission: '820 €' },
    { min: '8 000 €', max: '10 999 €', commission: '940 €' },
    { min: '11 000 €', max: '14 999 €', commission: '1 050 €' },
    { min: '15 000 €', max: '19 999 €', commission: '1 250 €' },
    { min: '20 000 €', max: '24 999 €', commission: '1 500 €' },
    { min: '25 000 €', max: '29 999 €', commission: '1 750 €' },
    { min: '30 000 €', max: '34 999 €', commission: '2 000 €' },
    { min: '35 000 €', max: '39 999 €', commission: '2 200 €' },
    { min: '40 000 €', max: '44 999 €', commission: '2 700 €' },
    { min: '45 000 €', max: '49 999 €', commission: '2 900 €' },
    { min: '50 000 €', max: '59 999 €', commission: '3 200 €' },
    { min: '60 000 €', max: '69 999 €', commission: '3 700 €' },
    { min: '70 000 €', max: '79 999 €', commission: '4 200 €' },
    { min: '80 000 €', max: '89 999 €', commission: '4 700 €' },
    { min: '90 000 €', max: '99 999 €', commission: '5 200 €' },
    { min: '100 000 €', max: '124 999 €', commission: '7 500 €' },
    { min: '125 000 €', max: '149 999 €', commission: '10 000 €' },
    { min: '150 000 €', max: '200 000 €', commission: '20 000 €' },
  ];

  return (
    <Grid item xs={12} sm={4}>
      <Typography mb={2}>Légende des types d'offres</Typography>
      {Object.entries(offerDetails).map(([key, value]) => (
        <React.Fragment key={key}>
          <div onClick={(e) => handleClick(e, key)}>
            <Typography variant="h6" fontSize={16} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              {value.emoji} {value.title} <sup>💡</sup>
            </Typography>
          </div>
          <Popover
            id={id}
            open={openedPopoverId === key}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiPopover-paper': {
                maxWidth: '500px',
                border: '1px solid #ccc',
                backgroundColor: 'white',
                padding: '20px',
              },
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {value.title}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {value.description}
            </Typography>
            {key === '30-jours' && (
              <>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2, mt: 2, textAlign: 'center' }}>
                  Commission SKIPCAR (Mandat 30 jours vendu sous 30 jours maximum)
                </Typography>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: theme.palette.saffron.main }}>
                      <TableRow sx={{ backgroundColor: theme.palette.saffron.main }}>
                        <TableCell sx={{ color: 'black', fontWeight: '600' }}>Prix d'achat de</TableCell>
                        <TableCell sx={{ color: 'black', backgroundColor: 'black', fontWeight: '600' }}>À</TableCell>
                        <TableCell sx={{ color: 'black', backgroundColor: 'black', fontWeight: '600' }}>
                          Commission NET
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commissionData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.min}</TableCell>
                          <TableCell>{row.max}</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>{row.commission}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Popover>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default OfferLegends;
