import React from 'react';

import { Button, CircularProgress, LinearProgress, Typography } from '@mui/material';

interface MarketPlaceStatusProps {
  marketplaceDate: Date | undefined;
  minimumPrice: string;
  isLoading: boolean;
  dealId: number;
  handleDeleteFromMarketplace: () => void;
  timeElapsedPercentage?: number;
}

const MarketPlaceStatus: React.FC<MarketPlaceStatusProps> = ({
  marketplaceDate,
  minimumPrice,
  isLoading,
  dealId,
  handleDeleteFromMarketplace,
  timeElapsedPercentage,
}) => {
  return (
    <>
      <Typography align="center" sx={{ fontSize: 14, marginTop: 1 }} variant="body2">
        Demande de marketplace faite le {marketplaceDate?.toLocaleDateString()} au prix de{' '}
        <span style={{ fontWeight: '700' }}>{minimumPrice}</span>
      </Typography>
      <Button
        color="error"
        sx={{ display: 'flex', margin: 'auto' }}
        onClick={handleDeleteFromMarketplace}
        id={`cancel-marketplace-${dealId}`}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Retirer de la marketplace'}
      </Button>
      {timeElapsedPercentage !== undefined && (
        <LinearProgress variant="determinate" value={timeElapsedPercentage / 100} />
      )}
    </>
  );
};

export default MarketPlaceStatus;
