// packages/frontend/src/components/DealsList/DealStageActions/SubmitCTVO.tsx
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SaveIcon from '@mui/icons-material/Save';
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import { makeAuthenticatedApiCall } from '../../../apiHelper';
import { useDealMisivF } from '../../../hooks/useApiQueries';
import theme from '../../../theme';
import { formatUTCDateTime, getFormatFromMimetype, removeSpaces, updateDealStage } from '../../../utils';
import useButtonStepStore from '../../CGList/ButtonStepStore';
import FirebaseUploadComponent from '../../CreateCotation/FirebaseUploadComponent';
import { ErrorMessageSnackbar } from '../../ErrorMessageSnackbar';

interface SubmitCTVO {
  deal: any;
  pipelineLetter: string;
  stageId: number;
  userRole?: string;
  onRefresh?: (changeCurrentTab?: number) => void;
}

const SubmitCTVO: React.FC<SubmitCTVO> = ({ deal, pipelineLetter, stageId, userRole, onRefresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('');
  const [responseNomFic, setResponseNomFic] = useState<string>('');
  const [responseId, setResponseId] = useState<number>();
  const [errorText, setErrorText] = useState<string>('');
  const [tooltipError, setTooltipError] = useState<string>('');
  const [isTimeoutError, setIsTimeoutError] = useState(false);
  const [hasFirebaseUrlOnly, setHasFirebaseUrlOnly] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [responseErrorCode, setResponseErrorCode] = useState(null);
  const [purchaseDateTime, setPurchaseDateTime] = useState('');

  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);

  const [dealDataToSend, setDealDataToSend] = useState<any>(null);

  const env = window.location.hostname === 'app.skipcar.fr' ? 'production' : 'development';

  const isNumFormuleExists = !deal?.NumFormule;

  const { data: misivDealData } = useDealMisivF(deal.PipedriveDealID);

  const { buttonSteps, setButtonStep } = useButtonStepStore();

  const formMethods = useForm({
    mode: 'all', // Validation à chaque changement de champ
  });

  const handleDownloadPhotos = async () => {
    setDownloading(true);
    const zip = new JSZip();
    const folder = zip.folder(`Skipcar-CTVO-${deal.PipedriveDealID}`);

    // Parse the deal.AllFiles string into an array of URLs
    const urls = deal.AllFiles.split(',').map((url: string) => url.trim());

    const promises = urls.map(async (url: string, index: number) => {
      try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const fileName = `Skipcar-CTVO-${deal.PipedriveDealID}-${index}.${getFormatFromMimetype(response.headers['content-type'])}`;
        folder?.file(fileName, blob);
      } catch (error) {
        console.error('Error handling photo:', error);
      }
    });

    await Promise.all(promises);

    try {
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `Skipcar-CTVO-${deal.PipedriveDealID}.zip`);
    } catch (error) {
      console.error('Error generating ZIP:', error);
    } finally {
      setDownloading(false);
    }
  };

  const { handleSubmit, control, setValue, watch, formState, trigger } = formMethods;
  const { errors, isValid } = formState;

  const validationRules = {
    firstName: { required: 'Ce champ est requis' },
    lastName: { required: 'Ce champ est requis' },
    addressNumber: {
      validate: (value: string) => {
        const addressType = formMethods.watch('addressType');
        if (addressType !== 'ldit' && !value) {
          return 'Ce champ est requis';
        }
        return true;
      },
    },
    addressType: { required: 'Ce champ est requis' },
    addressName: { required: 'Ce champ est requis' },
    city: { required: 'Ce champ est requis' },
    postalCode: { required: 'Ce champ est requis' },
    country: { required: 'Ce champ est requis' },
  };

  const handleUploadComplete = (url: string) => {
    setUploadedUrls((prevUrls) => [...prevUrls, url]);
  };

  const handleDeleteComplete = (url: string) => {
    setUploadedUrls((prevUrls) => prevUrls.filter((existingUrl) => existingUrl !== url));
  };

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const BuyerCountryOfBirth = watch('BuyerCountryOfBirth');
  const CoBuyer = watch('CoBuyer');
  const CoBuyerType = watch('CoBuyerType');
  const SellerType = watch('SellerType');
  const BuyerType = watch('BuyerType');

  const isFrance = BuyerCountryOfBirth === 'FRANCE';

  useEffect(() => {
    if (BuyerCountryOfBirth === 'FRANCE') {
      trigger('buyerDepartmentOfBirth'); // Déclenchez la revalidation du champ si le pays est la France
    }
  }, [BuyerCountryOfBirth, trigger]);

  useEffect(() => {
    if (CoBuyer === 'yes') {
      trigger('CoBuyerType');
    }
  }, [CoBuyerType, CoBuyer, trigger]);

  useEffect(() => {
    const determineButtonState = () => {
      if (stageId === 46) {
        // F1
        return 'waiting-payment';
      }

      const data = misivDealData?.data.getDealMisivFromDB
        .filter((data: any) => data.receipt_file_name?.startsWith('CTVO-') || data.receipt_file_firebase_url)
        .sort((a: any, b: any) => b.id - a.id || b.response_id - a.response_id)[0];

      if (!data) {
        if (userRole === 'agency' && stageId === 48) {
          // F3
          return 'waiting-procedures';
        } else if (stageId === 47 && userRole === 'agency') {
          // F2
          return 'waiting-procedures';
        } else {
          return 'initial';
        }
      }

      const {
        receipt_file_firebase_url,
        receipt_file_name,
        response_id,
        has_timeout,
        purchase_date_time,
        last_update,
      } = data;

      if (
        ((receipt_file_name && receipt_file_name.startsWith('CTVO-')) ||
          (receipt_file_firebase_url && receipt_file_firebase_url.includes('/CTVO/'))) &&
        response_id &&
        has_timeout === 0
      ) {
        // Cas spécial : URL Firebase sans nom de fichier
        const hasFirebaseUrlOnly = receipt_file_firebase_url && !receipt_file_name;
        setHasFirebaseUrlOnly(hasFirebaseUrlOnly);
        setResponseNomFic(receipt_file_name);
        if (userRole === 'agency') {
          setButtonText(`✅ Télécharger récépissé CTVO `);
        } else {
          setButtonText(`✅ CTVO n°${response_id} ${userRole === 'god' && receipt_file_firebase_url ? '' : 'KO'}`);
        }
        setIsTimeoutError(false);

        if (userRole === 'agency' && stageId === 48) {
          // F3
          return 'receipt-only';
        } else {
          return 'receipt';
        }
      } else if (!receipt_file_name && has_timeout === 1) {
        setResponseId(response_id);
        setIsTimeoutError(true);
        setButtonText(`Renvoyer au ministère n°${response_id}`);
        return 'timeout-error';
      } else if (!receipt_file_name && has_timeout === 0) {
        setPurchaseDateTime(formatUTCDateTime(purchase_date_time));
        setIsTimeoutError(false);
        setButtonText(`⚠️ CTVO n°${response_id}`);
        return 'save';
      }

      return 'initial';
    };

    const newState = determineButtonState();
    setButtonStep(deal.PipedriveDealID, newState);
  }, [misivDealData, stageId]);

  useEffect(() => {
    if (deal?.AllFiles) {
      const files = [...(pipelineLetter === 'f' && deal?.AllFiles ? deal?.AllFiles.split(',') : [])];

      setUploadedUrls(files);
    }
  }, [deal, pipelineLetter]);

  useEffect(() => {
    if (deal?.PurchaseDateTime) {
      const formattedDate = formatUTCDateTime(deal.PurchaseDateTime);
      setPurchaseDateTime(formattedDate);
    }
  }, [deal]);

  const handleConfirmClick = () => {
    setIsConfirmModalOpen(true); // Ouvrir la modale de confirmation au lieu de soumettre directement
  };

  const handleFinalConfirm = async () => {
    const formData = formMethods.getValues(); // Récupérer les données du formulaire actuel
    const updatedDealWithFormDatas = { ...deal, ...formData };
    setDealDataToSend(updatedDealWithFormDatas);

    setIsConfirmModalOpen(false); // Fermer la modale de confirmation
    const handleSaveResponse = await handleSave(formData);
    if (handleSaveResponse) {
      await handleSaveCTVO(updatedDealWithFormDatas); // Appeler la fonction pour soumettre
      handleDialogClose(); // Fermer la modale principale
    }
  };

  const handleSave = async (formData: any /* FormDataPerson | FormDataPro */) => {
    try {
      const purchaseDateTime = formMethods.getValues('purchaseDateTime');
      const dealWithPurchaseDateTime = { ...formData, purchaseDateTime, type: 'CTVO' };

      const responseUpdate = await makeAuthenticatedApiCall('post', `/api/cg/update`, {
        dealDataToSend: dealWithPurchaseDateTime,
        PipedriveDealID: deal.PipedriveDealID,
      });
      // openSnackbar('Les modifications ont bien été prises en compte', 'success');
      return true;
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.message, 'error');
      return false;
    } finally {
      setResponseErrorCode(null);
    }
  };

  const handleSaveCTVO = async (dealDataToSend: any) => {
    setIsLoading(true);
    setDisabledButton(true);

    try {
      const responseSave = await makeAuthenticatedApiCall('post', `/api/misiv/save/ctvo`, dealDataToSend);
      const responseSubmitId = responseSave?.data.responseId;
      setButtonStep(deal.PipedriveDealID, 'save');
      setButtonText(`⚠️ CTVO n°${responseSubmitId}`);
      await handleSubmitCTVO(dealDataToSend);
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de la demande:', error);
      openSnackbar('Erreur lors de la sauvegarde de la demande. Veuillez ré-essayer.', 'error');
      setIsLoading(false);
      setDisabledButton(false);
    }
  };
  const handleSubmitCTVO = async (dealDataToSend: any) => {
    setIsLoading(true);
    setDisabledButton(true);

    try {
      const responseSubmit = await makeAuthenticatedApiCall('post', `/api/misiv/submit/ctvo`, dealDataToSend);

      const responseId = responseSubmit.data.responseId;
      const responseNomFic = responseSubmit.data.responseNomFic;
      setResponseNomFic(responseNomFic);

      // Gérer le cas où il n'y a pas d'erreur
      openSnackbar(`Votre demande a bien été envoyée : ${responseId}`, 'success');
      setButtonStep(deal.PipedriveDealID, 'receipt');
      if (userRole === 'agency') {
        setButtonText(`✅ Télécharger récépissé CTVO `);
      } else {
        if (env === 'production') {
          // Ici route pour envoyer le mail de confirmation
          const responseSendEmailToAgency = await makeAuthenticatedApiCall('post', `/api/mails/ctvo/agency`, {
            AgencyEmail: dealDataToSend.AgencyID === 343 ? 'administratif@biwiz.me' : dealDataToSend.AgencyEmail,
            RegPlate: dealDataToSend.RegPlate,
          });
          console.log('responseSendEmailToAgency', responseSendEmailToAgency);
        }
        setButtonText(`✅ CTVO n°${responseId}`);
      }
      /*
      const base64String = responseSubmit.data.askCessionReceipt;
      const blob = base64ToBlob(base64String, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      */
      const firebaseUrl = responseSubmit.data.firebaseReceipt.firebaseReceiptUrl;
      if (firebaseUrl) {
        // Si nous recevons une URL Firebase, ouvrez-la directement
        window.open(firebaseUrl);
        // Mise à jour du stage ID à 48 lorsque le récépissé est disponible
        if (env === 'production') {
          await updateDealStage(deal.PipedriveDealID, 48);
        }
        if (onRefresh) {
          onRefresh();
        }
      }
    } catch (error: any) {
      if (error.response && error.response.status === 500) {
        // Cas où le serveur a renvoyé une erreur 500 avec des données JSON
        const responseId = error.response.data?.responseId;
        const responseErrorCode = error.response.data?.responseErrorCode;
        const responseErrorMessage = error.response.data?.responseErrorMessage;

        if (responseErrorCode === 3 || responseErrorCode === 100) {
          // Ici on doit pouvoir re-cliquer et re-tenter l'envoi sans passer par la modale avec le form
          setResponseId(responseId);
          setIsTimeoutError(true);
          setButtonText(`Renvoyer au ministère n°${responseId}`);
          openSnackbar(`Timeout Ministère : Ré-essayer dans quelques instants`, 'error');
          return;
        } else {
          // Enregistrez le code d'erreur pour une utilisation ultérieure
          setResponseErrorCode(responseErrorCode);
        }

        if (responseErrorCode) {
          openSnackbar(`Erreur ${responseErrorCode} : ${responseErrorMessage}`, 'error');
          setErrorText(responseErrorMessage);
        } else {
          console.log(error);
          openSnackbar(error.response.data.error, 'error');
          setErrorText(error.response.data.error);
        }

        return;
      } else if (axios.isAxiosError(error)) {
        console.error('Erreur réseau ou serveur :', error.message);
        openSnackbar('Erreur réseau ou serveur : ' + error.message, 'error');
      } else {
        console.error("Erreur inattendue lors de l'envoi du certificat à TMS :", error);
        openSnackbar(`Erreur lors de l'envoi du certificat à TMS`, 'error');
      }
      setDisabledButton(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendToMinistry = async (responseSubmitId: number) => {
    setIsLoading(true);

    try {
      const responseOpen = await makeAuthenticatedApiCall('post', `/api/misiv/open/ctvo`, {
        responseSubmitId,
      });
      handleGetReceipt(responseOpen.data.jsonObj);
      setButtonStep(deal.PipedriveDealID, 'receipt');
      setButtonText(`✅ CTVO n°${responseSubmitId}`);
      setTooltipError('');
      setResponseNomFic(responseOpen.data.jsonObj);
    } catch (error) {
      console.error("Erreur lors de l'ouverture du certificat à TMS :", error);
      openSnackbar("Erreur lors de l'ouverture du certificat à TMS", 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetReceipt = async (responseNomFic: string) => {
    console.log('handleGetReceipt 1');
    setIsLoading(true);

    try {
      const responseReceipt = await makeAuthenticatedApiCall('post', `/api/misiv/receipt/ctvo`, {
        responseNomFic,
        pipelineLetter,
        dealId: deal.PipedriveDealID,
        typeDemarche: 'CTVO',
      });

      console.log('responseReceipt', responseReceipt);
      const firebaseUrl = responseReceipt.data.firebaseUrl;
      if (firebaseUrl) {
        console.log('handleGetReceipt / firebaseUrl existe');
        window.open(firebaseUrl);
        // Mise à jour du stage ID à 48 lorsque le récépissé est disponible
        if (env === 'production') {
          await updateDealStage(deal.PipedriveDealID, 48);
        }
        if (onRefresh) {
          onRefresh();
        }
      }
      setButtonStep(deal.PipedriveDealID, 'receipt');
      setIsTimeoutError(false);
    } catch (error: any) {
      console.log('handleGetReceipt 3');
      console.error('handleGetReceipt - Erreur lors de la génération du reçu :', error);

      const errorMessage = error.response?.data?.message || 'Erreur inconnue';
      openSnackbar(errorMessage, 'error');
      setErrorText(errorMessage);
    } finally {
      setIsLoading(false);
      console.log('handleGetReceipt 4');
    }
  };

  const handleButtonClick = async () => {
    console.log('handleButtonClick 1');
    if (buttonText.startsWith('⚠️ CTVO n°') && responseErrorCode === 3 && responseErrorCode === 100) {
      handleSubmitCTVO(dealDataToSend);
    } else {
      setIsDialogOpen(true);
    }
    setIsLoading(false); // Ajout de cette ligne pour réinitialiser isLoading
  };

  useEffect(() => {
    if (isDialogOpen) {
      trigger(); // Déclencher la validation de tous les champs
    }
  }, [isDialogOpen, trigger]);

  return (
    <>
      {buttonSteps[deal.PipedriveDealID] === 'waiting-payment' && !isTimeoutError && (
        <Button
          disabled
          sx={{
            fontSize: 14,
            background: '#FF6060',
            opacity: 0.7,
            color: 'white !important',
            fontWeight: 600,
            width: 234,
          }}
        >
          CTVO : En attente de paiement
        </Button>
      )}
      {buttonSteps[deal.PipedriveDealID] === 'waiting-procedures' && !isTimeoutError && (
        <Button
          disabled
          sx={{
            fontSize: 14,
            background: '#FF6060',
            opacity: 0.7,
            color: 'white !important',
            fontWeight: 600,
            width: 260,
          }}
        >
          CTVO : En attente des démarches
        </Button>
      )}
      {buttonSteps[deal.PipedriveDealID] === 'initial' && !isTimeoutError && (
        <div style={{ position: 'relative' }}>
          <Tooltip
            title={tooltipError ? tooltipError : isNumFormuleExists ? 'Numéro de formule absent' : ''}
            placement="top"
          >
            <div>
              <Button
                sx={{
                  fontSize: 14,
                  margin: '8px auto 0',
                  maxWidth: 160,
                  color: 'white',
                  backgroundColor: tooltipError ? 'red' : '', // Changer la couleur du bouton en rouge en cas d'erreur
                }}
                variant="contained"
                onClick={() => handleButtonClick()}
                disabled={disabledButton || !!tooltipError || isNumFormuleExists}
                fullWidth
              >
                {isLoading && <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} />}
                {disabledButton ? buttonText : '📩 Soumettre le CTVO'}
              </Button>
            </div>
          </Tooltip>
          {tooltipError && (
            <Typography
              color="error"
              sx={{
                fontSize: 12,
                marginTop: 1,
                textAlign: 'center',
              }}
            >
              {tooltipError}
            </Typography>
          )}
        </div>
      )}

      {buttonSteps[deal.PipedriveDealID] === 'save' && !isTimeoutError && (
        <Tooltip
          title={
            isLoading
              ? "Demande sauvegardée, maintenant en cours d'envoi"
              : "La demande n'a pas abouti. Cliquez pour réessayer."
          }
          placement="top"
        >
          <div>
            <Button
              sx={{
                fontSize: 14,
                margin: '8px auto 0',
                width: 180,
                backgroundColor: 'orange !important',
              }}
              variant="contained"
              onClick={responseErrorCode !== 3 && responseErrorCode !== 100 ? handleButtonClick : handleSubmitCTVO}
              fullWidth
            >
              {isLoading && <CircularProgress size={12} style={{ color: 'white' }} />}
              {buttonText}
            </Button>
            {errorText && (
              <Typography
                variant="body2"
                sx={{ fontSize: '10px', lineHeight: '1.25', marginTop: 1, fontWeight: 'bold', color: 'red !important' }}
              >
                {errorText}
              </Typography>
            )}
          </div>
        </Tooltip>
      )}

      {(buttonSteps[deal.PipedriveDealID] === 'receipt' || buttonSteps[deal.PipedriveDealID] === 'receipt-only') &&
        !isTimeoutError && (
          <>
            <Button
              sx={{
                fontSize: 14,
                margin: '8px auto 0',
                maxWidth: 160,
                backgroundColor: 'green',
                color: 'white',
              }}
              variant="contained"
              onClick={() => handleGetReceipt(responseNomFic)}
              fullWidth
            >
              {isLoading && <CircularProgress size={12} style={{ color: 'white' }} />}
              {buttonText}
            </Button>
            {hasFirebaseUrlOnly && userRole !== 'agency' && (
              <Tooltip title="Il y a eu un soucis au moment où la démarche a été envoyée à Misiv qui fait que nous n'avons pas le nom du fichier, mais nous avons quand même pu sauvegarder le récépissé sur Firebase">
                <ErrorOutlineIcon
                  sx={{
                    color: theme.palette.error.main,
                    fontSize: '16px',
                    ml: 0.5,
                    opacity: 0.8,
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                />
              </Tooltip>
            )}
          </>
        )}
      {isTimeoutError && responseId !== undefined && (
        <Tooltip title="Timeout Ministère : Ré-essayez dans quelques instants" placement="top">
          <div>
            <Button
              sx={{
                fontSize: 14,
                margin: '8px auto 0',
                width: 180,
                backgroundColor: 'red !important',
              }}
              variant="contained"
              onClick={() => handleResendToMinistry(responseId)}
              fullWidth
            >
              {isLoading && <CircularProgress size={12} style={{ color: 'white' }} />}
              {buttonText}
            </Button>
            {errorText && (
              <Typography
                variant="body2"
                sx={{ fontSize: '10px', lineHeight: '1.25', marginTop: 0, fontWeight: 'bold', color: 'red !important' }}
              >
                {errorText}
              </Typography>
            )}
          </div>
        </Tooltip>
      )}
      {/*errorText && (
          <Typography
            variant="body2"
            sx={{ fontSize: '10px', lineHeight: '1.25', marginTop: 1, fontWeight: 'bold', color: 'red' }}
          >
            {errorText}
          </Typography>
        )*/}

      <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="lg">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleSave)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                  <Controller
                    name="purchaseDateTime"
                    control={control}
                    defaultValue={purchaseDateTime} // Ensure a default value is set
                    render={({ field }) => (
                      <TextField
                        {...field} // Bind react-hook-form's field to the TextField
                        autoFocus
                        margin="dense"
                        id="purchaseDateTime"
                        label="Date et heure de la vente"
                        type="datetime-local"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <Typography variant="body2" textAlign="center">
                    Veuillez vérifier les informations suivantes :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="RegPlate"
                    control={control}
                    defaultValue={deal?.RegPlate}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        fullWidth
                        margin="dense"
                        id="RegPlate"
                        label="Plaque d'immatriculation"
                        type="text"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="VIN"
                    control={control}
                    defaultValue={deal?.VIN}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        fullWidth
                        margin="dense"
                        id="VIN"
                        label="VIN"
                        type="text"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="NumFormule"
                    control={control}
                    defaultValue={deal?.NumFormule}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        fullWidth
                        margin="dense"
                        id="NumFormule"
                        label="Numéro de Formule"
                        type="text"
                      />
                    )}
                  />
                </Grid>
                {deal?.SellerType && (
                  <>
                    <Grid container sx={{ marginTop: 2 }} alignItems="center" item xs={12} md={12}>
                      <Grid item>
                        <Typography variant="body1" component="div" sx={{ marginRight: 2 }}>
                          Vendeur
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Controller
                          name="SellerType"
                          control={control}
                          defaultValue={deal?.SellerType}
                          render={({ field }) => (
                            <RadioGroup {...field} row>
                              <FormControlLabel
                                className="inputElement"
                                value="person"
                                control={<Radio />}
                                label="Particulier"
                              />
                              <FormControlLabel
                                className="inputElement"
                                value="pro"
                                control={<Radio />}
                                label="Professionnel"
                              />
                            </RadioGroup>
                          )}
                        />
                      </Grid>
                    </Grid>
                    {SellerType === 'person' && (
                      <>
                        <Grid item xs={12} md={2}>
                          <Controller
                            name="SellerGender"
                            control={control}
                            defaultValue={deal?.SellerGender === 'mr' ? 'male' : 'female'}
                            rules={{}}
                            render={({ field }) => (
                              <Select
                                error={!!errors.gender}
                                sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                                {...field}
                                fullWidth
                                className="inputElement"
                                label="Sexe"
                              >
                                <MenuItem value="male">Masculin</MenuItem>
                                <MenuItem value="female">Féminin</MenuItem>
                              </Select>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Controller
                            name="SellerFirstName"
                            rules={validationRules.firstName}
                            control={control}
                            defaultValue={deal?.SellerFirstName}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="inputElement"
                                margin="dense"
                                id="SellerFirstName"
                                error={!!errors.firstName}
                                label="Prénom"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="SellerLastName"
                            rules={validationRules.firstName}
                            control={control}
                            defaultValue={deal?.SellerLastName}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="inputElement"
                                margin="dense"
                                id="SellerLastName"
                                error={!!errors.firstName}
                                label="Nom"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="SellerUsualName"
                            rules={{}}
                            control={control}
                            defaultValue={deal?.SellerUsualName}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="inputElement"
                                margin="dense"
                                id="SellerUsualName"
                                error={!!errors.firstName}
                                label="Nom d'usage"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {SellerType === 'pro' && (
                      <>
                        <Grid item xs={12} md={5}>
                          <Controller
                            name="SellerSIRET"
                            control={control}
                            rules={
                              deal?.SellerType === 'pro'
                                ? {
                                    required: 'Ce champ est requis',
                                    minLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                    maxLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                  }
                                : {
                                    minLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                    maxLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                  }
                            }
                            defaultValue={deal?.SellerSIRET}
                            render={({ field }) => (
                              <TextField
                                className="inputElement"
                                error={!!errors.SellerSIRET}
                                helperText={errors.SellerSIRET ? (errors.SellerSIRET.message as string) : ''}
                                {...field}
                                margin="dense"
                                id="SellerSIRET"
                                label="SIRET"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                                onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Controller
                            name="SellerCompanyName"
                            rules={deal?.SellerType === 'pro' ? { required: 'Ce champ est requis' } : {}}
                            control={control}
                            defaultValue={deal?.SellerCompanyName}
                            render={({ field }) => (
                              <TextField
                                className="inputElement"
                                {...field}
                                margin="dense"
                                error={!!errors.companyName}
                                id="SellerCompanyName"
                                label="Nom de l'entreprise"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid container sx={{ marginTop: 2 }} alignItems="center" item xs={12} md={12}>
                  <Grid item>
                    <Typography variant="body1" component="div" sx={{ marginRight: 2 }}>
                      Acheteur
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Controller
                      name="BuyerType"
                      control={control}
                      defaultValue={deal?.BuyerType}
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            className="inputElement"
                            value="person"
                            control={<Radio />}
                            label="Particulier"
                          />
                          <FormControlLabel
                            className="inputElement"
                            value="pro"
                            control={<Radio />}
                            label="Professionnel"
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                </Grid>
                {BuyerType === 'pro' && (
                  <>
                    <Grid item xs={12} md={5}>
                      <Controller
                        name="BuyerSIRET"
                        control={control}
                        rules={
                          deal?.BuyerType === 'pro'
                            ? {
                                required: 'Ce champ est requis',
                                minLength: {
                                  value: 14,
                                  message: 'Le SIRET doit contenir exactement 14 caractères',
                                },
                                maxLength: {
                                  value: 14,
                                  message: 'Le SIRET doit contenir exactement 14 caractères',
                                },
                              }
                            : {
                                minLength: {
                                  value: 14,
                                  message: 'Le SIRET doit contenir exactement 14 caractères',
                                },
                                maxLength: {
                                  value: 14,
                                  message: 'Le SIRET doit contenir exactement 14 caractères',
                                },
                              }
                        }
                        defaultValue={deal?.BuyerSIRET}
                        render={({ field }) => (
                          <TextField
                            className="inputElement"
                            error={!!errors.BuyerSIRET}
                            helperText={errors.BuyerSIRET ? (errors.BuyerSIRET.message as string) : ''}
                            {...field}
                            margin="dense"
                            id="BuyerSIRET"
                            label="SIRET"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                            onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        name="BuyerCompanyName"
                        rules={deal?.BuyerType === 'pro' ? { required: 'Ce champ est requis' } : {}}
                        control={control}
                        defaultValue={deal?.BuyerCompanyName}
                        render={({ field }) => (
                          <TextField
                            className="inputElement"
                            {...field}
                            margin="dense"
                            error={!!errors.companyName}
                            id="BuyerCompanyName"
                            label="Nom de l'entreprise"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {BuyerType === 'person' && (
                  <>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="BuyerFirstName"
                        rules={validationRules.firstName}
                        control={control}
                        defaultValue={deal?.BuyerFirstName}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="inputElement"
                            margin="dense"
                            id="BuyerFirstName"
                            error={!!errors.firstName}
                            label="Prénom"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="BuyerLastName"
                        rules={validationRules.lastName}
                        control={control}
                        defaultValue={deal?.BuyerLastName}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="BuyerLastName"
                            error={!!errors.lastName}
                            className="inputElement"
                            margin="dense"
                            label="Nom"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="BuyerUsualName"
                        rules={{}}
                        control={control}
                        defaultValue={deal?.BuyerUsualName}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="inputElement"
                            margin="dense"
                            id="BuyerUsualName"
                            error={!!errors.firstName}
                            label="Nom d'usage"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={2}>
                  <Controller
                    name="BuyerAddressNumber"
                    control={control}
                    rules={validationRules.addressNumber}
                    defaultValue={deal?.BuyerAddressNumber}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        error={!!errors.addressNumber}
                        margin="dense"
                        id="BuyerAddressNumber"
                        label="Numéro d'adresse"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="BuyerAddressExtension"
                    control={control}
                    defaultValue={deal?.BuyerAddressExtension ?? ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        margin="dense"
                        id="BuyerAddressExtension"
                        label="Extension d'adresse"
                        type="text"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        placeholder="Ex: BIS"
                        InputLabelProps={{ shrink: true }}
                        onInput={(e) => {
                          const input = e.target as HTMLInputElement;
                          input.value = input.value.replace(/\d/g, '');
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="BuyerAddressType"
                    control={control}
                    defaultValue={deal?.BuyerAddressType ?? ''}
                    rules={validationRules.addressType}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl fullWidth margin="dense" error={!!error}>
                        <InputLabel className="inputElement" id={`${field.name}-label`}>
                          Type d'adresse
                        </InputLabel>
                        <Select
                          className="inputElement"
                          {...field}
                          sx={{ minHeight: 'inherit', height: '56px', marginTop: '0px' }}
                          labelId={`${field.name}-label`}
                          id={field.name}
                          label="Type d'adresse"
                          value={field.value ? field.value.toLowerCase() : ''}
                        >
                          <MenuItem value="rue">Rue</MenuItem>
                          <MenuItem value="all">Allée</MenuItem>
                          <MenuItem value="avn">Avenue</MenuItem>
                          <MenuItem value="blvd">Boulevard</MenuItem>
                          <MenuItem value="chau">Chaussée</MenuItem>
                          <MenuItem value="chm">Chemin</MenuItem>
                          <MenuItem value="cour">Cour</MenuItem>
                          <MenuItem value="fbg">Faubourg</MenuItem>
                          <MenuItem value="fort">Fort</MenuItem>
                          <MenuItem value="imp">Impasse</MenuItem>
                          <MenuItem value="ldit">Lieu-Dit</MenuItem>
                          <MenuItem value="parc">Parc</MenuItem>
                          <MenuItem value="pass">Passage</MenuItem>
                          <MenuItem value="plc">Place</MenuItem>
                          <MenuItem value="quai">Quai</MenuItem>
                          <MenuItem value="rte">Route</MenuItem>
                          <MenuItem value="sent">Sentier</MenuItem>
                          <MenuItem value="squa">Square</MenuItem>
                          <MenuItem value="sans">Inconnu</MenuItem>
                        </Select>
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="BuyerAddressName"
                    control={control}
                    rules={validationRules.addressName}
                    defaultValue={deal?.BuyerAddressName}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.addressName}
                        className="inputElement"
                        margin="dense"
                        id="BuyerAddressName"
                        label="Nom de la voie"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="BuyerAddressPlace"
                    control={control}
                    rules={{}}
                    defaultValue={deal?.BuyerAddressPlace ?? ''}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ''}
                        className="inputElement"
                        margin="dense"
                        id="BuyerAddressPlace"
                        label="Lieu-dit"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="BuyerAddressStairs"
                    control={control}
                    rules={{}}
                    defaultValue={deal?.BuyerAddressStairs ?? ''}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ''}
                        className="inputElement"
                        margin="dense"
                        id="BuyerAddressStairs"
                        label="Étage/Escalier/Appartement"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="BuyerAddressComplement"
                    control={control}
                    rules={{}}
                    defaultValue={deal?.BuyerAddressComplement ?? ''}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ''}
                        className="inputElement"
                        margin="dense"
                        id="BuyerAddressComplement"
                        label="Complément d'adresse"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="BuyerAddressPostalBox"
                    control={control}
                    rules={{}}
                    defaultValue={deal?.BuyerAddressPostalBox ?? ''}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={!!error}
                        helperText={error ? error.message : ''}
                        className="inputElement"
                        margin="dense"
                        id="BuyerAddressPostalBox"
                        label="Boîte postale"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="BuyerCity"
                    control={control}
                    rules={validationRules.city}
                    defaultValue={deal?.BuyerCity}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.city}
                        className="inputElement"
                        margin="dense"
                        id="BuyerCity"
                        label="Ville"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="BuyerPostalCode"
                    control={control}
                    rules={validationRules.postalCode}
                    defaultValue={deal?.BuyerPostalCode}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.postalCode}
                        className="inputElement"
                        margin="dense"
                        id="BuyerPostalCode"
                        label="Code postal"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="BuyerCountry"
                    control={control}
                    rules={validationRules.country}
                    defaultValue={deal?.BuyerCountry}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        margin="dense"
                        id="BuyerCountry"
                        error={!!errors.country}
                        label="Pays"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3} marginTop="8px">
                  <Controller
                    name="BuyerPhone"
                    control={control}
                    defaultValue={deal?.BuyerPhone ?? ''}
                    rules={{
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Numéro de téléphone invalide',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <PhoneInput
                        {...field}
                        country={'fr'}
                        onlyCountries={['fr']}
                        placeholder="Téléphone portable"
                        inputProps={{
                          name: 'phone',
                          autoFocus: true,
                        }}
                        specialLabel=""
                        containerStyle={{ width: '100%' }}
                        inputStyle={{
                          width: '100%',
                          fontFamily: 'Urbanist',
                          fontWeight: 500,
                          color: 'black',
                          height: '56px',
                          borderColor: error ? 'red' : '',
                        }}
                        buttonStyle={{ height: '56px' }}
                      />
                    )}
                  />
                </Grid>
                {deal?.BuyerType === 'person' && (
                  <>
                    <Grid item xs={12} md={2}>
                      <Controller
                        name="BuyerGender"
                        control={control}
                        defaultValue={deal?.BuyerGender === 'mr' ? 'male' : 'female'}
                        rules={deal?.BuyerType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        render={({ field }) => (
                          <Select
                            error={!!errors.gender}
                            {...field}
                            margin="dense"
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                            fullWidth
                            className="inputElement"
                            label="Sexe"
                          >
                            <MenuItem value="male">Masculin</MenuItem>
                            <MenuItem value="female">Féminin</MenuItem>
                          </Select>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Controller
                        name="BuyerDateOfBirth"
                        control={control}
                        rules={deal?.BuyerType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        defaultValue={deal?.BuyerDateOfBirth && deal?.BuyerDateOfBirth.substring(0, 10)}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="BuyerDateOfBirth"
                            className="inputElement"
                            error={!!errors.dateOfBirth}
                            label="Date de naissance"
                            type="date"
                            fullWidth
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="BuyerPlaceOfBirth"
                        rules={deal?.BuyerType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        control={control}
                        defaultValue={deal?.BuyerPlaceOfBirth}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="inputElement"
                            error={!!errors.placeOfBirth}
                            margin="dense"
                            id="BuyerPlaceOfBirth"
                            label="Lieu de naissance"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="BuyerCountryOfBirth"
                        rules={deal?.BuyerType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        control={control}
                        defaultValue={deal?.BuyerCountryOfBirth}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="inputElement"
                            error={!!errors.placeOfBirth}
                            margin="dense"
                            id="BuyerCountryOfBirth"
                            label="Pays de naissance"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Controller
                        name="BuyerDepartmentOfBirth"
                        rules={
                          deal?.BuyerType === 'person'
                            ? {
                                validate: {
                                  required: (value) =>
                                    isFrance
                                      ? value.trim() === ''
                                        ? 'Ce champ est requis pour la France'
                                        : true
                                      : true,
                                },
                              }
                            : {}
                        }
                        control={control}
                        defaultValue={deal?.BuyerDepartmentOfBirth}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="inputElement"
                            error={!!errors.placeOfBirth}
                            margin="dense"
                            id="BuyerDepartmentOfBirth"
                            label="Département de naissance"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 3 }}
                            disabled={!isFrance}
                            InputLabelProps={{
                              style: {
                                fontWeight: 'bold', // Mettre le label en gras
                                fontSize: '1rem', // Réduire la taille de la police si nécessaire
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {/*
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="documentType"
                        control={control}
                        defaultValue={deal?.BuyerDocumentType}
                        rules={deal?.BuyerType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        render={({ field }) => (
                          <Select
                            error={!!errors.documentType}
                            {...field}
                            fullWidth
                            margin="dense"
                            className="inputElement"
                          >
                            <MenuItem value="id_card">Carte d'identité</MenuItem>
                            <MenuItem value="passport">Passeport</MenuItem>
                            <MenuItem value="driving_licence">Permis de conduire</MenuItem>
                            <MenuItem value="residence_permit">Titre de séjour</MenuItem>
                          </Select>
                        )}
                      />
                    </Grid>
                      */}
                  </>
                )}
                <Grid container sx={{ marginTop: 2 }} alignItems="center" item xs={12} md={12}>
                  <Grid item>
                    <Typography variant="body1" component="div" sx={{ marginRight: 2 }}>
                      Il y a un co-titulaire :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Controller
                      name="CoBuyer"
                      control={control}
                      defaultValue={deal?.CoBuyerType ? 'yes' : 'no'}
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel className="inputElement" value="no" control={<Radio />} label="Non" />
                          <FormControlLabel className="inputElement" value="yes" control={<Radio />} label="Oui" />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                </Grid>
                {CoBuyer === 'yes' && (
                  <>
                    <Grid container sx={{ marginTop: 2 }} alignItems="center" item xs={12} md={12}>
                      <Grid item>
                        <Typography variant="h6" sx={{ marginRight: 2 }}>
                          Le co-titulaire est un :
                        </Typography>
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <Controller
                          name="CoBuyerType"
                          control={control}
                          defaultValue="person"
                          render={({ field }) => (
                            <RadioGroup {...field} row>
                              <FormControlLabel
                                className="inputElement"
                                value="person"
                                control={<Radio />}
                                label="Particulier"
                              />
                              <FormControlLabel
                                className="inputElement"
                                value="pro"
                                control={<Radio />}
                                label="Professionnel"
                              />
                            </RadioGroup>
                          )}
                        />
                      </Grid>
                    </Grid>
                    {CoBuyerType === 'person' && (
                      <>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="CoBuyerGender"
                            control={control}
                            defaultValue={deal?.CoBuyerGender === 'mr' ? 'male' : 'female'}
                            rules={{}}
                            render={({ field }) => (
                              <Select
                                error={!!errors.gender}
                                sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                                {...field}
                                margin="dense"
                                fullWidth
                                className="inputElement"
                                label="Sexe"
                              >
                                <MenuItem value="male">Masculin</MenuItem>
                                <MenuItem value="female">Féminin</MenuItem>
                              </Select>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="CoBuyerFirstName"
                            rules={validationRules.firstName}
                            control={control}
                            defaultValue={deal?.CoBuyerFirstName}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="inputElement"
                                margin="dense"
                                id="CoBuyerFirstName"
                                error={!!errors.firstName}
                                label="Prénom"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="CoBuyerLastName"
                            rules={validationRules.firstName}
                            control={control}
                            defaultValue={deal?.CoBuyerLastName}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="inputElement"
                                margin="dense"
                                id="CoBuyerLastName"
                                error={!!errors.firstName}
                                label="Nom"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="CoBuyerUsualName"
                            rules={{}}
                            control={control}
                            defaultValue={deal?.CoBuyerUsualName}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                className="inputElement"
                                margin="dense"
                                id="CoBuyerUsualName"
                                error={!!errors.firstName}
                                label="Nom d'usage"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {CoBuyerType === 'pro' && (
                      <>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name="CoBuyerSIRET"
                            control={control}
                            rules={
                              deal?.CoBuyerType === 'pro'
                                ? {
                                    required: 'Ce champ est requis',
                                    minLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                    maxLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                  }
                                : {
                                    minLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                    maxLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                  }
                            }
                            defaultValue={deal?.CoBuyerSIRET}
                            render={({ field }) => (
                              <TextField
                                className="inputElement"
                                error={!!errors.CoBuyerSIRET}
                                {...field}
                                margin="dense"
                                id="CoBuyerSIRET"
                                label="SIRET"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                                onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Controller
                            name="CoBuyerCompanyName"
                            rules={deal?.CoBuyerType === 'pro' ? { required: 'Ce champ est requis' } : {}}
                            control={control}
                            defaultValue={deal?.CoBuyerCompanyName}
                            render={({ field }) => (
                              <TextField
                                className="inputElement"
                                {...field}
                                margin="dense"
                                error={!!errors.companyName}
                                id="CoBuyerCompanyName"
                                label="Nom de l'entreprise"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid item xs={12} md={12}>
                  <Typography variant="body2">
                    {deal?.BuyerType === 'pro'
                      ? 'KBIS'
                      : "Justificatif de domicile, Passeport ou Carte d'identité (Recto-Verso)"}
                  </Typography>
                  <Button
                    onClick={handleDownloadPhotos}
                    style={{
                      position: 'relative',
                      float: 'right',
                      right: '0px',
                      top: '0px',
                      backgroundColor: theme.palette.saffron.main,
                      zIndex: 1000,
                      cursor: 'pointer',
                    }}
                  >
                    {downloading ? (
                      <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} />
                    ) : (
                      <SaveIcon color="action" />
                    )}
                  </Button>
                  <FirebaseUploadComponent
                    onDeleteComplete={handleDeleteComplete}
                    onUploadComplete={handleUploadComplete}
                    uploadedUrls={uploadedUrls}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Annuler</Button>
              <Button
                sx={{
                  fontWeight: 600,
                  backgroundColor: theme.palette.saffron.main,
                  '&:hover': { backgroundColor: theme.palette.saffron.main },
                }}
                variant="contained"
                onClick={handleConfirmClick}
                disabled={!isValid || !purchaseDateTime || isLoading}
              >
                {isLoading && <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} />}Soumettre
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      {/* Modale de confirmation */}
      <Dialog open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
        <DialogContent>
          <Typography>Êtes-vous sûr que toutes les informations sont correctes ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmModalOpen(false)}>Annuler</Button>
          <Button
            sx={{
              fontWeight: 600,
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            variant="contained"
            onClick={handleFinalConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
    </>
  );
};
export default SubmitCTVO;
