import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import { useArbitratorDeals, useDatabaseDeals, useMarketplaceDeals } from '../hooks/useApiQueries';
import theme from '../theme';
import { DBDeal } from '../types/deal';
import { Role } from '../types/user';
import { useAuth } from './AuthContext';
import { isInMarketPlace } from './DealsList/isInMarketPlace';
import MarketplaceDeal from './MarketplaceDealsList/MarketplaceDeal';

const MarketplaceDealsList: React.FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDeals, setFilteredDeals] = useState<any[]>([]);

  const { currentUser } = useAuth();
  const userRole: Role = currentUser?.data?.data?.role;
  const { data: response, isLoading, error } = useMarketplaceDeals();
  const {
    refetch: refetchArbitratorDeals,
    isLoading: isArbitratorDealsLoading,
    error: arbitratorDealsError,
  } = useArbitratorDeals();
  const {
    refetch: refetchUseDatabaseDeals,
    isLoading: isDatabaseDealsLoading,
    error: databaseDealsError,
  } = useDatabaseDeals();

  const handleRefresh = async () => {
    try {
      await refetchUseDatabaseDeals();
      await refetchArbitratorDeals();
    } catch (error) {
      console.error(error);
    }
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(0); // Reset page when changing tabs
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    setPage(0); // Reset page when searching
  };

  useEffect(() => {
    if (response?.data) {
      const deals = response.data.filter((deal) => (tabValue === 0 ? !deal.deleted_at : deal.deleted_at));

      if (searchTerm.length >= 3) {
        const filtered = deals.filter((deal) => {
          const searchLower = searchTerm.toLowerCase().trim();
          const brand = deal.brand?.toLowerCase() || '';
          const model = deal.model?.toLowerCase() || '';
          const immat = deal.immat?.toLowerCase() || '';

          return brand.includes(searchLower) || model.includes(searchLower) || immat.includes(searchLower);
        });
        setFilteredDeals(filtered);
      } else {
        setFilteredDeals(deals);
      }
    }
  }, [response?.data, searchTerm, tabValue]);

  if (isLoading) return <CircularProgress />;
  if (error) return <div>Erreur lors du chargement des données</div>;
  if (!response?.data) return null;

  const activeDeals = response.data.filter((deal) => !deal.deleted_at);
  const deletedDeals = response.data.filter((deal) => deal.deleted_at);

  // Pagination
  const currentDeals = filteredDeals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Container maxWidth={false}>
        <Paper sx={{ marginTop: 5, padding: 5 }} elevation={3}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2">✅ Nombre de véhicules sur la Marketplace : {activeDeals.length}</Typography>
              <Typography variant="body2">❌ Nombre de véhicules retirés : {deletedDeals.length}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                className="inputElement"
                label="Rechercher par marque, modèle ou plaque"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ marginBottom: 2 }}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={{ marginTop: '0', padding: 2 }} elevation={3}>
          <Tabs sx={{ marginTop: '0' }} value={tabValue} onChange={handleTabChange} centered className="DealsListTabs">
            <Tab label="Présents sur la Marketplace" />
            <Tab label="Retirés de la Marketplace" />
          </Tabs>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ background: '#262626' }}>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white', minWidth: 90 }}>Immat</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Marque / Modèle</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Kilométrage</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>MEC</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Prix</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white', textAlign: 'center' }}>Photos</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Date d'ajout</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: 'white', maxWidth: 200, textAlign: 'center' }}>
                    Action / Informations
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentDeals.map((deal, index) => (
                  <MarketplaceDeal key={deal.id_vehicle} row={deal} index={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredDeals.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage="Lignes par page"
          />
        </Paper>
      </Container>
    </>
  );
};

export default MarketplaceDealsList;
