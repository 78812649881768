import { useEffect, useState } from 'react';

import useFromExternalStore from '../../stores/FromExternalStore';

export const JantesSvg = () => {
  const isFromExternal = useFromExternalStore((state) => state.fromExternal);
  const [hasColorClass, setHasColorClass] = useState(false);

  useEffect(() => {
    // Fonction pour vérifier si le parent a une classe de couleur
    const checkColorClass = () => {
      const parent = document.querySelector('#grele')?.closest('.svg_block');
      const hasColor =
        parent?.classList.contains('yellow') ||
        parent?.classList.contains('orange') ||
        parent?.classList.contains('red') ||
        parent?.classList.contains('blue') ||
        false;
      setHasColorClass(hasColor);
    };

    // Observer les changements de classe sur le parent
    const observer = new MutationObserver(checkColorClass);
    const parent = document.querySelector('#grele')?.closest('.svg_block');

    if (parent) {
      observer.observe(parent, {
        attributes: true,
        attributeFilter: ['class'],
      });
    }

    // Vérification initiale
    checkColorClass();

    // Cleanup
    return () => observer.disconnect();
  }, []);

  return (
    <div className="svg_container form-cotation">
      <div className="svg_container_title">
        <div className="svg_block formH">
          <svg id="jante_avg" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
            <path d="M87.0101 174C38.6401 173.38 0.0700959 135.46 9.58742e-05 87.0002C-0.0699041 38.8702 38.2001 0.0901585 86.8601 0.000158537C134.96 -0.0898415 173.78 38.1502 173.86 86.8502C173.93 135.03 135.64 173.3 87.0101 174ZM167.8 86.8102C168.2 42.5702 131.23 5.92016 86.8801 5.95016C42.5601 5.98016 5.9601 42.6802 5.9501 86.9002C5.9401 131.21 42.6701 167.99 87.1201 167.9C131.26 167.81 168.33 131.22 167.8 86.8002V86.8102Z" />
            <path d="M138.22 91.8901C142.84 91.8901 147.45 91.8501 152.07 91.9001C155.99 91.9501 157.01 93.1601 156.48 97.0001C154.85 108.77 150.39 119.38 143.32 128.9C140.91 132.15 139.5 132.24 136.66 129.42C129.93 122.71 123.22 115.96 116.49 109.25C113.49 106.25 112.27 102.78 113.72 98.6701C115.16 94.6001 118.18 92.2701 122.44 92.0901C127.69 91.8701 132.96 92.0401 138.21 92.0401C138.21 91.9901 138.21 91.9401 138.21 91.8901H138.22ZM135.51 98.0901C131.63 98.0901 127.75 98.0901 123.88 98.0901C122.04 98.0901 120.28 98.3101 119.51 100.29C118.75 102.22 119.36 103.86 120.81 105.31C126.5 110.98 132.21 116.63 137.82 122.38C139.23 123.83 139.94 123.53 140.93 122C145.13 115.49 148.02 108.45 149.78 100.92C150.27 98.8301 149.53 98.0301 147.42 98.0701C143.45 98.1501 139.48 98.0901 135.51 98.0901Z" />
            <path d="M91.8999 138C91.8999 133.29 91.7699 128.58 91.9299 123.88C92.1799 116.52 97.5999 111.92 104.04 113.39C105.89 113.81 107.47 114.73 108.8 116.06C115.79 123.03 122.8 130 129.76 137C132.11 139.36 132.03 140.93 129.39 142.93C119.47 150.45 108.33 155.04 95.9799 156.64C93.3699 156.98 91.9899 155.68 91.9299 152.68C91.8299 147.79 91.8999 142.9 91.8999 138ZM98.0899 135.27C98.0899 139.43 98.1199 143.58 98.0799 147.73C98.0599 149.61 98.8999 150.22 100.67 149.82C108.4 148.07 115.61 145.11 122.25 140.74C123.59 139.86 123.76 139.18 122.55 137.99C116.69 132.23 110.9 126.4 105.08 120.59C103.7 119.21 102.06 118.8 100.26 119.51C98.4299 120.24 98.0899 121.86 98.0999 123.62C98.0999 127.5 98.0999 131.38 98.0999 135.25L98.0899 135.27Z" />
            <path d="M137.94 81.9602C133.23 81.9602 128.52 82.1002 123.82 81.9302C116.53 81.6702 111.9 76.1602 113.4 69.7602C113.86 67.8102 114.9 66.2102 116.3 64.8102C123.15 57.9602 129.99 51.0902 136.86 44.2502C139.44 41.6802 140.94 41.7802 143.15 44.7402C150.29 54.3202 154.85 64.9802 156.48 76.8502C157.01 80.7002 155.99 81.9102 152.07 81.9502C147.36 82.0102 142.66 81.9602 137.95 81.9602H137.94ZM135.25 75.7702C139.4 75.7702 143.56 75.7402 147.71 75.7802C149.58 75.8002 150.23 74.9902 149.83 73.2102C148.09 65.4802 145.13 58.2702 140.76 51.6302C139.89 50.3102 139.22 50.0802 138.01 51.3102C132.25 57.1702 126.42 62.9602 120.61 68.7702C119.23 70.1502 118.8 71.7802 119.51 73.5902C120.23 75.4202 121.84 75.7802 123.61 75.7702C127.49 75.7702 131.37 75.7702 135.24 75.7702H135.25Z" />
            <path d="M81.9703 137.98C81.9703 142.69 82.0203 147.4 81.9603 152.1C81.9103 155.98 80.6803 157 76.8203 156.46C65.0503 154.84 54.4603 150.32 44.9403 143.28C41.7103 140.89 41.6303 139.48 44.4803 136.62C51.0603 130.02 57.7003 123.47 64.2503 116.83C67.4503 113.59 71.0603 112.11 75.5003 113.82C80.0403 115.58 81.8603 119.23 81.9603 123.84C82.0603 128.55 81.9803 133.25 81.9803 137.96H81.9903L81.9703 137.98ZM75.7803 135.65C75.7803 131.86 75.7803 128.08 75.7803 124.29C75.7803 122.29 75.7203 120.28 73.4503 119.45C71.3603 118.69 69.7403 119.59 68.2503 121.09C62.7303 126.67 57.2003 132.24 51.5803 137.71C50.0403 139.21 50.3303 139.95 51.9703 141C58.4203 145.12 65.3703 147.98 72.8003 149.74C75.0403 150.27 75.8503 149.52 75.7903 147.27C75.7003 143.39 75.7603 139.52 75.7703 135.64L75.7803 135.65Z" />
            <path d="M81.9603 35.8602C81.9603 40.5702 82.0903 45.2802 81.9303 49.9802C81.6803 57.2902 76.1903 61.9502 69.8103 60.4702C67.9603 60.0402 66.3903 59.1302 65.0503 57.7902C58.0603 50.8102 51.0603 43.8402 44.1003 36.8302C41.7503 34.4702 41.8203 32.9202 44.4803 30.9102C54.4003 23.4102 65.5303 18.7802 77.8903 17.2002C80.5003 16.8702 81.8703 18.1702 81.9303 21.1702C82.0303 26.0602 81.9603 30.9502 81.9603 35.8502V35.8602ZM75.7803 38.5502C75.7803 34.4902 75.7203 30.4202 75.8003 26.3602C75.8403 24.1502 74.8303 23.5702 72.8703 24.0502C65.3703 25.8902 58.2903 28.7202 51.8103 32.9702C50.4903 33.8302 49.9203 34.4802 51.3503 35.8802C57.1603 41.5602 62.8803 47.3302 68.6103 53.0902C70.0603 54.5502 71.7203 55.1002 73.6403 54.3102C75.4703 53.5602 75.7703 51.9302 75.7703 50.1702C75.7703 46.2902 75.7703 42.4102 75.7703 38.5402L75.7803 38.5502Z" />
            <path d="M91.8901 35.8901C91.8901 31.1801 91.8401 26.4701 91.9001 21.7701C91.9501 17.8801 93.1701 16.8601 97.0401 17.3901C108.81 19.0101 119.4 23.5101 128.93 30.5701C132.16 32.9601 132.25 34.3801 129.41 37.2301C122.7 43.9601 115.95 50.6701 109.24 57.3901C106.24 60.4001 102.75 61.6101 98.6601 60.1301C94.6201 58.6701 92.2801 55.6501 92.1001 51.3901C91.8801 46.2301 92.0501 41.0601 92.0501 35.8901C92.0001 35.8901 91.9601 35.8901 91.9101 35.8901H91.8901ZM98.1001 38.4301C98.1001 42.4001 98.1001 46.3701 98.1001 50.3401C98.1001 52.1201 98.5301 53.7001 100.37 54.3801C102.07 55.0001 103.65 54.6601 104.96 53.3601C110.85 47.4901 116.72 41.6101 122.63 35.7701C123.71 34.7001 123.62 34.0301 122.38 33.2001C115.77 28.7801 108.53 25.8801 100.84 24.0201C99.0001 23.5801 98.0501 24.2001 98.0801 26.2501C98.1501 30.3101 98.1001 34.3801 98.1001 38.4401V38.4301Z" />
            <path d="M35.6799 81.9702C31.0599 81.9702 26.4499 82.0202 21.8299 81.9602C17.8899 81.9102 16.8599 80.7102 17.3799 76.8902C18.9699 65.1102 23.4899 54.5202 30.5199 44.9902C32.9899 41.6502 34.3599 41.6002 37.3699 44.6002C43.8999 51.1202 50.3899 57.6902 56.9599 64.1702C60.1999 67.3602 61.7299 70.9602 60.0499 75.4002C58.2799 80.0702 54.5199 81.8702 49.7999 81.9402C45.0899 82.0202 40.3899 81.9602 35.6799 81.9602V81.9702ZM38.4499 75.7702C42.4199 75.7702 46.3899 75.7702 50.3599 75.7702C52.0199 75.7702 53.5499 75.4102 54.2699 73.7302C55.0399 71.9502 54.7199 70.2802 53.3399 68.8902C47.4799 63.0002 41.5899 57.1302 35.7499 51.2202C34.6499 50.1102 33.9899 50.2902 33.1799 51.5102C28.7599 58.1202 25.8599 65.3602 24.0099 73.0602C23.5599 74.9202 24.2299 75.8402 26.2599 75.8002C30.3199 75.7302 34.3899 75.7802 38.4499 75.7802V75.7702Z" />
            <path d="M60.7802 101.96C60.6202 104.43 59.8702 106.69 58.1002 108.47C50.9502 115.68 43.7902 122.88 36.5802 130.02C34.5002 132.08 32.8902 131.93 31.0402 129.51C23.4202 119.54 18.8002 108.31 17.2102 95.8701C16.8802 93.3101 18.2502 91.9701 21.3002 91.9201C26.8402 91.8301 32.3802 91.8801 37.9102 91.8801C41.9702 91.8801 46.0402 91.7701 50.0902 91.9301C56.4302 92.1801 60.8502 96.4101 60.7802 101.96ZM38.5402 98.0901C34.4802 98.0901 30.4102 98.1301 26.3502 98.0701C24.4102 98.0401 23.5402 98.7901 24.0102 100.73C25.8402 108.43 28.7502 115.67 33.1402 122.29C34.0602 123.68 34.7602 123.67 35.9002 122.51C41.6002 116.72 47.3602 110.98 53.1102 105.24C54.5602 103.79 55.1102 102.12 54.3202 100.2C53.5702 98.3701 51.9302 98.0801 50.1802 98.0801C46.3002 98.0801 42.4202 98.0801 38.5502 98.0801L38.5402 98.0901Z" />
            <path d="M86.9399 101.98C78.8999 101.99 71.8899 94.9901 71.8799 86.9401C71.8699 78.8901 78.8699 71.8901 86.9199 71.8801C94.9699 71.8701 101.97 78.8601 101.99 86.9201C102 94.9701 95.0099 101.97 86.9499 101.98H86.9399ZM95.7999 87.0301C95.8399 81.8101 92.2099 78.1001 87.0199 78.0601C81.7999 78.0201 78.0899 81.6501 78.0499 86.8401C78.0099 92.0601 81.6399 95.7701 86.8299 95.8101C92.0499 95.8501 95.7599 92.2201 95.7999 87.0301Z" />
            <path d="M74.0301 71.1301C73.8101 72.8601 72.8901 73.9701 71.2001 73.9701C69.2701 73.9701 68.1101 72.7501 68.0901 70.8601C68.0701 69.1801 69.1801 68.0701 70.8601 68.0801C72.7601 68.0801 73.8101 69.2901 74.0401 71.1301H74.0301Z" />
            <path d="M102.67 74.0302C100.93 73.7802 99.84 72.8102 99.88 71.1402C99.93 69.2302 101.16 68.0702 103.06 68.1002C104.74 68.1202 105.82 69.2502 105.77 70.9302C105.71 72.8202 104.51 73.8602 102.66 74.0302H102.67Z" />
            <path d="M70.9199 105.85C69.2399 105.61 68.0799 104.72 68.0899 103.03C68.0899 101.14 69.2499 99.9102 71.1699 99.8802C72.8499 99.8602 73.9499 100.94 73.9799 102.63C74.0099 104.58 72.7399 105.53 70.9299 105.85H70.9199Z" />
            <path d="M102.77 99.8202C104.6 100.09 105.8 101.15 105.77 103.06C105.74 104.74 104.62 105.83 102.94 105.77C101.06 105.7 99.8501 104.54 99.8901 102.6C99.9301 100.9 101.06 100.02 102.78 99.8202H102.77Z" />
            <path d="M87.0203 61.3801C88.8203 61.7301 89.8903 62.7701 89.7803 64.6201C89.6703 66.4501 88.5503 67.5801 86.7003 67.4401C85.0103 67.3101 84.0503 66.1301 84.0803 64.4201C84.1203 62.5801 85.2203 61.5901 87.0303 61.3801H87.0203Z" />
            <path d="M67.5102 86.9501C67.2202 88.7601 66.2002 89.8501 64.3502 89.7801C62.5202 89.7101 61.3602 88.6201 61.4502 86.7701C61.5302 85.0801 62.7002 84.0901 64.4002 84.0801C66.2502 84.0701 67.2602 85.1401 67.5102 86.9501Z" />
            <path d="M112.48 86.8202C112.28 88.6302 111.3 89.7502 109.46 89.7802C107.63 89.8002 106.43 88.7602 106.42 86.9102C106.42 85.2002 107.53 84.1802 109.23 84.0802C111.08 83.9802 112.12 85.0202 112.48 86.8202Z" />
            <path d="M86.8799 112.48C85.0699 112.16 83.9899 111.13 84.0799 109.28C84.1599 107.45 85.2699 106.3 87.1199 106.41C88.8099 106.51 89.7899 107.69 89.7899 109.39C89.7899 111.23 88.6899 112.23 86.8899 112.47L86.8799 112.48Z" />
          </svg>
        </div>
        <div className="svg_title">Jante AVG</div>
      </div>
      <div className="svg_container_title">
        <div className="svg_block formH">
          <svg id="jante_avd" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
            <path d="M87.0101 174C38.6401 173.38 0.0700959 135.46 9.58742e-05 87.0002C-0.0699041 38.8702 38.2001 0.0901585 86.8601 0.000158537C134.96 -0.0898415 173.78 38.1502 173.86 86.8502C173.93 135.03 135.64 173.3 87.0101 174ZM167.8 86.8102C168.2 42.5702 131.23 5.92016 86.8801 5.95016C42.5601 5.98016 5.9601 42.6802 5.9501 86.9002C5.9401 131.21 42.6701 167.99 87.1201 167.9C131.26 167.81 168.33 131.22 167.8 86.8002V86.8102Z" />
            <path d="M138.22 91.8901C142.84 91.8901 147.45 91.8501 152.07 91.9001C155.99 91.9501 157.01 93.1601 156.48 97.0001C154.85 108.77 150.39 119.38 143.32 128.9C140.91 132.15 139.5 132.24 136.66 129.42C129.93 122.71 123.22 115.96 116.49 109.25C113.49 106.25 112.27 102.78 113.72 98.6701C115.16 94.6001 118.18 92.2701 122.44 92.0901C127.69 91.8701 132.96 92.0401 138.21 92.0401C138.21 91.9901 138.21 91.9401 138.21 91.8901H138.22ZM135.51 98.0901C131.63 98.0901 127.75 98.0901 123.88 98.0901C122.04 98.0901 120.28 98.3101 119.51 100.29C118.75 102.22 119.36 103.86 120.81 105.31C126.5 110.98 132.21 116.63 137.82 122.38C139.23 123.83 139.94 123.53 140.93 122C145.13 115.49 148.02 108.45 149.78 100.92C150.27 98.8301 149.53 98.0301 147.42 98.0701C143.45 98.1501 139.48 98.0901 135.51 98.0901Z" />
            <path d="M91.8999 138C91.8999 133.29 91.7699 128.58 91.9299 123.88C92.1799 116.52 97.5999 111.92 104.04 113.39C105.89 113.81 107.47 114.73 108.8 116.06C115.79 123.03 122.8 130 129.76 137C132.11 139.36 132.03 140.93 129.39 142.93C119.47 150.45 108.33 155.04 95.9799 156.64C93.3699 156.98 91.9899 155.68 91.9299 152.68C91.8299 147.79 91.8999 142.9 91.8999 138ZM98.0899 135.27C98.0899 139.43 98.1199 143.58 98.0799 147.73C98.0599 149.61 98.8999 150.22 100.67 149.82C108.4 148.07 115.61 145.11 122.25 140.74C123.59 139.86 123.76 139.18 122.55 137.99C116.69 132.23 110.9 126.4 105.08 120.59C103.7 119.21 102.06 118.8 100.26 119.51C98.4299 120.24 98.0899 121.86 98.0999 123.62C98.0999 127.5 98.0999 131.38 98.0999 135.25L98.0899 135.27Z" />
            <path d="M137.94 81.9602C133.23 81.9602 128.52 82.1002 123.82 81.9302C116.53 81.6702 111.9 76.1602 113.4 69.7602C113.86 67.8102 114.9 66.2102 116.3 64.8102C123.15 57.9602 129.99 51.0902 136.86 44.2502C139.44 41.6802 140.94 41.7802 143.15 44.7402C150.29 54.3202 154.85 64.9802 156.48 76.8502C157.01 80.7002 155.99 81.9102 152.07 81.9502C147.36 82.0102 142.66 81.9602 137.95 81.9602H137.94ZM135.25 75.7702C139.4 75.7702 143.56 75.7402 147.71 75.7802C149.58 75.8002 150.23 74.9902 149.83 73.2102C148.09 65.4802 145.13 58.2702 140.76 51.6302C139.89 50.3102 139.22 50.0802 138.01 51.3102C132.25 57.1702 126.42 62.9602 120.61 68.7702C119.23 70.1502 118.8 71.7802 119.51 73.5902C120.23 75.4202 121.84 75.7802 123.61 75.7702C127.49 75.7702 131.37 75.7702 135.24 75.7702H135.25Z" />
            <path d="M81.9703 137.98C81.9703 142.69 82.0203 147.4 81.9603 152.1C81.9103 155.98 80.6803 157 76.8203 156.46C65.0503 154.84 54.4603 150.32 44.9403 143.28C41.7103 140.89 41.6303 139.48 44.4803 136.62C51.0603 130.02 57.7003 123.47 64.2503 116.83C67.4503 113.59 71.0603 112.11 75.5003 113.82C80.0403 115.58 81.8603 119.23 81.9603 123.84C82.0603 128.55 81.9803 133.25 81.9803 137.96H81.9903L81.9703 137.98ZM75.7803 135.65C75.7803 131.86 75.7803 128.08 75.7803 124.29C75.7803 122.29 75.7203 120.28 73.4503 119.45C71.3603 118.69 69.7403 119.59 68.2503 121.09C62.7303 126.67 57.2003 132.24 51.5803 137.71C50.0403 139.21 50.3303 139.95 51.9703 141C58.4203 145.12 65.3703 147.98 72.8003 149.74C75.0403 150.27 75.8503 149.52 75.7903 147.27C75.7003 143.39 75.7603 139.52 75.7703 135.64L75.7803 135.65Z" />
            <path d="M81.9603 35.8602C81.9603 40.5702 82.0903 45.2802 81.9303 49.9802C81.6803 57.2902 76.1903 61.9502 69.8103 60.4702C67.9603 60.0402 66.3903 59.1302 65.0503 57.7902C58.0603 50.8102 51.0603 43.8402 44.1003 36.8302C41.7503 34.4702 41.8203 32.9202 44.4803 30.9102C54.4003 23.4102 65.5303 18.7802 77.8903 17.2002C80.5003 16.8702 81.8703 18.1702 81.9303 21.1702C82.0303 26.0602 81.9603 30.9502 81.9603 35.8502V35.8602ZM75.7803 38.5502C75.7803 34.4902 75.7203 30.4202 75.8003 26.3602C75.8403 24.1502 74.8303 23.5702 72.8703 24.0502C65.3703 25.8902 58.2903 28.7202 51.8103 32.9702C50.4903 33.8302 49.9203 34.4802 51.3503 35.8802C57.1603 41.5602 62.8803 47.3302 68.6103 53.0902C70.0603 54.5502 71.7203 55.1002 73.6403 54.3102C75.4703 53.5602 75.7703 51.9302 75.7703 50.1702C75.7703 46.2902 75.7703 42.4102 75.7703 38.5402L75.7803 38.5502Z" />
            <path d="M91.8901 35.8901C91.8901 31.1801 91.8401 26.4701 91.9001 21.7701C91.9501 17.8801 93.1701 16.8601 97.0401 17.3901C108.81 19.0101 119.4 23.5101 128.93 30.5701C132.16 32.9601 132.25 34.3801 129.41 37.2301C122.7 43.9601 115.95 50.6701 109.24 57.3901C106.24 60.4001 102.75 61.6101 98.6601 60.1301C94.6201 58.6701 92.2801 55.6501 92.1001 51.3901C91.8801 46.2301 92.0501 41.0601 92.0501 35.8901C92.0001 35.8901 91.9601 35.8901 91.9101 35.8901H91.8901ZM98.1001 38.4301C98.1001 42.4001 98.1001 46.3701 98.1001 50.3401C98.1001 52.1201 98.5301 53.7001 100.37 54.3801C102.07 55.0001 103.65 54.6601 104.96 53.3601C110.85 47.4901 116.72 41.6101 122.63 35.7701C123.71 34.7001 123.62 34.0301 122.38 33.2001C115.77 28.7801 108.53 25.8801 100.84 24.0201C99.0001 23.5801 98.0501 24.2001 98.0801 26.2501C98.1501 30.3101 98.1001 34.3801 98.1001 38.4401V38.4301Z" />
            <path d="M35.6799 81.9702C31.0599 81.9702 26.4499 82.0202 21.8299 81.9602C17.8899 81.9102 16.8599 80.7102 17.3799 76.8902C18.9699 65.1102 23.4899 54.5202 30.5199 44.9902C32.9899 41.6502 34.3599 41.6002 37.3699 44.6002C43.8999 51.1202 50.3899 57.6902 56.9599 64.1702C60.1999 67.3602 61.7299 70.9602 60.0499 75.4002C58.2799 80.0702 54.5199 81.8702 49.7999 81.9402C45.0899 82.0202 40.3899 81.9602 35.6799 81.9602V81.9702ZM38.4499 75.7702C42.4199 75.7702 46.3899 75.7702 50.3599 75.7702C52.0199 75.7702 53.5499 75.4102 54.2699 73.7302C55.0399 71.9502 54.7199 70.2802 53.3399 68.8902C47.4799 63.0002 41.5899 57.1302 35.7499 51.2202C34.6499 50.1102 33.9899 50.2902 33.1799 51.5102C28.7599 58.1202 25.8599 65.3602 24.0099 73.0602C23.5599 74.9202 24.2299 75.8402 26.2599 75.8002C30.3199 75.7302 34.3899 75.7802 38.4499 75.7802V75.7702Z" />
            <path d="M60.7802 101.96C60.6202 104.43 59.8702 106.69 58.1002 108.47C50.9502 115.68 43.7902 122.88 36.5802 130.02C34.5002 132.08 32.8902 131.93 31.0402 129.51C23.4202 119.54 18.8002 108.31 17.2102 95.8701C16.8802 93.3101 18.2502 91.9701 21.3002 91.9201C26.8402 91.8301 32.3802 91.8801 37.9102 91.8801C41.9702 91.8801 46.0402 91.7701 50.0902 91.9301C56.4302 92.1801 60.8502 96.4101 60.7802 101.96ZM38.5402 98.0901C34.4802 98.0901 30.4102 98.1301 26.3502 98.0701C24.4102 98.0401 23.5402 98.7901 24.0102 100.73C25.8402 108.43 28.7502 115.67 33.1402 122.29C34.0602 123.68 34.7602 123.67 35.9002 122.51C41.6002 116.72 47.3602 110.98 53.1102 105.24C54.5602 103.79 55.1102 102.12 54.3202 100.2C53.5702 98.3701 51.9302 98.0801 50.1802 98.0801C46.3002 98.0801 42.4202 98.0801 38.5502 98.0801L38.5402 98.0901Z" />
            <path d="M86.9399 101.98C78.8999 101.99 71.8899 94.9901 71.8799 86.9401C71.8699 78.8901 78.8699 71.8901 86.9199 71.8801C94.9699 71.8701 101.97 78.8601 101.99 86.9201C102 94.9701 95.0099 101.97 86.9499 101.98H86.9399ZM95.7999 87.0301C95.8399 81.8101 92.2099 78.1001 87.0199 78.0601C81.7999 78.0201 78.0899 81.6501 78.0499 86.8401C78.0099 92.0601 81.6399 95.7701 86.8299 95.8101C92.0499 95.8501 95.7599 92.2201 95.7999 87.0301Z" />
            <path d="M74.0301 71.1301C73.8101 72.8601 72.8901 73.9701 71.2001 73.9701C69.2701 73.9701 68.1101 72.7501 68.0901 70.8601C68.0701 69.1801 69.1801 68.0701 70.8601 68.0801C72.7601 68.0801 73.8101 69.2901 74.0401 71.1301H74.0301Z" />
            <path d="M102.67 74.0302C100.93 73.7802 99.84 72.8102 99.88 71.1402C99.93 69.2302 101.16 68.0702 103.06 68.1002C104.74 68.1202 105.82 69.2502 105.77 70.9302C105.71 72.8202 104.51 73.8602 102.66 74.0302H102.67Z" />
            <path d="M70.9199 105.85C69.2399 105.61 68.0799 104.72 68.0899 103.03C68.0899 101.14 69.2499 99.9102 71.1699 99.8802C72.8499 99.8602 73.9499 100.94 73.9799 102.63C74.0099 104.58 72.7399 105.53 70.9299 105.85H70.9199Z" />
            <path d="M102.77 99.8202C104.6 100.09 105.8 101.15 105.77 103.06C105.74 104.74 104.62 105.83 102.94 105.77C101.06 105.7 99.8501 104.54 99.8901 102.6C99.9301 100.9 101.06 100.02 102.78 99.8202H102.77Z" />
            <path d="M87.0203 61.3801C88.8203 61.7301 89.8903 62.7701 89.7803 64.6201C89.6703 66.4501 88.5503 67.5801 86.7003 67.4401C85.0103 67.3101 84.0503 66.1301 84.0803 64.4201C84.1203 62.5801 85.2203 61.5901 87.0303 61.3801H87.0203Z" />
            <path d="M67.5102 86.9501C67.2202 88.7601 66.2002 89.8501 64.3502 89.7801C62.5202 89.7101 61.3602 88.6201 61.4502 86.7701C61.5302 85.0801 62.7002 84.0901 64.4002 84.0801C66.2502 84.0701 67.2602 85.1401 67.5102 86.9501Z" />
            <path d="M112.48 86.8202C112.28 88.6302 111.3 89.7502 109.46 89.7802C107.63 89.8002 106.43 88.7602 106.42 86.9102C106.42 85.2002 107.53 84.1802 109.23 84.0802C111.08 83.9802 112.12 85.0202 112.48 86.8202Z" />
            <path d="M86.8799 112.48C85.0699 112.16 83.9899 111.13 84.0799 109.28C84.1599 107.45 85.2699 106.3 87.1199 106.41C88.8099 106.51 89.7899 107.69 89.7899 109.39C89.7899 111.23 88.6899 112.23 86.8899 112.47L86.8799 112.48Z" />
          </svg>
        </div>
        <div className="svg_title">Jante AVD</div>
      </div>
      <div className="svg_container_title">
        <div className="svg_block formH">
          <svg id="nettoyage" viewBox="0 0 176 175" xmlns="http://www.w3.org/2000/svg">
            <path d="M145.066 82.7902C145.096 81.3502 145.106 79.9002 144.966 78.4702C142.826 56.9002 124.846 40.6702 103.116 40.6602C101.276 40.6602 99.4564 40.7602 97.6264 40.9902C77.2564 43.5902 61.2864 61.3902 61.2964 81.8902C61.2964 97.1902 67.8264 109.23 80.5364 117.78C86.6564 121.9 93.5564 123.9 100.886 124.42C101.376 124.46 101.826 124.42 102.236 124.36C105.746 139.04 118.886 150.84 135.506 150.97C155.486 151.12 171.136 135.94 171.166 116.55C171.196 100.18 160.246 86.6802 145.076 82.7902H145.066ZM96.6964 117.03C78.1364 113.66 65.4264 95.7202 68.3864 77.1302C71.4464 57.9902 88.7464 45.1802 108.136 47.7002C122.486 49.5602 134.616 60.8002 137.596 74.9502C138.066 77.2002 138.276 79.4602 138.366 81.7402C137.616 81.6902 136.856 81.6602 136.086 81.6602C116.966 81.6202 101.586 97.0302 101.306 116.05C101.306 116.57 101.306 117.08 101.326 117.59C99.7864 117.38 98.2264 117.31 96.6964 117.03ZM135.706 144.29C120.466 144.25 108.036 131.68 108.046 116.31C108.056 100.72 120.396 88.5702 136.206 88.5602C151.626 88.5602 163.826 100.85 163.806 116.4C163.786 131.93 151.296 144.33 135.706 144.29Z" />
            <path d="M28.2464 76.0001C12.9764 75.9801 0.796387 88.0901 0.796387 103.29C0.796387 118.42 12.9264 130.57 28.0564 130.58C43.2264 130.58 55.3764 118.5 55.3964 103.39C55.4064 88.2101 43.3364 76.0201 28.2564 76.0001H28.2464ZM28.0364 123.87C16.7464 123.85 7.50639 114.59 7.50639 103.29C7.50639 91.8801 16.7564 82.6601 28.1964 82.6901C39.5464 82.7101 48.6964 91.8901 48.6964 103.26C48.6964 114.7 39.4864 123.89 28.0364 123.88V123.87Z" />
            <path d="M40.5463 45.1002C52.2363 45.1102 61.7963 35.6302 61.7663 24.0402C61.7463 12.4802 52.2863 3.06018 40.6863 3.05018C28.9763 3.04018 19.4563 12.4702 19.4663 24.0902C19.4763 35.6402 28.9663 45.0902 40.5363 45.1102L40.5463 45.1002ZM40.5163 9.75018C48.5963 9.74018 54.8363 15.9502 54.8563 24.0102C54.8763 32.0702 48.6263 38.4002 40.6263 38.4002C32.6363 38.4002 26.3663 32.0802 26.3763 24.0302C26.3863 16.0402 32.5963 9.77018 40.5063 9.75018H40.5163Z" />
            <path d="M171.416 20.5402C162.486 20.0402 155.866 13.4502 155.246 4.44016C155.186 3.55016 155.096 2.66016 154.676 1.86016C153.996 0.550157 152.926 -0.0898432 151.426 0.0101568C149.996 0.110157 149.056 0.870157 148.626 2.19016C148.416 2.84016 148.336 3.55016 148.296 4.24016C147.836 12.7702 140.896 19.7602 132.396 20.2302C131.446 20.2802 130.486 20.3402 129.576 20.5602C128.066 20.9202 127.136 21.9502 127.036 23.5002C126.936 25.1302 127.776 26.3002 129.306 26.8602C129.946 27.0902 130.666 27.1502 131.346 27.1902C139.176 27.6702 145.926 33.4602 147.556 41.1702C147.846 42.5302 147.896 43.9402 148.216 45.2802C148.666 47.1302 150.046 48.1802 151.736 48.1602C153.676 48.1402 154.846 46.7902 154.936 44.4702C155.306 34.8502 162.006 27.8902 171.516 27.2302C173.816 27.0702 175.356 25.7002 175.326 23.8402C175.296 21.9802 173.726 20.6602 171.406 20.5202L171.416 20.5402ZM158.736 24.5902C156.096 26.3902 153.926 28.6702 152.196 31.3502C151.556 32.3402 151.116 32.1402 150.566 31.2902C148.896 28.7102 146.786 26.5702 144.196 24.9002C143.556 24.4902 142.956 24.0702 143.996 23.4102C146.816 21.6402 149.136 19.3502 151.066 16.6502C151.246 16.3902 151.316 16.0602 151.486 15.6302C152.066 16.2702 152.306 16.4702 152.476 16.7202C154.166 19.2002 156.206 21.3302 158.676 23.0402C159.426 23.5502 159.606 23.9902 158.736 24.5902Z" />
            <path d="M87.7462 151.97C85.7362 151.7 83.7162 151.49 81.9262 150.42C78.1762 148.18 76.1662 144.87 75.8262 140.51C75.6362 138.09 74.2062 136.48 72.3462 136.45C70.5462 136.42 69.3262 137.76 68.9962 140.11C68.9462 140.49 68.9062 140.86 68.8862 141.24C68.7162 144.2 67.3462 146.54 65.1862 148.47C62.7162 150.67 59.7562 151.54 56.5062 151.81C54.1762 152 52.8762 153.41 52.9262 155.23C52.9762 157.07 54.3662 158.33 56.6262 158.6C63.9462 159.45 67.8362 163.34 68.8062 170.8C69.0962 173.02 70.5862 174.45 72.4962 174.35C74.2562 174.26 75.3862 172.89 75.5462 170.68C75.7162 168.25 76.2362 165.93 77.7162 163.91C80.0562 160.7 83.1862 158.92 87.1662 158.67C89.4262 158.53 90.8362 157.27 90.8862 155.41C90.9362 153.68 89.6462 152.23 87.7562 151.97H87.7462ZM72.0962 159.74C71.8262 159.62 71.5562 159.44 71.3462 159.23C70.3162 158.22 69.3362 157.15 68.2662 156.18C67.6262 155.59 67.6162 155.18 68.3162 154.7C68.5262 154.56 68.6962 154.36 68.9162 154.24C70.3462 153.41 71.0262 150.93 72.6462 151.33C73.9462 151.66 74.7162 153.72 76.0662 154.7C76.2562 154.84 76.3962 155.06 76.4962 155.18C76.5262 155.49 72.2762 159.82 72.0962 159.74Z" />
            <path d="M107.456 57.8702C107.116 55.9202 105.686 55.0802 102.726 55.0302C101.536 55.1802 100.336 55.2802 99.1564 55.5002C86.7464 57.8102 78.3064 69.0602 77.9564 81.0602C77.8964 83.2102 79.3464 84.7102 81.2264 84.7402C83.0364 84.7702 84.3564 83.4401 84.6264 81.3501C84.7964 80.0301 84.9164 78.7002 85.2064 77.4102C87.3164 68.2002 94.4564 62.2802 103.976 61.8002C106.416 61.6802 107.816 60.0801 107.436 57.8601L107.456 57.8702Z" />
            <path d="M134.346 93.1602C123.756 93.4802 115.266 101.95 114.816 112.64C114.726 114.68 115.926 116.23 117.746 116.43C119.676 116.64 121.046 115.58 121.506 113.5C121.596 113.07 121.666 112.63 121.686 112.19C121.866 108.8 123.336 106.03 125.716 103.66C128.336 101.05 131.586 100.15 135.146 99.7902C137.326 99.5702 138.286 98.1502 138.096 96.1302C137.916 94.3002 136.396 93.0902 134.346 93.1602Z" />
            <path d="M24.5063 87.4402C15.7163 88.0302 10.9963 96.4202 11.2463 103.59C11.3063 105.29 12.8163 106.54 14.3763 106.61C16.0663 106.69 17.4363 105.57 17.8063 103.73C17.9663 102.93 18.0263 102.11 18.1363 101.29C18.6563 97.3702 21.2763 94.6502 25.0463 94.1002C27.0863 93.8102 28.4163 92.3902 28.3163 90.6202C28.2063 88.7902 26.5663 87.4202 24.5063 87.4402Z" />
          </svg>
        </div>
        <div className="svg_title">Nettoyage</div>
      </div>
      <div className="svg_container_title">
        <div className="svg_block formH">
          <svg id="jante_arg" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
            <path d="M87.0101 174C38.6401 173.38 0.0700959 135.46 9.58742e-05 87.0002C-0.0699041 38.8702 38.2001 0.0901585 86.8601 0.000158537C134.96 -0.0898415 173.78 38.1502 173.86 86.8502C173.93 135.03 135.64 173.3 87.0101 174ZM167.8 86.8102C168.2 42.5702 131.23 5.92016 86.8801 5.95016C42.5601 5.98016 5.9601 42.6802 5.9501 86.9002C5.9401 131.21 42.6701 167.99 87.1201 167.9C131.26 167.81 168.33 131.22 167.8 86.8002V86.8102Z" />
            <path d="M138.22 91.8901C142.84 91.8901 147.45 91.8501 152.07 91.9001C155.99 91.9501 157.01 93.1601 156.48 97.0001C154.85 108.77 150.39 119.38 143.32 128.9C140.91 132.15 139.5 132.24 136.66 129.42C129.93 122.71 123.22 115.96 116.49 109.25C113.49 106.25 112.27 102.78 113.72 98.6701C115.16 94.6001 118.18 92.2701 122.44 92.0901C127.69 91.8701 132.96 92.0401 138.21 92.0401C138.21 91.9901 138.21 91.9401 138.21 91.8901H138.22ZM135.51 98.0901C131.63 98.0901 127.75 98.0901 123.88 98.0901C122.04 98.0901 120.28 98.3101 119.51 100.29C118.75 102.22 119.36 103.86 120.81 105.31C126.5 110.98 132.21 116.63 137.82 122.38C139.23 123.83 139.94 123.53 140.93 122C145.13 115.49 148.02 108.45 149.78 100.92C150.27 98.8301 149.53 98.0301 147.42 98.0701C143.45 98.1501 139.48 98.0901 135.51 98.0901Z" />
            <path d="M91.8999 138C91.8999 133.29 91.7699 128.58 91.9299 123.88C92.1799 116.52 97.5999 111.92 104.04 113.39C105.89 113.81 107.47 114.73 108.8 116.06C115.79 123.03 122.8 130 129.76 137C132.11 139.36 132.03 140.93 129.39 142.93C119.47 150.45 108.33 155.04 95.9799 156.64C93.3699 156.98 91.9899 155.68 91.9299 152.68C91.8299 147.79 91.8999 142.9 91.8999 138ZM98.0899 135.27C98.0899 139.43 98.1199 143.58 98.0799 147.73C98.0599 149.61 98.8999 150.22 100.67 149.82C108.4 148.07 115.61 145.11 122.25 140.74C123.59 139.86 123.76 139.18 122.55 137.99C116.69 132.23 110.9 126.4 105.08 120.59C103.7 119.21 102.06 118.8 100.26 119.51C98.4299 120.24 98.0899 121.86 98.0999 123.62C98.0999 127.5 98.0999 131.38 98.0999 135.25L98.0899 135.27Z" />
            <path d="M137.94 81.9602C133.23 81.9602 128.52 82.1002 123.82 81.9302C116.53 81.6702 111.9 76.1602 113.4 69.7602C113.86 67.8102 114.9 66.2102 116.3 64.8102C123.15 57.9602 129.99 51.0902 136.86 44.2502C139.44 41.6802 140.94 41.7802 143.15 44.7402C150.29 54.3202 154.85 64.9802 156.48 76.8502C157.01 80.7002 155.99 81.9102 152.07 81.9502C147.36 82.0102 142.66 81.9602 137.95 81.9602H137.94ZM135.25 75.7702C139.4 75.7702 143.56 75.7402 147.71 75.7802C149.58 75.8002 150.23 74.9902 149.83 73.2102C148.09 65.4802 145.13 58.2702 140.76 51.6302C139.89 50.3102 139.22 50.0802 138.01 51.3102C132.25 57.1702 126.42 62.9602 120.61 68.7702C119.23 70.1502 118.8 71.7802 119.51 73.5902C120.23 75.4202 121.84 75.7802 123.61 75.7702C127.49 75.7702 131.37 75.7702 135.24 75.7702H135.25Z" />
            <path d="M81.9703 137.98C81.9703 142.69 82.0203 147.4 81.9603 152.1C81.9103 155.98 80.6803 157 76.8203 156.46C65.0503 154.84 54.4603 150.32 44.9403 143.28C41.7103 140.89 41.6303 139.48 44.4803 136.62C51.0603 130.02 57.7003 123.47 64.2503 116.83C67.4503 113.59 71.0603 112.11 75.5003 113.82C80.0403 115.58 81.8603 119.23 81.9603 123.84C82.0603 128.55 81.9803 133.25 81.9803 137.96H81.9903L81.9703 137.98ZM75.7803 135.65C75.7803 131.86 75.7803 128.08 75.7803 124.29C75.7803 122.29 75.7203 120.28 73.4503 119.45C71.3603 118.69 69.7403 119.59 68.2503 121.09C62.7303 126.67 57.2003 132.24 51.5803 137.71C50.0403 139.21 50.3303 139.95 51.9703 141C58.4203 145.12 65.3703 147.98 72.8003 149.74C75.0403 150.27 75.8503 149.52 75.7903 147.27C75.7003 143.39 75.7603 139.52 75.7703 135.64L75.7803 135.65Z" />
            <path d="M81.9603 35.8602C81.9603 40.5702 82.0903 45.2802 81.9303 49.9802C81.6803 57.2902 76.1903 61.9502 69.8103 60.4702C67.9603 60.0402 66.3903 59.1302 65.0503 57.7902C58.0603 50.8102 51.0603 43.8402 44.1003 36.8302C41.7503 34.4702 41.8203 32.9202 44.4803 30.9102C54.4003 23.4102 65.5303 18.7802 77.8903 17.2002C80.5003 16.8702 81.8703 18.1702 81.9303 21.1702C82.0303 26.0602 81.9603 30.9502 81.9603 35.8502V35.8602ZM75.7803 38.5502C75.7803 34.4902 75.7203 30.4202 75.8003 26.3602C75.8403 24.1502 74.8303 23.5702 72.8703 24.0502C65.3703 25.8902 58.2903 28.7202 51.8103 32.9702C50.4903 33.8302 49.9203 34.4802 51.3503 35.8802C57.1603 41.5602 62.8803 47.3302 68.6103 53.0902C70.0603 54.5502 71.7203 55.1002 73.6403 54.3102C75.4703 53.5602 75.7703 51.9302 75.7703 50.1702C75.7703 46.2902 75.7703 42.4102 75.7703 38.5402L75.7803 38.5502Z" />
            <path d="M91.8901 35.8901C91.8901 31.1801 91.8401 26.4701 91.9001 21.7701C91.9501 17.8801 93.1701 16.8601 97.0401 17.3901C108.81 19.0101 119.4 23.5101 128.93 30.5701C132.16 32.9601 132.25 34.3801 129.41 37.2301C122.7 43.9601 115.95 50.6701 109.24 57.3901C106.24 60.4001 102.75 61.6101 98.6601 60.1301C94.6201 58.6701 92.2801 55.6501 92.1001 51.3901C91.8801 46.2301 92.0501 41.0601 92.0501 35.8901C92.0001 35.8901 91.9601 35.8901 91.9101 35.8901H91.8901ZM98.1001 38.4301C98.1001 42.4001 98.1001 46.3701 98.1001 50.3401C98.1001 52.1201 98.5301 53.7001 100.37 54.3801C102.07 55.0001 103.65 54.6601 104.96 53.3601C110.85 47.4901 116.72 41.6101 122.63 35.7701C123.71 34.7001 123.62 34.0301 122.38 33.2001C115.77 28.7801 108.53 25.8801 100.84 24.0201C99.0001 23.5801 98.0501 24.2001 98.0801 26.2501C98.1501 30.3101 98.1001 34.3801 98.1001 38.4401V38.4301Z" />
            <path d="M35.6799 81.9702C31.0599 81.9702 26.4499 82.0202 21.8299 81.9602C17.8899 81.9102 16.8599 80.7102 17.3799 76.8902C18.9699 65.1102 23.4899 54.5202 30.5199 44.9902C32.9899 41.6502 34.3599 41.6002 37.3699 44.6002C43.8999 51.1202 50.3899 57.6902 56.9599 64.1702C60.1999 67.3602 61.7299 70.9602 60.0499 75.4002C58.2799 80.0702 54.5199 81.8702 49.7999 81.9402C45.0899 82.0202 40.3899 81.9602 35.6799 81.9602V81.9702ZM38.4499 75.7702C42.4199 75.7702 46.3899 75.7702 50.3599 75.7702C52.0199 75.7702 53.5499 75.4102 54.2699 73.7302C55.0399 71.9502 54.7199 70.2802 53.3399 68.8902C47.4799 63.0002 41.5899 57.1302 35.7499 51.2202C34.6499 50.1102 33.9899 50.2902 33.1799 51.5102C28.7599 58.1202 25.8599 65.3602 24.0099 73.0602C23.5599 74.9202 24.2299 75.8402 26.2599 75.8002C30.3199 75.7302 34.3899 75.7802 38.4499 75.7802V75.7702Z" />
            <path d="M60.7802 101.96C60.6202 104.43 59.8702 106.69 58.1002 108.47C50.9502 115.68 43.7902 122.88 36.5802 130.02C34.5002 132.08 32.8902 131.93 31.0402 129.51C23.4202 119.54 18.8002 108.31 17.2102 95.8701C16.8802 93.3101 18.2502 91.9701 21.3002 91.9201C26.8402 91.8301 32.3802 91.8801 37.9102 91.8801C41.9702 91.8801 46.0402 91.7701 50.0902 91.9301C56.4302 92.1801 60.8502 96.4101 60.7802 101.96ZM38.5402 98.0901C34.4802 98.0901 30.4102 98.1301 26.3502 98.0701C24.4102 98.0401 23.5402 98.7901 24.0102 100.73C25.8402 108.43 28.7502 115.67 33.1402 122.29C34.0602 123.68 34.7602 123.67 35.9002 122.51C41.6002 116.72 47.3602 110.98 53.1102 105.24C54.5602 103.79 55.1102 102.12 54.3202 100.2C53.5702 98.3701 51.9302 98.0801 50.1802 98.0801C46.3002 98.0801 42.4202 98.0801 38.5502 98.0801L38.5402 98.0901Z" />
            <path d="M86.9399 101.98C78.8999 101.99 71.8899 94.9901 71.8799 86.9401C71.8699 78.8901 78.8699 71.8901 86.9199 71.8801C94.9699 71.8701 101.97 78.8601 101.99 86.9201C102 94.9701 95.0099 101.97 86.9499 101.98H86.9399ZM95.7999 87.0301C95.8399 81.8101 92.2099 78.1001 87.0199 78.0601C81.7999 78.0201 78.0899 81.6501 78.0499 86.8401C78.0099 92.0601 81.6399 95.7701 86.8299 95.8101C92.0499 95.8501 95.7599 92.2201 95.7999 87.0301Z" />
            <path d="M74.0301 71.1301C73.8101 72.8601 72.8901 73.9701 71.2001 73.9701C69.2701 73.9701 68.1101 72.7501 68.0901 70.8601C68.0701 69.1801 69.1801 68.0701 70.8601 68.0801C72.7601 68.0801 73.8101 69.2901 74.0401 71.1301H74.0301Z" />
            <path d="M102.67 74.0302C100.93 73.7802 99.84 72.8102 99.88 71.1402C99.93 69.2302 101.16 68.0702 103.06 68.1002C104.74 68.1202 105.82 69.2502 105.77 70.9302C105.71 72.8202 104.51 73.8602 102.66 74.0302H102.67Z" />
            <path d="M70.9199 105.85C69.2399 105.61 68.0799 104.72 68.0899 103.03C68.0899 101.14 69.2499 99.9102 71.1699 99.8802C72.8499 99.8602 73.9499 100.94 73.9799 102.63C74.0099 104.58 72.7399 105.53 70.9299 105.85H70.9199Z" />
            <path d="M102.77 99.8202C104.6 100.09 105.8 101.15 105.77 103.06C105.74 104.74 104.62 105.83 102.94 105.77C101.06 105.7 99.8501 104.54 99.8901 102.6C99.9301 100.9 101.06 100.02 102.78 99.8202H102.77Z" />
            <path d="M87.0203 61.3801C88.8203 61.7301 89.8903 62.7701 89.7803 64.6201C89.6703 66.4501 88.5503 67.5801 86.7003 67.4401C85.0103 67.3101 84.0503 66.1301 84.0803 64.4201C84.1203 62.5801 85.2203 61.5901 87.0303 61.3801H87.0203Z" />
            <path d="M67.5102 86.9501C67.2202 88.7601 66.2002 89.8501 64.3502 89.7801C62.5202 89.7101 61.3602 88.6201 61.4502 86.7701C61.5302 85.0801 62.7002 84.0901 64.4002 84.0801C66.2502 84.0701 67.2602 85.1401 67.5102 86.9501Z" />
            <path d="M112.48 86.8202C112.28 88.6302 111.3 89.7502 109.46 89.7802C107.63 89.8002 106.43 88.7602 106.42 86.9102C106.42 85.2002 107.53 84.1802 109.23 84.0802C111.08 83.9802 112.12 85.0202 112.48 86.8202Z" />
            <path d="M86.8799 112.48C85.0699 112.16 83.9899 111.13 84.0799 109.28C84.1599 107.45 85.2699 106.3 87.1199 106.41C88.8099 106.51 89.7899 107.69 89.7899 109.39C89.7899 111.23 88.6899 112.23 86.8899 112.47L86.8799 112.48Z" />
          </svg>
        </div>
        <div className="svg_title">Jante ARG</div>
      </div>
      <div className="svg_container_title">
        <div className="svg_block formH">
          <svg id="jante_ard" viewBox="0 0 174 174" xmlns="http://www.w3.org/2000/svg">
            <path d="M87.0101 174C38.6401 173.38 0.0700959 135.46 9.58742e-05 87.0002C-0.0699041 38.8702 38.2001 0.0901585 86.8601 0.000158537C134.96 -0.0898415 173.78 38.1502 173.86 86.8502C173.93 135.03 135.64 173.3 87.0101 174ZM167.8 86.8102C168.2 42.5702 131.23 5.92016 86.8801 5.95016C42.5601 5.98016 5.9601 42.6802 5.9501 86.9002C5.9401 131.21 42.6701 167.99 87.1201 167.9C131.26 167.81 168.33 131.22 167.8 86.8002V86.8102Z" />
            <path d="M138.22 91.8901C142.84 91.8901 147.45 91.8501 152.07 91.9001C155.99 91.9501 157.01 93.1601 156.48 97.0001C154.85 108.77 150.39 119.38 143.32 128.9C140.91 132.15 139.5 132.24 136.66 129.42C129.93 122.71 123.22 115.96 116.49 109.25C113.49 106.25 112.27 102.78 113.72 98.6701C115.16 94.6001 118.18 92.2701 122.44 92.0901C127.69 91.8701 132.96 92.0401 138.21 92.0401C138.21 91.9901 138.21 91.9401 138.21 91.8901H138.22ZM135.51 98.0901C131.63 98.0901 127.75 98.0901 123.88 98.0901C122.04 98.0901 120.28 98.3101 119.51 100.29C118.75 102.22 119.36 103.86 120.81 105.31C126.5 110.98 132.21 116.63 137.82 122.38C139.23 123.83 139.94 123.53 140.93 122C145.13 115.49 148.02 108.45 149.78 100.92C150.27 98.8301 149.53 98.0301 147.42 98.0701C143.45 98.1501 139.48 98.0901 135.51 98.0901Z" />
            <path d="M91.8999 138C91.8999 133.29 91.7699 128.58 91.9299 123.88C92.1799 116.52 97.5999 111.92 104.04 113.39C105.89 113.81 107.47 114.73 108.8 116.06C115.79 123.03 122.8 130 129.76 137C132.11 139.36 132.03 140.93 129.39 142.93C119.47 150.45 108.33 155.04 95.9799 156.64C93.3699 156.98 91.9899 155.68 91.9299 152.68C91.8299 147.79 91.8999 142.9 91.8999 138ZM98.0899 135.27C98.0899 139.43 98.1199 143.58 98.0799 147.73C98.0599 149.61 98.8999 150.22 100.67 149.82C108.4 148.07 115.61 145.11 122.25 140.74C123.59 139.86 123.76 139.18 122.55 137.99C116.69 132.23 110.9 126.4 105.08 120.59C103.7 119.21 102.06 118.8 100.26 119.51C98.4299 120.24 98.0899 121.86 98.0999 123.62C98.0999 127.5 98.0999 131.38 98.0999 135.25L98.0899 135.27Z" />
            <path d="M137.94 81.9602C133.23 81.9602 128.52 82.1002 123.82 81.9302C116.53 81.6702 111.9 76.1602 113.4 69.7602C113.86 67.8102 114.9 66.2102 116.3 64.8102C123.15 57.9602 129.99 51.0902 136.86 44.2502C139.44 41.6802 140.94 41.7802 143.15 44.7402C150.29 54.3202 154.85 64.9802 156.48 76.8502C157.01 80.7002 155.99 81.9102 152.07 81.9502C147.36 82.0102 142.66 81.9602 137.95 81.9602H137.94ZM135.25 75.7702C139.4 75.7702 143.56 75.7402 147.71 75.7802C149.58 75.8002 150.23 74.9902 149.83 73.2102C148.09 65.4802 145.13 58.2702 140.76 51.6302C139.89 50.3102 139.22 50.0802 138.01 51.3102C132.25 57.1702 126.42 62.9602 120.61 68.7702C119.23 70.1502 118.8 71.7802 119.51 73.5902C120.23 75.4202 121.84 75.7802 123.61 75.7702C127.49 75.7702 131.37 75.7702 135.24 75.7702H135.25Z" />
            <path d="M81.9703 137.98C81.9703 142.69 82.0203 147.4 81.9603 152.1C81.9103 155.98 80.6803 157 76.8203 156.46C65.0503 154.84 54.4603 150.32 44.9403 143.28C41.7103 140.89 41.6303 139.48 44.4803 136.62C51.0603 130.02 57.7003 123.47 64.2503 116.83C67.4503 113.59 71.0603 112.11 75.5003 113.82C80.0403 115.58 81.8603 119.23 81.9603 123.84C82.0603 128.55 81.9803 133.25 81.9803 137.96H81.9903L81.9703 137.98ZM75.7803 135.65C75.7803 131.86 75.7803 128.08 75.7803 124.29C75.7803 122.29 75.7203 120.28 73.4503 119.45C71.3603 118.69 69.7403 119.59 68.2503 121.09C62.7303 126.67 57.2003 132.24 51.5803 137.71C50.0403 139.21 50.3303 139.95 51.9703 141C58.4203 145.12 65.3703 147.98 72.8003 149.74C75.0403 150.27 75.8503 149.52 75.7903 147.27C75.7003 143.39 75.7603 139.52 75.7703 135.64L75.7803 135.65Z" />
            <path d="M81.9603 35.8602C81.9603 40.5702 82.0903 45.2802 81.9303 49.9802C81.6803 57.2902 76.1903 61.9502 69.8103 60.4702C67.9603 60.0402 66.3903 59.1302 65.0503 57.7902C58.0603 50.8102 51.0603 43.8402 44.1003 36.8302C41.7503 34.4702 41.8203 32.9202 44.4803 30.9102C54.4003 23.4102 65.5303 18.7802 77.8903 17.2002C80.5003 16.8702 81.8703 18.1702 81.9303 21.1702C82.0303 26.0602 81.9603 30.9502 81.9603 35.8502V35.8602ZM75.7803 38.5502C75.7803 34.4902 75.7203 30.4202 75.8003 26.3602C75.8403 24.1502 74.8303 23.5702 72.8703 24.0502C65.3703 25.8902 58.2903 28.7202 51.8103 32.9702C50.4903 33.8302 49.9203 34.4802 51.3503 35.8802C57.1603 41.5602 62.8803 47.3302 68.6103 53.0902C70.0603 54.5502 71.7203 55.1002 73.6403 54.3102C75.4703 53.5602 75.7703 51.9302 75.7703 50.1702C75.7703 46.2902 75.7703 42.4102 75.7703 38.5402L75.7803 38.5502Z" />
            <path d="M91.8901 35.8901C91.8901 31.1801 91.8401 26.4701 91.9001 21.7701C91.9501 17.8801 93.1701 16.8601 97.0401 17.3901C108.81 19.0101 119.4 23.5101 128.93 30.5701C132.16 32.9601 132.25 34.3801 129.41 37.2301C122.7 43.9601 115.95 50.6701 109.24 57.3901C106.24 60.4001 102.75 61.6101 98.6601 60.1301C94.6201 58.6701 92.2801 55.6501 92.1001 51.3901C91.8801 46.2301 92.0501 41.0601 92.0501 35.8901C92.0001 35.8901 91.9601 35.8901 91.9101 35.8901H91.8901ZM98.1001 38.4301C98.1001 42.4001 98.1001 46.3701 98.1001 50.3401C98.1001 52.1201 98.5301 53.7001 100.37 54.3801C102.07 55.0001 103.65 54.6601 104.96 53.3601C110.85 47.4901 116.72 41.6101 122.63 35.7701C123.71 34.7001 123.62 34.0301 122.38 33.2001C115.77 28.7801 108.53 25.8801 100.84 24.0201C99.0001 23.5801 98.0501 24.2001 98.0801 26.2501C98.1501 30.3101 98.1001 34.3801 98.1001 38.4401V38.4301Z" />
            <path d="M35.6799 81.9702C31.0599 81.9702 26.4499 82.0202 21.8299 81.9602C17.8899 81.9102 16.8599 80.7102 17.3799 76.8902C18.9699 65.1102 23.4899 54.5202 30.5199 44.9902C32.9899 41.6502 34.3599 41.6002 37.3699 44.6002C43.8999 51.1202 50.3899 57.6902 56.9599 64.1702C60.1999 67.3602 61.7299 70.9602 60.0499 75.4002C58.2799 80.0702 54.5199 81.8702 49.7999 81.9402C45.0899 82.0202 40.3899 81.9602 35.6799 81.9602V81.9702ZM38.4499 75.7702C42.4199 75.7702 46.3899 75.7702 50.3599 75.7702C52.0199 75.7702 53.5499 75.4102 54.2699 73.7302C55.0399 71.9502 54.7199 70.2802 53.3399 68.8902C47.4799 63.0002 41.5899 57.1302 35.7499 51.2202C34.6499 50.1102 33.9899 50.2902 33.1799 51.5102C28.7599 58.1202 25.8599 65.3602 24.0099 73.0602C23.5599 74.9202 24.2299 75.8402 26.2599 75.8002C30.3199 75.7302 34.3899 75.7802 38.4499 75.7802V75.7702Z" />
            <path d="M60.7802 101.96C60.6202 104.43 59.8702 106.69 58.1002 108.47C50.9502 115.68 43.7902 122.88 36.5802 130.02C34.5002 132.08 32.8902 131.93 31.0402 129.51C23.4202 119.54 18.8002 108.31 17.2102 95.8701C16.8802 93.3101 18.2502 91.9701 21.3002 91.9201C26.8402 91.8301 32.3802 91.8801 37.9102 91.8801C41.9702 91.8801 46.0402 91.7701 50.0902 91.9301C56.4302 92.1801 60.8502 96.4101 60.7802 101.96ZM38.5402 98.0901C34.4802 98.0901 30.4102 98.1301 26.3502 98.0701C24.4102 98.0401 23.5402 98.7901 24.0102 100.73C25.8402 108.43 28.7502 115.67 33.1402 122.29C34.0602 123.68 34.7602 123.67 35.9002 122.51C41.6002 116.72 47.3602 110.98 53.1102 105.24C54.5602 103.79 55.1102 102.12 54.3202 100.2C53.5702 98.3701 51.9302 98.0801 50.1802 98.0801C46.3002 98.0801 42.4202 98.0801 38.5502 98.0801L38.5402 98.0901Z" />
            <path d="M86.9399 101.98C78.8999 101.99 71.8899 94.9901 71.8799 86.9401C71.8699 78.8901 78.8699 71.8901 86.9199 71.8801C94.9699 71.8701 101.97 78.8601 101.99 86.9201C102 94.9701 95.0099 101.97 86.9499 101.98H86.9399ZM95.7999 87.0301C95.8399 81.8101 92.2099 78.1001 87.0199 78.0601C81.7999 78.0201 78.0899 81.6501 78.0499 86.8401C78.0099 92.0601 81.6399 95.7701 86.8299 95.8101C92.0499 95.8501 95.7599 92.2201 95.7999 87.0301Z" />
            <path d="M74.0301 71.1301C73.8101 72.8601 72.8901 73.9701 71.2001 73.9701C69.2701 73.9701 68.1101 72.7501 68.0901 70.8601C68.0701 69.1801 69.1801 68.0701 70.8601 68.0801C72.7601 68.0801 73.8101 69.2901 74.0401 71.1301H74.0301Z" />
            <path d="M102.67 74.0302C100.93 73.7802 99.84 72.8102 99.88 71.1402C99.93 69.2302 101.16 68.0702 103.06 68.1002C104.74 68.1202 105.82 69.2502 105.77 70.9302C105.71 72.8202 104.51 73.8602 102.66 74.0302H102.67Z" />
            <path d="M70.9199 105.85C69.2399 105.61 68.0799 104.72 68.0899 103.03C68.0899 101.14 69.2499 99.9102 71.1699 99.8802C72.8499 99.8602 73.9499 100.94 73.9799 102.63C74.0099 104.58 72.7399 105.53 70.9299 105.85H70.9199Z" />
            <path d="M102.77 99.8202C104.6 100.09 105.8 101.15 105.77 103.06C105.74 104.74 104.62 105.83 102.94 105.77C101.06 105.7 99.8501 104.54 99.8901 102.6C99.9301 100.9 101.06 100.02 102.78 99.8202H102.77Z" />
            <path d="M87.0203 61.3801C88.8203 61.7301 89.8903 62.7701 89.7803 64.6201C89.6703 66.4501 88.5503 67.5801 86.7003 67.4401C85.0103 67.3101 84.0503 66.1301 84.0803 64.4201C84.1203 62.5801 85.2203 61.5901 87.0303 61.3801H87.0203Z" />
            <path d="M67.5102 86.9501C67.2202 88.7601 66.2002 89.8501 64.3502 89.7801C62.5202 89.7101 61.3602 88.6201 61.4502 86.7701C61.5302 85.0801 62.7002 84.0901 64.4002 84.0801C66.2502 84.0701 67.2602 85.1401 67.5102 86.9501Z" />
            <path d="M112.48 86.8202C112.28 88.6302 111.3 89.7502 109.46 89.7802C107.63 89.8002 106.43 88.7602 106.42 86.9102C106.42 85.2002 107.53 84.1802 109.23 84.0802C111.08 83.9802 112.12 85.0202 112.48 86.8202Z" />
            <path d="M86.8799 112.48C85.0699 112.16 83.9899 111.13 84.0799 109.28C84.1599 107.45 85.2699 106.3 87.1199 106.41C88.8099 106.51 89.7899 107.69 89.7899 109.39C89.7899 111.23 88.6899 112.23 86.8899 112.47L86.8799 112.48Z" />
          </svg>
        </div>
        <div className="svg_title">Jante ARD</div>
      </div>
      <div className="svg_container_title">
        <div className="svg_block formH">
          <svg
            id="grele"
            className={isFromExternal || hasColorClass ? '' : 'redFill'}
            viewBox="0 0 90.5 90.5"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M90.202 36.0576C88.217 22.8626 75.107 13.2076 62.122 15.4626C60.637 15.7226 59.852 15.3726 58.967 14.1626C51.397 3.78263 41.297 -1.25737 28.362 0.267635C12.142 2.18263 -1.63302 16.9576 0.156979 35.7376C1.88698 53.8526 17.482 65.5826 32.857 65.1976C38.142 65.0675 43.432 65.1776 48.717 65.1776C54.132 65.1776 59.547 65.1925 64.962 65.1675C66.367 65.1625 67.792 65.1725 69.172 64.9525C83.142 62.7175 92.312 50.0925 90.202 36.0576ZM68.472 60.2775C67.132 60.4875 65.757 60.5675 64.397 60.5725C59.242 60.6075 54.082 60.5875 48.927 60.5875C43.642 60.5875 38.357 60.6225 33.072 60.5775C20.847 60.4775 10.627 53.4426 6.45702 42.3076C-0.318018 24.2226 13.127 4.69263 32.412 4.72763C43.032 4.74763 51.112 9.47763 56.717 18.4676C58.067 20.6326 58.507 20.8626 60.982 20.3276C72.352 17.8826 83.367 25.0626 85.447 36.2826C87.582 47.7976 80.057 58.4476 68.472 60.2725V60.2775Z" />
            <path d="M52.397 81.9476C50.002 80.5975 49.9671 80.4975 52.457 79.1426C53.397 78.6326 54.127 77.9525 54.127 76.2676C54.127 75.0375 52.432 73.9775 50.757 74.7025C50.057 75.0075 49.4071 75.4225 48.7321 75.7925C48.0471 76.1725 47.6671 75.9375 47.6071 75.1925C47.5471 74.4275 47.5821 73.6575 47.5121 72.8975C47.3871 71.5625 46.5071 70.6875 45.3271 70.6525C44.0971 70.6175 43.1471 71.5125 43.0171 72.8975C42.9471 73.6575 42.9821 74.4325 42.9221 75.1925C42.8671 75.9375 42.4821 76.1725 41.7971 75.7925C41.1621 75.4425 40.5471 75.0575 39.8921 74.7475C38.6071 74.1425 37.2871 74.5325 36.6721 75.6525C36.0921 76.7125 36.4471 78.0126 37.5571 78.7775C38.0821 79.1375 38.6421 79.4525 39.1971 79.7675C40.0771 80.2625 40.2071 80.7475 39.2371 81.2925C38.6821 81.6025 38.1221 81.9225 37.6021 82.2925C36.4371 83.1175 36.0921 84.3276 36.6721 85.3976C37.2771 86.5076 38.5671 86.8976 39.8971 86.3175C40.5171 86.0475 41.1021 85.6875 41.6921 85.3425C42.4721 84.8875 42.9021 85.0825 42.9421 85.9975C42.9721 86.7225 42.9421 87.4475 43.0071 88.1675C43.1171 89.4875 43.9921 90.3525 45.1971 90.3875C46.4521 90.4225 47.4021 89.5375 47.5171 88.1725C47.5771 87.4475 47.5521 86.7225 47.5821 85.9975C47.6221 84.8925 48.0471 84.8925 48.8321 85.3325C49.4971 85.7075 50.157 86.1225 50.862 86.4025C51.797 86.7675 52.717 86.6525 53.462 85.9025C54.127 85.2325 54.282 84.4225 53.967 83.5425C53.692 82.7725 53.072 82.3125 52.392 81.9325L52.397 81.9476Z" />
            <path d="M18.1719 70.7875C17.0469 70.4325 16.1319 70.8675 15.5019 71.7875C13.8669 74.1775 12.2519 76.5875 10.6769 79.0175C9.77688 80.4075 10.0119 81.7125 11.2019 82.4725C12.3969 83.2375 13.6619 82.9225 14.5919 81.5575C16.1519 79.2675 17.6719 76.9525 19.2019 74.6475C19.5319 74.1475 19.8019 73.6125 19.8119 72.8875C19.7319 71.9075 19.2219 71.1225 18.1669 70.7925L18.1719 70.7875Z" />
            <path d="M30.9319 70.8325C29.7619 70.3825 28.8119 70.8225 28.1519 71.7775C26.5319 74.1175 24.9469 76.4875 23.3919 78.8725C22.4719 80.2825 22.6719 81.7525 23.8119 82.4825C24.9979 83.2475 26.3179 82.8875 27.2869 81.4575C28.8169 79.2075 30.3119 76.9325 31.8169 74.6675C32.1519 74.1675 32.4069 73.6325 32.3919 73.0025C32.3819 71.9575 31.9119 71.2025 30.9319 70.8275V70.8325Z" />
            <path d="M68.777 70.8225C67.602 70.3825 66.662 70.8375 66.002 71.7925C64.387 74.1375 62.802 76.5025 61.242 78.8875C60.322 80.2975 60.542 81.7625 61.692 82.4925C62.882 83.2575 64.187 82.8775 65.162 81.4375C66.687 79.2225 68.142 76.9825 69.627 74.7575C69.987 74.2225 70.282 73.6575 70.257 72.9825C70.237 71.9375 69.762 71.1875 68.777 70.8225Z" />
            <path d="M81.252 70.7725C80.127 70.4075 79.232 70.8675 78.602 71.7925C77.022 74.1125 75.462 76.4485 73.922 78.7925C73.022 80.3125 73.182 81.6675 74.327 82.4775C75.497 83.2425 76.802 82.8775 77.792 81.4225C79.327 79.1725 80.817 76.8975 82.327 74.6325C82.662 74.1225 82.892 73.5875 82.902 72.9125C82.837 71.8725 82.282 71.1075 81.252 70.7725Z" />
          </svg>
        </div>
        <div className="svg_title">Grêle</div>
      </div>
    </div>
  );
};
