import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { QueryObserverResult, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { makeAuthenticatedApiCall } from '../apiHelper';
import { useCGList, useCGStatistics, usePipelines, useStages } from '../hooks/useApiQueries';
import useSearchHandler from '../hooks/useSearchHandler';
import theme from '../theme';
import { formatDateToDDMMYYYY, updateDealStage } from '../utils';
import { useAuth } from './AuthContext';
import CGComments from './CGList/CGComments';
import ExternalComments from './CGList/ExternalComments';
import PaymentModal from './CGList/PaymentModal';
import DealStageModal from './DealsList/DealStageActions/DealStageModal';
import SubmitCTVO from './DealsList/DealStageActions/SubmitCTVO';
import SubmitCessionCertificate from './DealsList/DealStageActions/SubmitCessionCertificate';

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

const SortIcon = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
  color: 'white',
}));

const getStageId = (tabIndex: number) => {
  switch (tabIndex) {
    case 1:
      return 46;
    case 2:
      return 47;
    case 3:
      return 48;
    default:
      return null;
  }
};

const DELETED_TAB_INDEX = 4;

const mapSortColumnToQuery = (column: string): string => {
  const columnMap: { [key: string]: string } = {
    'R.RegistrationID': 'R.RegistrationID',
    'R.CreatedAt': 'R.CreatedAt',
    'R.UpdatedAt': 'R.UpdatedAt',
    'R.StageID': 'R.StageID',
    // Add any other column mappings here
    // Default to RegistrationID if not found
  };
  return columnMap[column] || 'R.RegistrationID';
};

const CGList = () => {
  const { currentUser } = useAuth();
  const userRole = currentUser?.data?.data?.role;
  const userPipedrive = currentUser?.data?.data?.idPipedrive ?? 188;

  const [isLoadingInvoice, setIsLoadingInvoice] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] = useState<string>('');
  const [price, setPrice] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<number>(userRole === 'agency' ? 0 : 2);
  const [sortColumn, setSortColumn] = useState<string>('R.RegistrationID');
  const [sortOrder, setSortOrder] = useState<string>('DESC');
  const [isDeleting, setIsDeleting] = useState(false);

  const env = window.location.hostname === 'app.skipcar.fr' ? 'production' : 'development';

  const { searchQuery, hasSearched, handleSearch, resetSearch } = useSearchHandler<QueryObserverResult>();

  const navigate = useNavigate();

  const { data: stages, isLoading: isLoadingStages } = useStages();
  const { data: pipelines, isLoading: isLoadingPipelines } = usePipelines();

  const stageId = searchQuery ? null : getStageId(selectedTab);

  const { data, isLoading, error, refetch } = useCGList(
    userPipedrive,
    userRole,
    page + 1,
    rowsPerPage,
    searchQuery,
    stageId,
    mapSortColumnToQuery(sortColumn),
    sortOrder,
    false,
  );

  const { data: dataHidden } = useCGList(
    userPipedrive,
    userRole,
    page + 1,
    rowsPerPage,
    searchQuery,
    stageId,
    mapSortColumnToQuery(sortColumn),
    sortOrder,
    true,
  );

  const cgListData = selectedTab === DELETED_TAB_INDEX ? dataHidden?.cgList || [] : data?.cgList || [];
  const totalItems = selectedTab === DELETED_TAB_INDEX ? dataHidden?.totalItems || 0 : data?.totalItems || 0;

  const { data: statistics, isLoading: isLoadingStatistics } =
    userRole !== 'agency' ? useCGStatistics() : { data: null, isLoading: false };

  const queryClient = useQueryClient();

  const [openRegenerateDialog, setOpenRegenerateDialog] = useState<boolean>(false);
  const [personId, setPersonId] = useState<number>();
  const [agencyId, setAgencyId] = useState<number>();
  const [dealToRegenerate, setDealToRegenerate] = useState<any>(null);

  useEffect(() => {
    const syncAndVerify = async () => {
      if (!isLoading && cgListData && cgListData.length > 0) {
        await verifyAndUpdateInvoiceStatus();
      }
    };
    syncAndVerify();
  }, [isLoading, cgListData]);

  useEffect(() => {
    if (sortColumn && sortOrder) {
      refetch();
    }
  }, [sortColumn, sortOrder]);

  useEffect(() => {
    if (searchQuery !== '') {
      setPage(0);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!isLoading && cgListData) {
      setIsTableLoading(false); // Arrêter le loader une fois les données chargées
    }
  }, [isLoading, cgListData]);

  const handleDeleteCG = async (registrationId: number) => {
    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer ce deal CG ?');
    if (confirmDelete) {
      setIsDeleting(true);
      try {
        await makeAuthenticatedApiCall('delete', `/api/cg/${registrationId}`);
        await refetch(); // Rafraîchir la liste après suppression
        alert('Le deal CG a été supprimé avec succès.');
      } catch (error) {
        console.error('Erreur lors de la suppression du deal CG:', error);
        alert('Une erreur est survenue lors de la suppression du deal CG.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleOpenPaymentModal = (invoice: string, price: number) => {
    setSelectedInvoice(invoice);
    setPrice(userPipedrive === 343 ? price + 10 : price + 12);
    setOpenPaymentModal(true);
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setIsTableLoading(true);
    setSelectedTab(newValue);
    setPage(0); // Reset pagination to the first page when the tab changes
    if (newValue === DELETED_TAB_INDEX) {
      // Optionally refetch dataHidden if needed
      // refetchDataHidden();
    } else {
      // Refetch data for other tabs
      refetch();
    }
  };

  const handleChangePage = async (_event: React.MouseEvent | null, newPage: number) => {
    setIsTableLoading(true); // Démarrer le chargement
    setPage(newPage);
    // await refetch();
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setIsTableLoading(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // await refetch();
  };

  const handleOpenRegenerateDialog = (deal: any) => {
    setDealToRegenerate(deal);
    setOpenRegenerateDialog(true);
  };

  const handleCloseRegenerateDialog = () => {
    setOpenRegenerateDialog(false);
  };

  const handleConfirmRegenerateInvoice = async () => {
    if (!dealToRegenerate) return;

    setIsLoadingInvoice(true);
    try {
      const responseInvoiceGeneration = await makeAuthenticatedApiCall('post', '/api/qonto/generate', {
        pipeline: 'f',
        deal: { ...dealToRegenerate, id: dealToRegenerate.PipedriveDealID },
        vat: 0,
        sellingPrice: dealToRegenerate.TaxData.taxeTotale ?? dealToRegenerate.TaxDataEstim.taxeTotale,
        agencyData: { personID: personId, agencyID: agencyId },
        regPlate: dealToRegenerate.RegPlate,
      });
      console.log('responseInvoiceGeneration', responseInvoiceGeneration);
    } catch (error: any) {
      console.error('Error generating invoice:', error);
    }
    setIsLoadingInvoice(false);
    handleCloseRegenerateDialog();
  };

  const verifyAndUpdateInvoiceStatus = async () => {
    setIsTableLoading(true);

    const updatePromises = cgListData.map(async (cg) => {
      if (!cg || cg.AgencyID === 343 || cg.AgencyID === 380) return;

      if (cg.InvoiceStatus === 'paid' && cg.StageID === 46) {
        console.log(env, 'Updating stage for deal to 47', cg.PipedriveDealID);
        if (env === 'production') {
          await updateDealStage(Number(cg.PipedriveDealID), 47);
          await sendInvoicePaidEmail({ agencyID: cg.AgencyID, agencyName: cg.AgencyName }, cg.RegPlate);
        }
      } else if (cg.InvoiceStatus === 'unpaid' && cg.StageID === 47) {
        console.log(env, 'Updating stage for deal to 46', cg.PipedriveDealID);
        if (env === 'production') {
          await updateDealStage(Number(cg.PipedriveDealID), 46);
        }
      } else if (
        cg.InvoiceStatus === 'paid' &&
        cg.StageID !== 48 &&
        cg.TaxDataEstim?.taxeTotale &&
        cg.TaxData?.TaxeTotale
      ) {
        if (cg.CurrentStep === 'CTVO') {
          if (env === 'production') {
            await updateDealStage(Number(cg.PipedriveDealID), 48);
          }
          console.log('verifyAndUpdateInvoiceStatus / updateDealStage to 48 / cg.CurrentStep', cg.CurrentStep);
        }
      }
    });

    await Promise.all(updatePromises);
    await refetch();
    setIsTableLoading(false);
  };

  const sendInvoicePaidEmail = async (agencyData: any, regPlate: string) => {
    if (agencyData?.agencyID !== 343) {
      try {
        await makeAuthenticatedApiCall('post', `/api/mails/ctvo/god`, {
          AgencyName: agencyData?.agencyName,
          RegPlate: regPlate,
        });
      } catch (error: any) {
        console.error(error);
        const errorMessage =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Une erreur inattendue s'est produite. Veuillez réessayer.";
        console.error(errorMessage);
        //openSnackbar(errorMessage, 'error');
      }
    }
  };

  if (error) return <Typography color="error">Erreur: {error.message}</Typography>;

  if (!isLoading && !isTableLoading && (!cgListData || cgListData.length === 0) && !searchQuery) {
    return (
      <Container maxWidth={false}>
        <Grid item xs={12} sx={{ marginBottom: 5 }}>
          <Typography align="center" mt={5} variant="body2">
            📅 Vous désirez passer sur une facturation à la quinzaine ou mensuelle :{' '}
            <Link target="_blank" href="mailto:skipcarcg@gmail.com">
              Contactez-nous
            </Link>{' '}
            ✉️
          </Typography>
        </Grid>

        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '200px' }}>
          <Card
            elevation={5}
            sx={{
              padding: theme.spacing(2),
              display: 'flex',
              flexDirection: 'column',
              marginTop: theme.spacing(0),
              width: '100%',
              maxWidth: '500px',

              background: 'linear-gradient(135deg, #fff8e1 0%, #ffe57f 100%)',
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{
                marginBottom: theme.spacing(1),
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              📊 Pas encore de carte grise
            </Typography>
            <Typography
              variant="body2"
              align="center"
              sx={{
                marginBottom: theme.spacing(2),
                color: 'black',
              }}
            >
              Les demandes de carte grise sont uniquement à 10€ HT Accessible pour tout véhicule (même les véhicules
              vendus en intermédiation). Profitez en !
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="button"
              className="ActionButton"
              sx={{
                width: '350px',
                margin: '22px auto !important',
                backgroundColor: theme.palette.saffron.main,
                '&:hover': {
                  backgroundColor: theme.palette.saffron.dark,
                },
              }}
              href="/form-cg"
            >
              🚗 Faire une demande de carte grise
            </Button>
          </Card>
        </Grid>
      </Container>
    );
  }

  async function handleInvoiceClick(PipedriveDealID: number) {
    try {
      const response = await makeAuthenticatedApiCall<{ invoice_url_firebase: string }>(
        'get',
        `api/qonto/deals/f/${PipedriveDealID}`,
      );
      if (response.data.invoice_url_firebase) {
        window.open(response.data.invoice_url_firebase, '_blank');
      } else {
        console.error('No invoice URL found');
        return false;
      }
    } catch (error) {
      console.error('Error getting invoice:', error);
    }
  }

  async function handleCGClick(RegistrationID: number) {
    navigate(`/form-cg?registrationId=${RegistrationID}`);
  }

  const onRefresh = async (changeCurrentTab?: number) => {
    setIsTableLoading(true);
    try {
      if (userRole === 'agency') {
        // Invalider les requêtes pour forcer un rechargement
        await queryClient.invalidateQueries({
          queryKey: ['useCGList', userPipedrive, userRole, page + 1, rowsPerPage],
        });
      } else {
        await queryClient.invalidateQueries({
          queryKey: ['useCGList'],
        });
      }

      // Si une recherche est en cours, relancer la recherche
      if (searchQuery && searchQuery.trim()) {
        await handleSearch(searchQuery, refetch);
      } else {
        // Sinon, recharger les données initiales
        await refetch();
      }
      // 1. Récupérer d'abord les statuts factures à jour
      await axios.post('/api/qonto/sync/invoices/cg');

      const response = await makeAuthenticatedApiCall('post', '/api/cg/update-stage-ids');
      console.log('response', response);

      if (env === 'production') {
        console.log('onRefresh / env production');

        // 2. Puis synchroniser les statuts des factures avec les deals

        // 3. Enfin, vérifier les étapes des factures et ajuster les stages si nécessaire
        await verifyAndUpdateInvoiceStatus();
      }
      if (changeCurrentTab) {
        setSelectedTab(changeCurrentTab);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsTableLoading(false);
    }
  };

  const handleSort = (column: string) => {
    console.log('handleSort', column);
    const isAsc = sortColumn === column && sortOrder === 'ASC';
    setSortOrder(isAsc ? 'DESC' : 'ASC');
    setSortColumn(column);
  };

  return (
    <>
      <Container maxWidth={false}>
        <Grid item xs={12}>
          <Typography align="center" mt={5} variant="body2">
            📅 Vous désirez passer sur une facturation à la quinzaine ou mensuelle :{' '}
            <Link target="_blank" href="mailto:skipcarcg@gmail.com">
              Contactez-nous
            </Link>{' '}
            ✉️
          </Typography>
        </Grid>

        {!currentUser?.data?.organization?.id && (
          <Typography variant="h6" sx={{ mt: 4, textAlign: 'center' }}>
            Il semblerait y avoir un problème dans la configuration de votre compte, veuillez nous contacter au 07 80 94
            97 37.
          </Typography>
        )}

        {currentUser?.data?.organization?.id && (
          <>
            {userRole !== 'agency' && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 5,
                }}
              >
                <Button
                  variant="contained"
                  className="dealActionButton"
                  sx={{ fontWeight: 800 }}
                  onClick={() => navigate('/billing-client-list')}
                >
                  Voir les clients / Gérer la facturation
                </Button>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TextField
                  label="Rechercher"
                  className="inputElement"
                  variant="outlined"
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value, refetch)}
                  sx={{ marginRight: 0 }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleSearch(searchQuery, refetch)}
                  className="dealActionButton"
                  sx={{
                    marginLeft: 1,
                    fontWeight: 900,
                  }}
                >
                  Rechercher
                </Button>
                {hasSearched && ( // Afficher le bouton si une recherche a été effectuée
                  <Button
                    className="secondaryDealActionButton"
                    variant="contained"
                    sx={{ marginLeft: 1 }}
                    color="error"
                    onClick={() => resetSearch(refetch)}
                  >
                    Revenir à la liste principale
                  </Button>
                )}
              </Box>
              <TablePagination
                className="custom-pagination"
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalItems || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={'Lignes par page'}
                labelDisplayedRows={({ from, to, count }) => `de ${from} à ${to}`}
              />
            </Box>
            {userRole !== 'agency' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 5,
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<SyncIcon />}
                  className="dealActionButton"
                  sx={{ fontWeight: 800 }}
                  onClick={() => onRefresh()}
                >
                  Rafraichir / Mettre à jour
                </Button>
                {!isLoadingStatistics && (
                  <>
                    <Tooltip
                      title="Le nombre total de CG enregistrés qui sont visibles et ont un identifiant Pipedrive."
                      arrow
                      placement="top"
                    >
                      <Box>
                        <Typography variant="body1" textAlign={'center'}>
                          Total CG
                        </Typography>
                        <Typography variant="body2" textAlign={'center'}>
                          {statistics?.totalCG}
                        </Typography>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title="Le nombre total de CG qui ont été complétés, c'est-à-dire ceux qui ont atteint l'étape finale."
                      arrow
                      placement="top"
                    >
                      <Box>
                        <Typography variant="body1" textAlign={'center'}>
                          Total CG effectuées
                        </Typography>
                        <Typography variant="body2" textAlign={'center'}>
                          {statistics?.totalCompletedCG}
                        </Typography>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title="Le montant total des frais collectés par Skipcar pour les CG complétés. 12€ pour Biwiz et 10€ pour les autres agences."
                      arrow
                      placement="top"
                    >
                      <Box>
                        <Typography variant="body1" textAlign={'center'}>
                          Recettes Skipcar
                        </Typography>
                        <Typography variant="body2" textAlign={'center'}>
                          {statistics?.totalFees} €
                        </Typography>
                      </Box>
                    </Tooltip>
                  </>
                )}
              </Box>
            )}
            <Paper sx={{ marginTop: 5, padding: 0 }} elevation={3}>
              {userRole !== 'agency' && !hasSearched && (
                <Tabs
                  value={selectedTab}
                  className="DealsListTabs"
                  onChange={handleTabChange}
                  aria-label="CG List Tabs"
                >
                  <Tab label="Tous" />
                  <Tab label="Demande de CG" />
                  <Tab label="Facture Payée <=> À FAIRE" />
                  <Tab label="Procédure terminée" />
                  {userRole !== 'agency' && <Tab label={<span style={{ color: 'red' }}>CG supprimées</span>} />}
                </Tabs>
              )}
              <Table stickyHeader>
                <TableHead className="stickyTableHead">
                  <TableRow sx={{ background: '#262626' }}>
                    {userRole !== 'agency' && (
                      <TableCell sx={{ ...stickyCellStyle, width: '30px', padding: 0, paddingLeft: 1 }}></TableCell>
                    )}
                    <TableCell
                      sx={{ ...stickyCellStyle, width: '90px', cursor: 'pointer' }}
                      onClick={() => handleSort('V.RegPlate')}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Immat
                        {sortColumn === 'V.RegPlate' && (
                          <SortIcon>
                            {sortOrder === 'ASC' ? (
                              <ArrowUpwardIcon fontSize="small" />
                            ) : (
                              <ArrowDownwardIcon fontSize="small" />
                            )}
                          </SortIcon>
                        )}
                      </Box>
                    </TableCell>
                    {userRole !== 'agency' && (
                      <>
                        <TableCell
                          sx={{ ...stickyCellStyle, cursor: 'pointer' }}
                          onClick={() => handleSort('R.RegistrationID')}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            ID
                            {sortColumn === 'R.RegistrationID' && (
                              <SortIcon>
                                {sortOrder === 'ASC' ? (
                                  <ArrowUpwardIcon fontSize="small" />
                                ) : (
                                  <ArrowDownwardIcon fontSize="small" />
                                )}
                              </SortIcon>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{ ...stickyCellStyle, cursor: 'pointer' }}
                          onClick={() => handleSort('R.PipedriveDealID')}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Pipedrive
                            {sortColumn === 'R.PipedriveDealID' && (
                              <SortIcon>
                                {sortOrder === 'ASC' ? (
                                  <ArrowUpwardIcon fontSize="small" />
                                ) : (
                                  <ArrowDownwardIcon fontSize="small" />
                                )}
                              </SortIcon>
                            )}
                          </Box>
                        </TableCell>
                      </>
                    )}
                    <TableCell sx={{ ...stickyCellStyle, width: '100px' }}>Prix</TableCell>
                    {userRole !== 'agency' && <TableCell sx={{ ...stickyCellStyle, width: '75px' }}>Agence</TableCell>}
                    <TableCell sx={{ ...stickyCellStyle, minWidth: '200px' }}>Commentaires</TableCell>
                    <TableCell sx={stickyCellStyle}>Vendeur / Acheteur</TableCell>
                    <TableCell sx={{ ...stickyCellStyle, cursor: 'pointer' }} onClick={() => handleSort('R.CreatedAt')}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Date de création
                        {sortColumn === 'R.CreatedAt' && (
                          <SortIcon>
                            {sortOrder === 'ASC' ? (
                              <ArrowUpwardIcon fontSize="small" />
                            ) : (
                              <ArrowDownwardIcon fontSize="small" />
                            )}
                          </SortIcon>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ ...stickyCellStyle, cursor: 'pointer' }}
                      onClick={() => handleSort('R.PurchaseDateTime')}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        Date de vente
                        {sortColumn === 'R.PurchaseDateTime' && (
                          <SortIcon>
                            {sortOrder === 'ASC' ? (
                              <ArrowUpwardIcon fontSize="small" />
                            ) : (
                              <ArrowDownwardIcon fontSize="small" />
                            )}
                          </SortIcon>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ ...stickyCellStyle, textAlign: 'center' }}>Étape / Actions</TableCell>
                  </TableRow>
                </TableHead>
                {isTableLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {cgListData?.map((cg: any) => {
                      const currentStage = stages?.find((stage: any) => stage.id === cg.StageID);
                      const priceCellColor =
                        cg.TaxDataEstim?.taxeTotale === undefined || cg.TaxData?.TaxeTotale === undefined
                          ? 'black' // Met en noir si l'une des valeurs est indéfinie
                          : cg.TaxDataEstim?.taxeTotale === cg.TaxData?.TaxeTotale
                            ? 'green' // Met en vert si les deux valeurs sont égales
                            : 'red'; // Met en rouge si les deux valeurs sont différentes et toutes deux définies

                      return (
                        <TableRow
                          key={`${cg.RegistrationID}-${cg.PipedriveDealID}`}
                          sx={{
                            backgroundColor:
                              userRole !== 'agency'
                                ? cg.Comments
                                  ? 'rgb(255 96 96 / 50%)'
                                  : cg.hasExternalComments
                                    ? 'rgb(255 96 96 / 70%)'
                                    : 'inherit'
                                : 'inherit', // Applique la couleur 'inherit' si userRole est 'agency'
                          }}
                        >
                          {userRole !== 'agency' && (
                            <TableCell sx={{ width: '30px', padding: 0, paddingLeft: 1 }}>
                              {isDeleting ? (
                                <CircularProgress size={24} />
                              ) : (
                                <DeleteIcon
                                  sx={{ cursor: 'pointer', color: 'red' }}
                                  onClick={() => handleDeleteCG(cg.RegistrationID)}
                                />
                              )}
                            </TableCell>
                          )}
                          <TableCell sx={{ minWidth: '90px' }}>{cg.RegPlate}</TableCell>
                          {userRole !== 'agency' && (
                            <TableCell style={{ width: '50px' }}>{cg.RegistrationID}</TableCell>
                          )}
                          {userRole !== 'agency' && (
                            <TableCell style={{ width: '75px' }}>{cg.PipedriveDealID}</TableCell>
                          )}
                          <TableCell style={{ color: priceCellColor, fontWeight: 600, minWidth: '150px' }}>
                            {cg.TaxDataEstim?.taxeTotale && (
                              <Box>Estimé : {(cg.TaxDataEstim?.taxeTotale * 1.2).toFixed(2)} € TTC</Box>
                            )}
                            {cg.TaxData?.TaxeTotale && (
                              <Box>Final : {(cg.TaxData?.TaxeTotale * 1.2).toFixed(2)} € TTC</Box>
                            )}
                          </TableCell>
                          {userRole !== 'agency' && <TableCell>{cg.AgencyName}</TableCell>}
                          <TableCell sx={{ minWidth: '200px' }}>
                            {userRole !== 'agency' && (
                              <>
                                <CGComments
                                  registrationId={cg.RegistrationID}
                                  comments={cg.Comments || []}
                                  refetch={async () => {
                                    await refetch();
                                  }}
                                />
                                <Divider sx={{ marginTop: 3, marginBottom: 1 }} />
                              </>
                            )}
                            <ExternalComments registrationId={cg.RegistrationID} userRole={userRole} />
                          </TableCell>
                          <TableCell>
                            {`${cg.SellerFirstName ?? ''} ${cg.SellerLastName ?? ''}`} {`${cg.SellerCompanyName ?? ''}`}
                            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                            {`${cg.BuyerFirstName ?? ''} ${cg.BuyerLastName ?? ''}`} {`${cg.BuyerCompanyName ?? ''}`}
                            {cg.CoBuyerType && (
                              <Tooltip
                                title={
                                  cg.CoBuyerType === 'company'
                                    ? `${cg.CoBuyerCompanyName} / ${cg.CoBuyerSIRET}`
                                    : `${cg.CoBuyerFirstName} ${cg.CoBuyerLastName}`
                                }
                                placement="top"
                                key={`co-buyer-${cg.CoBuyerEntityID}-${cg.PipedriveDealID}`}
                              >
                                <Typography variant="body2" sx={{ fontSize: '12px', textAlign: 'left' }}>
                                  CoAcheteur
                                </Typography>
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell>{formatDateToDDMMYYYY(cg.CreatedAt ?? cg.UpdatedAt) ?? ''}</TableCell>
                          <TableCell>{formatDateToDDMMYYYY(cg.PurchaseDateTime ?? '')}</TableCell>
                          <TableCell align="center">
                            <Stack direction="column" spacing={1} alignItems="center">
                              {cg.StageID === 46 && (
                                <Button
                                  sx={{
                                    fontSize: 14,
                                    background: '#FF6060',
                                    opacity: 1,
                                    color: 'white !important',
                                    fontWeight: 600,
                                    width: 152,
                                  }}
                                  onClick={() =>
                                    handleOpenPaymentModal(cg.InvoiceNumber, cg?.TaxDataEstim?.taxeTotale ?? 0)
                                  }
                                  variant="contained"
                                >
                                  Payer
                                </Button>
                              )}
                              <Button
                                sx={{
                                  fontSize: 14,
                                  width: 152,
                                  display: cg.InvoiceStatus ? 'block' : 'none',
                                }}
                                variant="outlined"
                                onClick={() => handleInvoiceClick(cg.PipedriveDealID)}
                              >
                                Voir la facture
                              </Button>
                              {userRole !== 'agency' && (
                                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                                  {cg.InvoiceNumber} {cg.InvoiceStatus && `/ ${cg.InvoiceStatus}`}
                                </Typography>
                              )}
                              <Button
                                sx={{
                                  fontSize: 14,
                                  width: 152,
                                }}
                                variant="outlined"
                                onClick={() => handleCGClick(cg.RegistrationID)}
                              >
                                Voir la demande
                              </Button>
                              <SubmitCessionCertificate
                                deal={cg}
                                pipelineLetter="f"
                                stageId={cg.StageID}
                                userRole={userRole}
                                onRefresh={onRefresh}
                              />
                              <SubmitCTVO
                                deal={cg}
                                pipelineLetter="f"
                                stageId={cg.StageID}
                                userRole={userRole}
                                onRefresh={onRefresh}
                              />
                              {isLoadingStages || isLoadingPipelines || isLoading ? (
                                <CircularProgress />
                              ) : (
                                <>
                                  <Typography
                                    sx={{ marginTop: 1, fontSize: 16, fontWeight: '600' }}
                                    align="center"
                                    variant="body2"
                                    className="MuiTypographyTable"
                                  >
                                    {currentStage ? currentStage.name : 'Loading...'}
                                  </Typography>
                                  {userRole !== 'agency' && (
                                    <DealStageModal
                                      dealId={cg.PipedriveDealID}
                                      dealPipelineId={8}
                                      stages={stages}
                                      pipelines={pipelines}
                                      initialStageId={cg.StageID}
                                      onRefresh={onRefresh}
                                    />
                                  )}
                                </>
                              )}
                              <Button
                                sx={{
                                  fontSize: 14,
                                  width: 152,
                                  display: 'none',
                                }}
                                variant="contained"
                                onClick={() => handleOpenRegenerateDialog(cg)}
                                disabled={isLoadingInvoice}
                              >
                                {isLoadingInvoice ? <CircularProgress size={24} /> : 'Regénérer la facture'}
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: 2,
                }}
              >
                <TablePagination
                  className="custom-pagination"
                  rowsPerPageOptions={[25, 50, 100]}
                  component="div"
                  count={totalItems || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={'Lignes par page'}
                  labelDisplayedRows={({ from, to, count }) => `de ${from} à ${to}`}
                />
              </Box>
            </Paper>
          </>
        )}
      </Container>
      <PaymentModal
        open={openPaymentModal}
        onClose={handleClosePaymentModal}
        price={price}
        invoiceNumber={selectedInvoice}
      />
      <Dialog open={openRegenerateDialog} onClose={handleCloseRegenerateDialog}>
        <DialogTitle>Regenerate Invoice</DialogTitle>
        <DialogContent>
          <TextField
            label="Person ID"
            type="number"
            value={personId}
            onChange={(e) => setPersonId(Number(e.target.value))}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Agency ID"
            type="number"
            value={agencyId}
            onChange={(e) => setAgencyId(Number(e.target.value))}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRegenerateDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRegenerateInvoice} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CGList;
